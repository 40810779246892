import { Button, CardInfo, Loading } from "../../../Components";
import { WalletIcon } from "../../../Components/Icons";

import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";

import { useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";

type InfoIngressoProps = {
  nomeTitular: string | null;
  nomeIngresso: string;
  nomeEvento: string;
  dataEvento: string;
  horaEvento: string;
  eventoId: string;
  ingressoId: string;
  podeTransferir: boolean;
  qrCodeVisivel: boolean;
};

export default function InfoIngresso(props: InfoIngressoProps) {
  const navigate = useNavigate();

  const [appleWalletLoading, setAppleWalletLoading] = useState<boolean>(false);

  const DownloadIngressoAppleWallet = async (
    eventoId: string,
    ingressoId: string,
  ) => {
    setAppleWalletLoading(true);

    try {
      const response = await AxiosClient.get(
        `/conta/ingressos/${eventoId}/${ingressoId}/apple-wallet`,
        {
          responseType: "blob",
        },
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.apple.pkpass",
      });

      saveAs(blob, `ingresso-${ingressoId}.pkpass`);
    } catch (error: any) {
      toast.error("Não foi possível acessar a AppleWallet desse ingresso.");
    } finally {
      setAppleWalletLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start p-4">
      {/* Comprador */}
      <p className="text-500-black-16 mb-1">Nome do titular</p>
      <p className="text-400-dark-16 text-break text-center mb-3">{props.nomeTitular}</p>

      {/* Ingresso */}
      <p className="text-500-black-16 mb-1">Ingresso</p>
      <p className="text-400-dark-16 text-break text-center mb-3">{props.nomeIngresso}</p>

      {/* Evento */}
      <p className="text-500-black-16 mb-1">Evento</p>
      <p className="text-400-dark-16 text-break text-center mb-3">{props.nomeEvento}</p>

      {props.dataEvento && props.horaEvento ? (
        <>
          {/* Data do evento */}
          <p className="text-500-black-16 mb-1">Data do evento</p>
          <p className="text-400-dark-16 text-break text-center mb-3">
            {new Intl.DateTimeFormat("pt-br").format(
              new Date(props.dataEvento),
            )}
          </p>

          {/* Hora do evento */}
          <p className="text-500-black-16 mb-1">Hora do evento</p>
          <p className="text-400-dark-16 text-break text-center mb-3">
            {new Date(props.dataEvento).toLocaleTimeString("pt-br", {
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            Horas
          </p>
        </>
      ) : (
        <p className="text-500-black-16 text-center mb-3">
          O evento não possui data e hora definidos
        </p>
      )}

      {/* Download ingresso Apple Wallet */}
      {appleWalletLoading ? (
        <Loading />
      ) : (
        <div
          className="cursor-pointer mb-3"
          style={{ width: "200px" }}
          hidden={!props.qrCodeVisivel}
          onClick={() =>
            DownloadIngressoAppleWallet(props.eventoId, props.ingressoId)
          }
        >
          <WalletIcon />
        </div>
      )}

      {/* {!props.podeTransferir && (
          <CardInfo text="Este ingresso não pode ser transferido" />
        )} */}
      {props.podeTransferir ? (
        <Button
          className="bc-btn bc-btn-light mb-3 me-0 me-lg-3 p-0"
          disabled={!props?.podeTransferir}
          text="Transferir Ingresso"
          type="submit"
          click={() => navigate(`/transferir-ingresso/${props.ingressoId}`)}
          width="216px"
          height="40px"
        />
      ) : (
        <CardInfo textClassName="text-center" text="Este ingresso não pode ser transferido" />
      )}
    </div>
  );
}
