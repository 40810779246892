import EsqueciSenhaArgs from "../Interfaces/Args/EsqueciSenhaArgs";
import AxiosClient from "../Services/AxiosClient";
import toast from "react-hot-toast";
import { useState } from "react";
import ServiceResult from "../Interfaces/ServiceResult";

export default function useEsqueciSenha() {
  const [loading, setLoading] = useState(false);

  const EnviarEmailRedefinicaoSenha = async (data: EsqueciSenhaArgs) => {
    setLoading(true);

    AxiosClient.post<ServiceResult>("/conta/esqueci-minha-senha", data)
      .then(({ data: result }) => {
        localStorage.setItem("email", data.email);

        if (result.messages.length > 0) {
          toast(result.messages[0].message, { icon: "🔒" });
          return;
        }

        toast.success(
          `Enviamos um e-mail para ${data.email} com as instruções para redefinir sua senha.`,
        );
      })
      .catch(() => {
        toast.error(`Não foi possível identificar a conta de origem pelo e-mail informado.
      Por favor, confira o e-mail e insira-o novamente.`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { EnviarEmailRedefinicaoSenha, loading };
}
