import Flickity from "react-flickity-component";

import { IngressoContaDetalheModel } from "../../../Interfaces/Models";
import InfoIngresso from "./InfoIngresso";
import QRCode from "react-qr-code";

type CarouselIngressoProps = {
  ingressos: IngressoContaDetalheModel[];
};

export default function CarouselIngresso({ ingressos }: CarouselIngressoProps) {
  return (
    <Flickity
      elementType="div"
      className="carousel-ingresso"
      options={{
        autoPlay: false,
        wrapAround: false,
        cellAlign: "center",
        lazyLoad: false,
        pageDots: true,
        prevNextButtons: true,
        adaptiveHeight: true,
        resize: true,
        draggable: false,
      }}
    >
      {ingressos.map((ingresso) => (
        // <div key={ingresso.id} className="rounded" style={{ background: "#F2F2F2", minWidth: "800px" }}>

        <div key={ingresso.id}>
          {/* WEB */}
          <div
            className="d-none d-lg-flex flex-row justify-content-between align-items-center"
            style={{
              // Ajustando cor da background image
              backgroundImage:
                "linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url(/assets/images/bg-ticket.png)",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "600px",
              height: "400px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
              margin: "1rem",
            }}
          >
            <div style={{ height: "300px", width: "50%", textAlign: "center" }}>
              {ingresso.qrCodeVisivel ? (
                <QRCode
                  className="pt-4 pt-lg-0"
                  style={{
                    height: "100%",
                    maxWidth: "220px",
                    width: "100%",
                  }}
                  viewBox={`0 0 256 256`}
                  value={ingresso.qrCode ?? ""}
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "150px", width: "100%" }}
                >
                  <p className="text-400-black-16 m-0">QR Code indisponível</p>
                </div>
              )}
            </div>
            <div
              // className="col-lg-6 d-flex flex-column justify-content-center align-items-start"
              style={{
                transform: "scale(0.7)", // TODO: Solução "idiota" temporária...
                alignItems: "left",
                width: "50%",
              }}
            >
              <InfoIngresso
                nomeTitular={ingresso.ingressoEmNomeDe}
                nomeIngresso={ingresso.descricao}
                nomeEvento={ingresso.eventoTitulo}
                dataEvento={ingresso.sessoes[0]}
                horaEvento={ingresso.sessoes[0]}
                eventoId={ingresso.eventoId}
                ingressoId={ingresso.id}
                podeTransferir={ingresso.podeTransferir}
                qrCodeVisivel={ingresso.qrCodeVisivel}
              />
            </div>
          </div>

          {/* MOBILE */}
          <div
            className="d-lg-none d-flex flex-column justify-content-center align-items-center px-5 pb-10 m-0"
            style={{
              width: "100%",
              minHeight: "900px",
              maxWidth: "600px",
              padding: "1rem",
              margin: "1rem",
            }}
          >
            <div
              className="mx-5 d-flex align-items-center"
              style={{
                height: "300px",
                textAlign: "center",
              }}
            >
              {ingresso.qrCodeVisivel ? (
                <QRCode
                  className="pt-4 pt-lg-0"
                  style={{
                    height: "100%",
                    maxWidth: "210px",
                    width: "100%",
                  }}
                  viewBox={`0 0 256 256`}
                  value={ingresso.qrCode ?? ""}
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "100%" }}
                >
                  <p className="text-400-black-16 m-0">QR Code indisponível</p>
                </div>
              )}
            </div>
            <div
              // className="col-lg-6 d-flex flex-column justify-content-center align-items-start"
              style={{ width: "100%", maxWidth: "230px", alignItems: "left" }}
            >
              <InfoIngresso
                nomeTitular={ingresso.ingressoEmNomeDe}
                nomeIngresso={ingresso.descricao}
                nomeEvento={ingresso.eventoTitulo}
                dataEvento={ingresso.sessoes[0]}
                horaEvento={ingresso.sessoes[0]}
                eventoId={ingresso.eventoId}
                ingressoId={ingresso.id}
                podeTransferir={ingresso.podeTransferir}
                qrCodeVisivel={ingresso.qrCodeVisivel}
              />
            </div>
          </div>
        </div>
      ))}
    </Flickity>
  );
}
