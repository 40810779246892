export default function ConverterStringTipoPagamento(tipoPagamento: string): string {
  switch (tipoPagamento) {
    case 'CartaoCredito':
      return 'Cartão de Crédito';
    case 'Pix':
      return 'Pix';
    case 'ApplePay':
      return 'Apple Pay';
    case 'Cortesia':
      return 'Cortesia';
    default:
      return 'Tipo de pagamento não encontrado';
  }
}