import Flickity from "react-flickity-component";

import { BannerModel } from "../../../Interfaces/Models";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

type CarouselProps = {
  banners: BannerModel[];
};

export default function Carousel({ banners }: CarouselProps) {
  const [flkty, setFlkty] = useState<Flickity | null>(null);

  // workaround para corrigir problema onde o carousel não exibe os items
  // "wrapAround" na esquerda a primeira vez que é renderizado
  useEffect(() => {
    if (flkty) {
      flkty.next();
    }
  }, [flkty]);

  return (
    <Flickity
      className="carousel mb-4 mt-4"
      elementType="div"
      options={{
        autoPlay: true,
        wrapAround: true,
        cellAlign: "center",
        lazyLoad: true,
      }}
      flickityRef={(c) => {
        if (c) {
          setFlkty(c);
        }
      }}
    >
      {banners.map((banner) => (
        <Link key={banner.id} to={banner.link}>
          <img
            className="carousel-cell"
            src={banner.imagem}
            alt="carousel"
          />
        </Link>
      ))}
    </Flickity>
  );
}
