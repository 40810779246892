import {
  Button,
  CardInfo,
  CleanLayout,
  Input,
  Loading,
} from "../../Components";

import LoginArgs from "../../Interfaces/Args/LoginArgs";
import useLogin from "../../Hooks/useLogin";

import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useValidateToken } from "../../Hooks/useValidateToken";
import { useState } from "react";
import GrantType from "../../Enums/GrantType";
import toast from "react-hot-toast";
import { useEmbeddedStore } from "../../Stores/embedded.store";

export default function Login() {
  const { embedded } = useEmbeddedStore();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [lembrarSenha, setLembrarSenha] = useState<boolean>(false);

  useValidateToken();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginArgs>({
    defaultValues: {
      user: "",
      password: "",
    },
  });

  const { loading, handleLogin } = useLogin(lembrarSenha);

  const onSubmit = async (data: LoginArgs): Promise<void> => {
    if (navigator.onLine) {
      handleLogin(GrantType.Password, data);
    } else {
      toast.error("Por favor, verifique sua conexão com a internet.");
    }
  };

  return (
    <>
      <CleanLayout>
        {loading ? (
          <Loading container="75vh" />
        ) : (
          <>
            {!embedded && (
              <Link to="/">
                <p className="mb-4">Voltar para a página inicial</p>
              </Link>
            )}

            <p className="title-h1 mt-3 mb-2">Acessar sua conta</p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="text-400-black-18 m-0 mb-5">
                Digite seu e-mail e senha abaixo:
              </p>

              <div className="form-group mb-5">
                <Input
                  control={control}
                  errors={errors}
                  name="user"
                  placeholder="e-mail"
                  type="text"
                  validation={{
                    required: "Por favor, informe um e-mail válido",
                    validate: {
                      user: (value: string) =>
                        (value.includes("@") && value.includes(".")) ||
                        `O e-mail '${value}' é inválido. Insira um e-mail corretamente.`,
                    },
                  }}
                />
                {errors.user && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.user.message?.toString()}
                  </p>
                )}
              </div>
              <div className="form-group mb-5">
                <div className="d-flex align-items-center">
                  <Input
                    control={control}
                    errors={errors}
                    validation={{
                      required: "Por favor, informe a senha.",
                      minLength: {
                        message: "A senha deve conter no mínimo 6 caracteres",
                        value: 6,
                      },
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="senha"
                  />
                  <button
                    type="button"
                    className="btn"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-solid fa-eye"></i>
                    )}
                  </button>
                </div>
                {errors.password && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.password.message?.toString()}
                  </p>
                )}
              </div>

              {/* Lembrar acesso / Esqueci a senha */}
              <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-center justify-content-xxl-between align-items-center mb-5">
                <div className="form-check form-switch d-flex align-items-center ps-5">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="lembrarAcesso"
                    checked={lembrarSenha}
                    onChange={() => setLembrarSenha(!lembrarSenha)}
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="lembrarAcesso"
                  >
                    Lembrar do meu acesso
                  </label>
                </div>

                <Link
                  to="/esqueci-senha"
                  className="bc-link text-center ps-lg-4"
                >
                  Esqueci a senha
                </Link>
              </div>

              <div className="g-5">
                <div className="col-lg-9">
                  <Button
                    width="100%"
                    text="Acessar conta"
                    type="submit"
                    className="bc-btn bc-btn-primary mb-5"
                    disabled={!!errors.user || !!errors.password}
                  />
                </div>
              </div>
            </form>

            <CardInfo
              text={
                <>
                  <p className="text-400-darkest-16 m-0">
                    Você ainda não tem uma conta? Clique em{" "}
                    <Link to={"/cadastro"} className="bc-link">
                      cadastrar
                    </Link>{" "}
                    e crie agora mesmo!
                  </p>
                </>
              }
              padding="14px"
            />
          </>
        )}
      </CleanLayout>
    </>
  );
}
