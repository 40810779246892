import ArrowLeftIcon from "./Icons/ArrowLeftIcon";

interface BreadCrumbProps {
  history: Page[];
}

export interface Page {
  name: string;
  link: string;
}

export default function BreadCrumb({ history }: BreadCrumbProps) {
  return (
    <>
      <div className="d-flex flex-wrap align-items-center">
        {/* Navega para a penultima pagina do array (volta 1) */}
        <a href={history[history.length - 2].link} className="py-2 pe-3">
          <ArrowLeftIcon />
        </a>

        {history.map((page, index) => (
          <a
            key={index}
            href={page.link}
            className={`${
              page === history[history.length - 1]
                ? "text-500-black-14"
                : "text-500-dark-14"
            } text-decoration-none d-flex`}
          >
            {page.name.toUpperCase()}
            {page !== history[history.length - 1] && (
              <p className="text-500-dark-14 m-0 mx-2">•</p>
            )}
          </a>
        ))}
      </div>
    </>
  );
}
