import { ReactNode } from "react";

type ButtonProps = {
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  text: string;
  click?: (...args: any[]) => any;
  className?: string;
  width?: string;
  height?: string;
  iconSvg?: ReactNode;
};

export default function Button({
  disabled,
  type,
  text,
  click,
  className,
  width,
  height,
  iconSvg,
}: ButtonProps) {
  return (
    <button
      disabled={disabled || false}
      type={type}
      className={`shadow-sm ${className}${iconSvg ? " d-flex justify-content-center align-items-center" : ""}`}
      style={{
        maxWidth: width || "200px",
        width: "100%",
        minHeight: height || "48px",
        opacity: disabled ? ".4" : "1",
        background: disabled ? "#333" : "",
      }}
      onClick={click}
    >
      <span className="px-2">{text}</span>
      {iconSvg && <>{iconSvg}</>}
    </button>
  );
}
