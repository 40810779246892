import { createContext } from "react";
import { PerfilUsuarioModel } from "../Interfaces/Models";

interface ProfileContextProps extends PerfilUsuarioModel {
  enderecoEditavel: boolean;
  botaoSalvarDesabilitado: boolean;
  setPerfil: React.Dispatch<React.SetStateAction<PerfilUsuarioModel>>;
  setEnderecoEditavel: React.Dispatch<React.SetStateAction<boolean>>;
  setBotaoSalvarDesabilitado: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileContext = createContext<ProfileContextProps>(
  {} as ProfileContextProps,
);

