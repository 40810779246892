import { Button, BreadCrumb, Layout, Loading } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import InfoIngresso from "./Components/InfoIngresso";
import QRCode from "react-qr-code";
import toast from "react-hot-toast";

import {
  IngressoContaDetalheModel,
  IngressoContaModel,
} from "../../Interfaces/Models";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ServiceResult from "../../Interfaces/ServiceResult";
import CarouselIngresso from "./Components/CarouselIngresso";
import saveAs from "file-saver";

export default function DetalhesIngressosEvento() {
  const navigate = useNavigate();

  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "Página inicial",
    },
    {
      link: "/meus-ingressos",
      name: "Meus ingressos",
    },
    {
      link: `/meus-ingressos/${eventoId}`,
      name: "Informações do ingresso",
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ingressos, setIngressos] = useState<
    IngressoContaDetalheModel[] | null
  >(null);

  const ObterIngressos = async () => {
    AxiosClient.get<ServiceResult<IngressoContaDetalheModel[]>>(
      `/conta/ingressos/evento-${eventoId}`,
    )
      .then(({ data: { data } }) => {
        if (data) setIngressos(data);
      })
      .catch(() => toast.error("Não foi possível obter os seus ingressos"))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    ObterIngressos();
  }, []);

  return (
    <>
      <Layout>
        <div className="row g-3">
          <BreadCrumb history={breadCrumbHistory} />

          <div className="col-lg-12">
            <div
              className="bg-white py-3 px-3 px-lg-5 shadow-sm"
              style={{ minHeight: "calc(100vh - 339px)" }}
            >
              <p className="title-h1 text-black m-0">Informações do ingresso</p>

              {isLoading && <Loading container="30vh" />}

              {!isLoading && ingressos && (
                <div className="row my-4 g-3">
                  <div
                    className="rounded"
                    // style={{ background: "#F2F2F2" }}
                  >
                    <CarouselIngresso ingressos={ingressos} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-12 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-6 p-0 d-flex flex-wrap justify-content-center justify-content-lg-end">
              <Button
                className="bc-btn bc-btn-primary mb-3 mb-sm-0"
                text="Voltar"
                click={() => navigate(-1)}
                type="submit"
                width="216px"
                height="40px"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
