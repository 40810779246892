import moment from "moment";

export default function ValidarDataDeVencimentoCartao(data: string): boolean {
  const dataAtual = moment();
  const dataVencimento = moment(data, "MM/YYYY").endOf("month");
  
  if (dataVencimento.isBefore(dataAtual)) {
    return false;
  }
  
  return true;
}