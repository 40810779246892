import { useContext } from "react";

import { Cartao } from "../../../Components";

import { Link } from "react-router-dom";
import { ProfileContext } from "../../../Contexts/ProfileContext";

export default function MeusCartoesCredito() {
  const { cartoes } = useContext(ProfileContext);

  return (
    <div id="meus-cartoes" className="col-lg-12 mt-2">
      <div className="bg-white py-3 px-3 px-lg-5 shadow-sm">
        {/* Título */}
        <p className="title-h1 text-black mb-3 mb-lg-4">
          Meus cartões de crédito
        </p>

        <div className="row g-5">
          {/* Subtítulo + descrição */}
          <div className="col-lg-6">
            <div className="row g-2">
              <div className="col-lg-8">
                <p className="text-500-dark-18">Meio de pagamento cadastrado</p>
                <p className="text-400-black-16">
                  Aqui você pode alterar alguns de seus dados cadastrais, os que
                  estiverem bloqueados você deve entrar em contato com nossa
                  equipe de suporte.
                </p>
              </div>
            </div>
          </div>

          {/* Cartões */}
          <div className="col-lg-6 mt-3 mt-lg-5">
            <div className="row g-3">
              {cartoes &&
                cartoes.map((c) => (
                  <div key={c.id} className="col-lg-6">
                    <Link to={`/meu-perfil/cartao/${c.id}`}>
                      <Cartao
                        id={c.id}
                        bandeira={c.bandeira}
                        digitosIniciais={c.cartaoPrimeirosDigitos}
                        digitosFinais={c.cartaoUltimosDigitos}
                      />
                    </Link>
                  </div>
                ))}

              <div className="col-lg-6">
                <Link
                  to="/meu-perfil/cadastrar-cartao"
                  className="btn w-100 shadow-sm bg-white card-cartao p-3"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="icons">
                      <img
                        src="/assets/images/icons/add_blue_circle.png"
                        alt="add icon"
                        width="30"
                      />
                      <span className="ps-3">Novo cartão</span>
                    </div>
                    <p className="text-400-black-16 m-0 fw-bolder">&gt;</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
