import { Button, BreadCrumb, Layout, Loading } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import MeusCartoesCredito from "./Components/MeusCartoesCredito";
import ServiceResult from "../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { useEffect, useState } from "react";
import { PerfilUsuarioModel } from "../../Interfaces/Models";
import { SomenteNumeros } from "../../Utils";
import { useNavigate } from "react-router-dom";
import MeuEndereco from "./Components/MeuEndereco";
import DadosPessoais from "./Components/DadosPessoais";
import { ProfileContext } from "../../Contexts/ProfileContext";
import EditarPerfilArgs from "../../Interfaces/Args/EditarPerfilArgs";
import { FormatDateJSON, FormatDatePTBR } from "../../Utils/FormatDate";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/meu-perfil",
    name: "Meu perfil",
  },
];

export default function Perfil() {
  const navigate = useNavigate();
  const [perfil, setPerfil] = useState<PerfilUsuarioModel>(
    {} as PerfilUsuarioModel
  );
  const [enderecoEditavel, setEnderecoEditavel] = useState<boolean>(false);
  const [botaoSalvarDesabilitado, setBotaoSalvarDesabilitado] =
    useState<boolean>(false);

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);

  const AtualizarPerfil = (data: PerfilUsuarioModel) => {
    setIsUpdatingProfile(true);

    if (
      !perfil.nomeCompleto ||
      !perfil.telefone ||
      !perfil.dataNascimento ||
      !perfil.email
    ) {
      toast.error(
        "Por favor, preencha todos os campos antes de salvar as alterações."
      );
      setIsUpdatingProfile(false);
      return;
    }

    let args: EditarPerfilArgs = {
      nomeCompleto: data.nomeCompleto,
      email: data.email,
      cpf: data.cpf,
      telefone: SomenteNumeros(data.telefone),
      dataNascimento: FormatDateJSON(data.dataNascimento),
      telefonePaisId: 31,
      sexo: data.sexo === "Não informado" ? null : data.sexo,
      endereco: {
        cep: SomenteNumeros(data.endereco!.cep),
        logradouro: data.endereco?.logradouro,
        numero: data.endereco?.numero,
        complemento: data.endereco?.complemento,
        bairro: data.endereco?.bairro,
        cidade: data.endereco?.cidade,
        unidadeFederacao: data.endereco?.unidadeFederacao,
        pais: data.endereco?.pais,
      },
      permiteNotificacaoSMS: data.permiteNotificacaoSMS,
      permiteNotificacaoWhatsApp: data.permiteNotificacaoWhatsApp,
    };

    toast.promise(
      AxiosClient.put("conta/dados-pessoais", args)
        .then((response) => {
          setIsUpdatingProfile(false);
          return response;
        })
        .catch((error) => {
          setIsUpdatingProfile(false);
          const errorMessage =
            error.response?.data?.messages?.[0]?.message ||
            "Erro ao atualizar o perfil!";
          throw new Error(errorMessage);
        }),
      {
        loading: "Atualizando perfil...",
        success: "Perfil atualizado!",
        error: (err) => `${err.message}`,
      }
    );
  };

  const ObterDadosPessoais = async () => {
    setFetchLoading(true);

    AxiosClient.get<ServiceResult<PerfilUsuarioModel>>("conta/dados-pessoais")
      .then(({ data: { data } }) => {
        setPerfil((prev) => ({
          ...prev,
          ...data,
          dataNascimento: FormatDatePTBR(data?.dataNascimento!),
        }));
      })
      .catch(() => toast.error("Não foi possível obter os dados do perfil!"))
      .finally(() => setFetchLoading(false));
  };

  useEffect(() => {
    ObterDadosPessoais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="row g-3">
        <BreadCrumb history={breadCrumbHistory} />

        <ProfileContext.Provider
          value={{
            ...perfil,
            setPerfil,
            enderecoEditavel,
            setEnderecoEditavel,
            botaoSalvarDesabilitado,
            setBotaoSalvarDesabilitado,
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              AtualizarPerfil(perfil);
            }}
          >
            {fetchLoading ? (
              <Loading container="75vh" />
            ) : (
              <>
                <DadosPessoais />
                <MeuEndereco />
                <MeusCartoesCredito />
              </>
            )}

            <div className="col-lg-12 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-end mt-3">
              <Button
                className="bc-btn bc-btn-light mb-3 mb-lg-0 mx-2"
                text="Cancelar"
                type="button"
                width="216px"
                click={() => navigate(-1)}
                height="40px"
              />

              <Button
                className="bc-btn bc-btn-primary mb-3 mb-sm-0"
                text={isUpdatingProfile ? "Carregando..." : "Salvar alterações"}
                type="submit"
                width="216px"
                disabled={isUpdatingProfile || botaoSalvarDesabilitado}
                height="40px"
              />
            </div>
          </form>
        </ProfileContext.Provider>
      </div>
    </Layout>
  );
}
