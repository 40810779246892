import { WhiteLabel } from "../../Utils";

export default function PlusIcon() {
  return (
    <>
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="14.0377"
          cy="14.0377"
          rx="14.0377"
          ry="14.0377"
          transform="matrix(0 -1 -1 0 28.584 29)"
          fill={WhiteLabel().colorPrimary}
        />
        <path
          d="M14.8382 7.35784C14.6636 7.35784 14.4963 7.42717 14.3729 7.55057C14.2495 7.67398 14.1801 7.84135 14.1801 8.01587L14.1801 14.5961L7.59994 14.5961C7.42543 14.5961 7.25806 14.6654 7.13465 14.7888C7.01125 14.9122 6.94193 15.0796 6.94193 15.2541C6.94193 15.4286 7.01125 15.596 7.13465 15.7194C7.25806 15.8428 7.42543 15.9121 7.59994 15.9121H14.1801V22.4924C14.1801 22.6669 14.2495 22.8343 14.3729 22.9577C14.4963 23.0811 14.6636 23.1504 14.8382 23.1504C15.0127 23.1504 15.18 23.0811 15.3034 22.9577C15.4268 22.8343 15.4962 22.6669 15.4962 22.4924V15.9121H22.0764C22.2509 15.9121 22.4182 15.8428 22.5416 15.7194C22.665 15.596 22.7344 15.4286 22.7344 15.2541C22.7344 15.0796 22.665 14.9122 22.5416 14.7888C22.4182 14.6654 22.2509 14.5961 22.0764 14.5961H15.4962V8.01587C15.4962 7.84135 15.4268 7.67398 15.3034 7.55057C15.18 7.42717 15.0127 7.35784 14.8382 7.35784Z"
          fill="white"
        />
      </svg>
    </>
  );
}
