import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

interface Props {
  numeroPaginas: number;
}

export const TablePagination = ({ numeroPaginas }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  function handlePageChange(pagina: number): void {
    setSearchParams((state) => {
      state.set("page", pagina.toString());

      return state;
    });
  }

  function handlePageSizeChange(paginaTamanho: number): void {
    setSearchParams((state) => {
      state.set("page", "1");
      state.set("size", paginaTamanho.toString());

      return state;
    });
  }

  return (
    <div className="my-3 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center p-0">
      {/* Quantidade de exibição */}

      <div className="dropdown">
        <button
          className="btn btn-dropdown dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Exibir de {paginaTamanho} em {paginaTamanho}
        </button>
        <ul className="dropdown-menu">
          <li>
            <button
              onClick={() => {
                if (paginaTamanho === 4) return;

                handlePageSizeChange(4);
              }}
              className={clsx(
                "dropdown-item d-flex align-items-center",
                paginaTamanho === 4 && "active",
              )}
            >
              Exibir de 4 em 4
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                if (paginaTamanho === 8) return;

                handlePageSizeChange(8);
              }}
              className={clsx(
                "dropdown-item d-flex align-items-center",
                paginaTamanho === 8 && "active",
              )}
            >
              Exibir de 8 em 8
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                if (paginaTamanho === 12) return;

                handlePageSizeChange(12);
              }}
              className={clsx(
                "dropdown-item d-flex align-items-center",
                paginaTamanho === 12 && "active",
              )}
            >
              Exibir de 12 em 12
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                if (paginaTamanho === 16) return;

                handlePageSizeChange(16);
              }}
              className={clsx(
                "dropdown-item d-flex align-items-center",
                paginaTamanho === 16 && "active",
              )}
            >
              Exibir de 16 em 16
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                if (paginaTamanho === 20) return;

                handlePageSizeChange(20);
              }}
              className={clsx(
                "dropdown-item d-flex align-items-center",
                paginaTamanho === 20 && "active",
              )}
            >
              Exibir de 20 em 20
            </button>
          </li>
        </ul>
      </div>

      {/* Navegação por pagina */}
      {numeroPaginas > 1 && (
        <nav className="mt-3 mt-md-0" aria-label="...">
          <ul className="pagination m-0">
            {/* Página anterior */}
            {pagina !== 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina - 1)}
                >
                  &laquo;
                </p>
              </li>
            )}

            {/* Continuação de página anterior */}
            {pagina > 3 && (
              <li className="page-item cursor-pointer">
                <p className="m-0 page-link">...</p>
              </li>
            )}

            {/* Página atual -2 */}
            {pagina - 1 > 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina - 2)}
                >
                  {pagina - 2}
                </p>
              </li>
            )}

            {/* Página atual -1 */}
            {pagina > 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina - 1)}
                >
                  {pagina - 1}
                </p>
              </li>
            )}

            {/* Página atual */}
            <li className="page-item cursor-pointer active">
              <p className="m-0 page-link">{pagina}</p>
            </li>

            {/* Pagina atual +1 */}
            {pagina < numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina + 1)}
                >
                  {pagina + 1}
                </p>
              </li>
            )}

            {/* Pagina atual +2 */}
            {pagina + 1 < numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina + 2)}
                >
                  {pagina + 2}
                </p>
              </li>
            )}

            {/* Continuação de próxima página */}
            {numeroPaginas - pagina > 2 && (
              <li className="page-item cursor-pointer">
                <p className="m-0 page-link">...</p>
              </li>
            )}

            {/* Próxima página */}
            {/* if (PaginaAtual != NumeroPaginas) */}
            {pagina !== numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina + 1)}
                >
                  &raquo;
                </p>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};
