import {
  Button,
  BreadCrumb,
  Input,
  Layout,
  Modal,
  Loading,
} from "../../../Components";
import { Page } from "../../../Components/Breadcrumb";

import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";

import { CartaoModel } from "../../../Interfaces/Models";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/meu-perfil",
    name: "Meu perfil",
  },
  {
    link: "/meu-perfil/cartao/{id}",
    name: "Informações do cartão de crédito",
  },
];

export default function DetalhesCartao() {
  const navigate = useNavigate();
  const { cartaoId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    AxiosClient.get(`conta/cartoes/${cartaoId}`)
      .then((res) => {
        const cartao: CartaoModel = res.data.data;
        setValue("nomeTitular", cartao.titularNome);
        setValue("cep", cartao.endereco.cep);
        setValue("bairro", cartao.endereco.bairro);
        setValue("logradouro", cartao.endereco.logradouro);
        setValue("numero", cartao.endereco.numero);
        setValue("complemento", cartao.endereco.complemento);
        setValue("cidade", cartao.endereco.cidade);
        setValue("estado", cartao.endereco.unidadeFederacao);
        setValue(
          "cartao",
          `${cartao.cartaoPrimeirosDigitos} **** **** ${cartao.cartaoUltimosDigitos}`
        );
      })
      .catch(() => {
        navigate(-1);
        toast.error("Não foi possível obter os dados do cartão");
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletarCartao = async () => {
    setLoading(true);

    AxiosClient.delete(`conta/cartoes/${cartaoId}`)
      .then(() => {
        setIsModalOpen(false);
        navigate(-1);
        toast.success("Cartão removido com sucesso!");
      })
      .catch(() => {
        setIsModalOpen(false);
        toast.error("Nã foi possível excluir o cartão!");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout>
      <div className="row g-3">
        <BreadCrumb history={breadCrumbHistory} />
        <div className="col-lg-12">
          <div
            className="bg-white py-3 px-3 px-lg-5 shadow-sm"
            style={{ minHeight: "calc(100vh - 339px)" }}
          >
            <div className="row g-2">
              <p className="title-h1 text-black mb-4">
                Informações do cartão de crédito
              </p>

              <div className="col-lg-4">
                <p className="text-500-dark-18">Dados pessoais</p>

                <p className="text-400-black-16">
                  Aqui você pode alterar alguns de seus dados cadastrais, os que
                  estiverem bloqueados você deve entrar em contato com nossa
                  equipe de suporte.
                </p>
              </div>
            </div>

            {loading && <Loading container="30vh" />}

            {/* Form */}
            {!loading && (
              <div className="row g-2 mb-4">
                <div className="col-lg-8">
                  <div className="row g-4">
                    {/* Cartão + dados titular */}
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="cartao" className="text-500-dark-18">
                          Número do cartão
                        </label>
                        <Input
                          disabled
                          control={control}
                          defaultValue=""
                          errors={errors}
                          validation={{}}
                          name="cartao"
                          type="text"
                          variant="outlined"
                          height="24px"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="nomeTitular"
                          className="text-500-dark-18"
                        >
                          Nome do titular
                        </label>
                        <Input
                          disabled
                          control={control}
                          defaultValue=""
                          errors={errors}
                          validation={{}}
                          name="nomeTitular"
                          type="text"
                          variant="outlined"
                          height="24px"
                        />
                      </div>
                    </div>

                    {/* Endereço */}
                    <div className="col-lg-6">
                      {/* CEP + bairro */}
                      <div className="row g-2 mb-3">
                        <div className="col-lg-6">
                          <label htmlFor="cep" className="text-500-dark-18">
                            CEP
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="cep"
                            mask="99999-999"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="bairro" className="text-500-dark-18">
                            Bairro
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="bairro"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>
                      </div>

                      {/* Rua + número */}
                      <div className="row g-2 mb-3">
                        <div className="col-lg-8">
                          <label htmlFor="rua" className="text-500-dark-18">
                            Rua
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="logradouro"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>

                        <div className="col-lg-4">
                          <label htmlFor="numero" className="text-500-dark-18">
                            Número
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="numero"
                            placeholder="S/N"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="col-lg-12">
                          <label
                            htmlFor="complemento"
                            className="text-500-dark-18"
                          >
                            Complemento
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="complemento"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>
                      </div>

                      {/* Cidade + UF */}
                      <div className="row g-2 mb-3">
                        <div className="col-lg-8">
                          <label htmlFor="cidade" className="text-500-dark-18">
                            Cidade
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="cidade"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>

                        <div className="col-lg-4">
                          <label htmlFor="estado" className="text-500-dark-18">
                            Estado
                          </label>
                          <Input
                            disabled
                            control={control}
                            defaultValue=""
                            errors={errors}
                            validation={{}}
                            name="estado"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-12 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between">
          <div className="col-12 col-lg-6 p-0 d-flex justify-content-center justify-content-lg-start ">
            <Button
              click={() => setIsModalOpen(true)}
              className="bc-btn bc-btn-light mb-3 mb-lg-0 p-0"
              text="Excluir cartão de crédito"
              type="submit"
              width="216px"
              height="40px"
            />
          </div>
          <div className="col-12 col-lg-6 p-0 d-flex flex-wrap justify-content-center justify-content-lg-end">
            <Button
              className="bc-btn bc-btn-light mb-3 mb-sm-0 me-0 me-sm-3"
              text="Voltar"
              click={() => navigate(-1)}
              type="submit"
              width="216px"
              height="40px"
            />

            {/* <Button
              className="bc-btn bc-btn-primary mb-3 mb-sm-0"
              text="Continuar"
              type="submit"
              width="216px"
              height="40px"
            /> */}
          </div>
        </div>
      </div>

      {/* Modal exclusão de cartão */}
      <Modal
        close={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title="Excluir cartão de crédito"
      >
        <div className="row">
          <div className="col-lg-12">
            <p className="modal-body mb-4">
              Tem certeza que deseja apagar o cartão de crédito selecionado?
            </p>
          </div>
          <div className="col-lg-12 d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end">
            <Button
              click={() => setIsModalOpen(false)}
              text="Cancelar"
              width="130px"
              height="40px"
              className="bc-btn bc-btn-gray mb-3 mb-sm-0 me-0 me-sm-3"
            />

            <Button
              disabled={loading}
              click={deletarCartao}
              width="130px"
              text={loading ? "Carregando..." : "Excluir cartão"}
              height="40px"
              className="bc-btn bc-btn-primary"
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
