import { Button } from "../../../Components";

import { useNavigate } from "react-router-dom";
import { WhiteLabel } from "../../../Utils";

type ContainerTypes = {
  title: string;
  backButtonNavigateTo: string;
  children: string | JSX.Element | JSX.Element[];
};

export default function MinhasComprasMainContent(props: ContainerTypes) {
  const navigate = useNavigate();

  return (
    <>
      {/* Título */}
      <div className="col-lg-12">
        <div className="bg-white py-4 px-3 px-lg-5 shadow-sm">
          <p className="title-h1 text-black m-0">{props.title}</p>
        </div>
      </div>
      <div className="col-lg-12">
        <div
          className="bg-white py-4 px-3 px-lg-5 shadow-sm table-responsive table-responsive-stack"
          style={{ minHeight: "calc(100vh - 457px)" }}
        >
          {props.children}
        </div>
      </div>
      {/* Botões */}
      <div className="col-lg-12 d-flex flex-column flex-sm-row justify-content-center align-items-center justify-content-lg-end">
        <a
          href={`mailto:${WhiteLabel().email}`}
          className="bc-btn bc-btn-light mb-3 mb-sm-0 me-0 me-sm-3 text-decoration-none d-flex justify-content-center align-items-center"
          style={{ width: "200px", height: "40px" }}
        >
          Fale conosco
        </a>
        <Button
          className="bc-btn bc-btn-primary"
          click={() => navigate(props.backButtonNavigateTo)}
          text="Voltar"
          width="200px"
          height="40px"
        />
      </div>
    </>
  );
}
