export default function UserIcon() {
  return (
    <>
      <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.3827 22.9701C24.8831 20.3382 22.5415 18.2875 19.7348 17.1481C21.1306 16.1013 22.1615 14.6419 22.6817 12.9766C23.2019 11.3113 23.185 9.52453 22.6333 7.86941C22.0816 6.21429 21.0231 4.77471 19.6077 3.7546C18.1924 2.73448 16.492 2.18555 14.7473 2.18555C13.0027 2.18555 11.3023 2.73448 9.88693 3.7546C8.4716 4.77471 7.41311 6.21429 6.8614 7.86941C6.3097 9.52453 6.29274 11.3113 6.81294 12.9766C7.33314 14.6419 8.36412 16.1013 9.75984 17.1481C6.95319 18.2875 4.6116 20.3382 3.11202 22.9701C3.0191 23.1195 2.95717 23.2861 2.92991 23.4599C2.90265 23.6337 2.91062 23.8112 2.95334 23.9819C2.99606 24.1526 3.07266 24.3129 3.17859 24.4534C3.28452 24.5939 3.41762 24.7117 3.56997 24.7997C3.72231 24.8877 3.8908 24.9442 4.06542 24.9658C4.24004 24.9874 4.41721 24.9737 4.58642 24.9254C4.75562 24.8772 4.9134 24.7954 5.05037 24.685C5.18734 24.5746 5.30071 24.4377 5.38374 24.2826C7.36562 20.857 10.8656 18.8138 14.7473 18.8138C18.6291 18.8138 22.1291 20.8581 24.1109 24.2826C24.2909 24.5719 24.5763 24.7799 24.9068 24.8627C25.2373 24.9454 25.5871 24.8963 25.8821 24.7259C26.1771 24.5555 26.3943 24.277 26.4877 23.9493C26.5811 23.6216 26.5434 23.2705 26.3827 22.9701ZM9.05983 10.5013C9.05983 9.37645 9.3934 8.27683 10.0184 7.34152C10.6433 6.40622 11.5316 5.67724 12.5708 5.24676C13.6101 4.81629 14.7536 4.70366 15.8569 4.92311C16.9602 5.14257 17.9736 5.68425 18.769 6.47966C19.5644 7.27507 20.1061 8.28849 20.3256 9.39175C20.545 10.495 20.4324 11.6386 20.0019 12.6778C19.5714 13.7171 18.8424 14.6054 17.9071 15.2303C16.9718 15.8553 15.8722 16.1888 14.7473 16.1888C13.2395 16.1871 11.7938 15.5873 10.7276 14.5211C9.66135 13.4548 9.06157 12.0092 9.05983 10.5013Z"
          fill="black"
        />
      </svg>
    </>
  );
}
