import { WhiteLabel } from "../../Utils";

interface HeartIconProps {
  colored: boolean;
}

export default function HeartIcon({ colored }: HeartIconProps) {
  return (
    <>
      {colored ? (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.1875 3.125C15.3041 3.125 13.6325 3.86844 12.5 5.14625C11.3675 3.86844 9.69594 3.125 7.8125 3.125C6.17208 3.12698 4.59942 3.77952 3.43947 4.93947C2.27952 6.09942 1.62698 7.67208 1.625 9.3125C1.625 16.0944 11.5447 21.5131 11.9666 21.7409C12.1305 21.8292 12.3138 21.8754 12.5 21.8754C12.6862 21.8754 12.8695 21.8292 13.0334 21.7409C13.4553 21.5131 23.375 16.0944 23.375 9.3125C23.373 7.67208 22.7205 6.09942 21.5605 4.93947C20.4006 3.77952 18.8279 3.12698 17.1875 3.125ZM16.6728 16.4713C15.3671 17.5792 13.9714 18.5764 12.5 19.4525C11.0286 18.5764 9.63287 17.5792 8.32719 16.4713C6.29562 14.7284 3.875 12.0706 3.875 9.3125C3.875 8.26821 4.28984 7.26669 5.02827 6.52827C5.76669 5.78984 6.76821 5.375 7.8125 5.375C9.48125 5.375 10.8781 6.25625 11.4584 7.67562C11.5429 7.88254 11.6871 8.05961 11.8726 8.18425C12.0581 8.30889 12.2765 8.37545 12.5 8.37545C12.7235 8.37545 12.9419 8.30889 13.1274 8.18425C13.3129 8.05961 13.4571 7.88254 13.5416 7.67562C14.1219 6.25625 15.5188 5.375 17.1875 5.375C18.2318 5.375 19.2333 5.78984 19.9717 6.52827C20.7102 7.26669 21.125 8.26821 21.125 9.3125C21.125 12.0706 18.7044 14.7284 16.6728 16.4713Z"
            fill="#FF0000"
          />
          <path
            d="M16.6728 16.4713C15.3671 17.5792 13.9714 18.5764 12.5 19.4525C11.0286 18.5764 9.63287 17.5792 8.32719 16.4713C6.29562 14.7284 3.875 12.0706 3.875 9.3125C3.875 8.26821 4.28984 7.26669 5.02827 6.52827C5.76669 5.78984 6.76821 5.375 7.8125 5.375C9.48125 5.375 10.8781 6.25625 11.4584 7.67562C11.5429 7.88254 11.6871 8.05961 11.8726 8.18425C12.0581 8.30889 12.2765 8.37545 12.5 8.37545C12.7235 8.37545 12.9419 8.30889 13.1274 8.18425C13.3129 8.05961 13.4571 7.88254 13.5416 7.67562C14.1219 6.25625 15.5188 5.375 17.1875 5.375C18.2318 5.375 19.2333 5.78984 19.9717 6.52827C20.7102 7.26669 21.125 8.26821 21.125 9.3125C21.125 12.0706 18.7044 14.7284 16.6728 16.4713Z"
            fill="#FF0000"
          />
        </svg>
      ) : (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.1875 3.125C15.3041 3.125 13.6325 3.86844 12.5 5.14625C11.3675 3.86844 9.69594 3.125 7.8125 3.125C6.17208 3.12698 4.59942 3.77952 3.43947 4.93947C2.27952 6.09942 1.62698 7.67208 1.625 9.3125C1.625 16.0944 11.5447 21.5131 11.9666 21.7409C12.1305 21.8292 12.3138 21.8754 12.5 21.8754C12.6862 21.8754 12.8695 21.8292 13.0334 21.7409C13.4553 21.5131 23.375 16.0944 23.375 9.3125C23.373 7.67208 22.7205 6.09942 21.5605 4.93947C20.4006 3.77952 18.8279 3.12698 17.1875 3.125ZM16.6728 16.4713C15.3671 17.5792 13.9714 18.5764 12.5 19.4525C11.0286 18.5764 9.63287 17.5792 8.32719 16.4713C6.29562 14.7284 3.875 12.0706 3.875 9.3125C3.875 8.26821 4.28984 7.26669 5.02827 6.52827C5.76669 5.78984 6.76821 5.375 7.8125 5.375C9.48125 5.375 10.8781 6.25625 11.4584 7.67562C11.5429 7.88254 11.6871 8.05961 11.8726 8.18425C12.0581 8.30889 12.2765 8.37545 12.5 8.37545C12.7235 8.37545 12.9419 8.30889 13.1274 8.18425C13.3129 8.05961 13.4571 7.88254 13.5416 7.67562C14.1219 6.25625 15.5188 5.375 17.1875 5.375C18.2318 5.375 19.2333 5.78984 19.9717 6.52827C20.7102 7.26669 21.125 8.26821 21.125 9.3125C21.125 12.0706 18.7044 14.7284 16.6728 16.4713Z"
            fill={WhiteLabel().colorPrimary}
          />
        </svg>
      )}
    </>
  );
}
