enum GrantType {
  /// <summary>
  /// Concessão de token por credenciais do cliente.
  /// </summary>
  ClientCredentials = "ClientCredentials",
  /// <summary>
  /// Concessão de token por senha.
  /// </summary>
  Password = "Password",
  /// <summary>
  /// Concessão de token sem senha.
  /// </summary>
  Passwordless = "Passwordless",
  /// <summary>
  /// Concessão de token por autenticação de dois fatores.
  /// </summary>
  TwoFactorAuthentication = "TwoFactorAuthentication",
  /// <summary>
  /// Concessão de token por atualização de token.
  /// </summary>
  RefreshToken = "RefreshToken",
  /// <summary>
  /// Concessão de token por portaria.
  /// </summary>
  Portaria = "Portaria",
  /// <summary>
  /// Concessão de token por magic link.
  /// </summary>
  MagicLink = "MagicLink",
}

export default GrantType;
