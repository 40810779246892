import Header from "./Header";
import Footer from "./Footer";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmbeddedStore } from "../Stores/embedded.store";

type ChildrenTypes = {
  children: ReactNode;
  background?: string;
};

export default function Layout({ children, background }: ChildrenTypes) {
  const { embedded, setEmbedded } = useEmbeddedStore();

  useEffect(() => {
    if (window.location.href.includes("embedded")) {
      setEmbedded(true);
    }
  }, []);

  return embedded ? (
    <div style={{ background }}>
      <section className="main-section">
        <div className="container py-4">{children}</div>
      </section>
    </div>
  ) : (
    <div style={{ background }}>
      <Header />
      <section className="main-section">
        <div className="container py-4">{children}</div>
      </section>
      <Footer />
    </div>
  );
}
