import { useState } from "react";
import ViaCEPClient from "../Services/ViaCEPClient";
import { ViaCepModel } from "../Interfaces/Models";

export function useViaCep() {
  const [fetchingCep, setFetchingCep] = useState<boolean>(false);

  const FetchCep = async (cep: string): Promise<ViaCepModel> => {
    setFetchingCep(true);

    return ViaCEPClient.get(`/ws/${cep}/json`)
      .then(({ data }) => {
        return data as ViaCepModel;
      })
      .catch((error: any) => {
        return error.response.data as ViaCepModel;
      })
      .finally(() => setFetchingCep(false));
  };

  return { FetchCep, fetchingCep };
}
