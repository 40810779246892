interface WhiteLabelProps {
  colorPrimary: string;
  email: string;
}

const WhiteLabel = (): WhiteLabelProps => {
  const tenant = process.env.REACT_APP_TENANT;

  let whiteLabel: WhiteLabelProps = {} as WhiteLabelProps;

  switch (tenant) {
    case "TICKETSAPP":
      whiteLabel = {
        colorPrimary: "#F82853",
        email: "atendimento@ticketsapp.com.br",
      };
      break;

    default:
      whiteLabel = {
        colorPrimary: "#003967",
        email: "atendimento@brasilcash.com.br",
      };
      break;
  }

  return whiteLabel;
};

export default WhiteLabel;
