interface LocalIconProps {
  dark?: boolean;
}

export default function LocalIcon({ dark }: LocalIconProps) {
  return (
    <>
      {dark ? (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.5922 11.8775C22.5922 15.1207 20.4792 18.2832 18.1392 20.7532C16.9931 21.9629 15.843 22.9537 14.9782 23.6424C14.6857 23.8752 14.427 24.0727 14.2134 24.2315C13.9998 24.0727 13.7411 23.8752 13.4487 23.6424C12.5838 22.9537 11.4338 21.9629 10.2876 20.7532C7.94769 18.2832 5.83464 15.1207 5.83464 11.8775C5.83464 9.6553 6.7174 7.52412 8.28873 5.95279C9.86005 4.38146 11.9912 3.4987 14.2134 3.4987C16.4356 3.4987 18.5668 4.38146 20.1381 5.95279C21.7095 7.52412 22.5922 9.6553 22.5922 11.8775Z"
            stroke="black"
            strokeWidth="2.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2302 11.8791C16.2302 12.992 15.328 13.8942 14.215 13.8942C13.1021 13.8942 12.1999 12.992 12.1999 11.8791C12.1999 10.7661 13.1021 9.86393 14.215 9.86393C15.328 9.86393 16.2302 10.7661 16.2302 11.8791Z"
            stroke="black"
            strokeWidth="2.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.3325 11.8794C23.3325 15.1226 21.2194 18.2852 18.8794 20.7551C17.7333 21.9649 16.5832 22.9557 15.7184 23.6443C15.426 23.8772 15.1673 24.0746 14.9537 24.2335C14.7401 24.0746 14.4813 23.8772 14.1889 23.6443C13.3241 22.9557 12.174 21.9649 11.0279 20.7551C8.68793 18.2852 6.57487 15.1226 6.57487 11.8794C6.57487 9.65725 7.45763 7.52607 9.02896 5.95474C10.6003 4.38341 12.7315 3.50065 14.9537 3.50065C17.1759 3.50065 19.307 4.38341 20.8784 5.95474C22.4497 7.52607 23.3325 9.65725 23.3325 11.8794Z"
            stroke="white"
            strokeWidth="2.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.9685 11.8791C16.9685 12.992 16.0662 13.8942 14.9533 13.8942C13.8404 13.8942 12.9382 12.992 12.9382 11.8791C12.9382 10.7661 13.8404 9.86393 14.9533 9.86393C16.0662 9.86393 16.9685 10.7661 16.9685 11.8791Z"
            stroke="white"
            strokeWidth="2.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
}
