import { Button, Modal } from "../../../Components";
import { CheckSuccessModalIcon } from "../../../Components/Icons";

import { useNavigate } from "react-router-dom";

type ModalProps = {
  modalIsOpen: boolean;
};

export default function ModalSucessoIngressoTransferido(props: ModalProps) {
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={props.modalIsOpen} close={() => navigate("/")}>
        <div className="row justify-content-center text-center">
          <div className="col-lg-9">
            <div className="row mb-4">
              <div className="col-lg-12">
                <CheckSuccessModalIcon />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-12">
                <p className="title-h2 text-black mb-2">
                  Solicitação enviada com sucesso!
                </p>
                <p className="text-400-black-16 mb-2">
                  Agora é só aguardar, a pessoa que você enviou deve aceitar o
                  seu ingresso.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-lg-12">
            <Button
              click={() => navigate("/meus-ingressos")}
              text="Voltar para meus ingressos"
              className="bc-btn bc-btn-primary mb-3"
              width="90%"
              height="56px"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
