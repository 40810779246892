import { useState } from "react";

type FiltroBuscaProps = {
  aplicarFiltro: (query: string) => void;
};

export default function FiltroBusca({ aplicarFiltro }: FiltroBuscaProps) {
  const [querySelecionada, setQuerySelecionada] = useState<string>("proximos");

  const handleButtonClick = (query: string) => {
    aplicarFiltro(query);
    setQuerySelecionada(query);
  };

  const botoes = [
    { query: "proximos", label: "próximos" },
    { query: "concluidos", label: "concluídos" },
  ];

  return (
    <div className="row my-4 mb-lg-0">
      <div className="col-lg-12 d-flex flex-wrap align-items-center">
        {botoes.map(({ query, label }) => (
          <button
            key={query}
            onClick={() => handleButtonClick(query)}
            className={`text-400-dark-16 border-0 bg-transparent py-2 me-3 ${
              querySelecionada === query ? "text-black" : ""
            }`}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}
