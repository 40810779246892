import { useEffect } from "react";
import { Layout } from "../../Components";

import { WhiteLabel } from "../../Utils";

export default function TermosDeServico() {
  /**
   * Gambiarra: por algum motivo quando abre essa página a partir do footer o scroll fica no meio da página, então
   * foi feito esse useEffect para forçar o scroll para o topo da página.
   */
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0
    });
  }, [])

  return (
    <Layout>
      <div className="row g-3">
        <div className="col-lg-12">
          <div className="bg-white py-4 px-3 px-lg-5 shadow-sm">
            <p className="title-h1 text-black m-0">Termos de serviço</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div
            className="bg-white py-4 px-3 px-lg-5 shadow-sm"
            style={{ minHeight: "calc(100vh - 339px)" }}
          >
            <p className="text-500-black-16 mb-2">1. Definição do serviço</p>
            <p className="text-400-black-16 mb-2">
              A Tickets App é uma plataforma tecnológica online disponibilizada
              aos seus usuários compradores e vendedores para que eles possam
              divulgar seus eventos e transacionar ingressos entre si. A Tickets
              App não está envolvida diretamente na organização de quaisquer dos
              eventos anunciados.
            </p>
            <p className="text-400-black-16 mb-2">
              Ao fazer a compra de um ingresso por meio do uso da plataforma da
              Tickets App, e sendo confirmado o pagamento, você entra em uma
              relação contratual direta (de vínculo jurídico) com o organizador
              e reconhece que ele é o único e exclusivo responsável pelo evento
              anunciado, o qual é apenas divulgado e comercializado através do
              uso da plataforma da Tickets App, que atua apenas como
              licenciadora desta tecnologia/plataforma para seus usuários
              compradores e vendedores.
            </p>

            <p className="text-500-black-14 mb-2">1.1 Vantagens aos usuários</p>
            <p className="text-400-black-16 mb-2">
              Aos usuários, a Tickets App permite a aquisição segura de
              ingressos através do uso da sua plataforma, além de disponibilizar
              uma experiência única de navegação e pesquisa dos mais diversos
              tipos de eventos anunciados por diferente organizadores de eventos
              ou usuários vendedores.
            </p>
            <p className="text-400-black-16 mb-2">
              A Tickets App também poderá enviar para o e-mail associado à sua
              conta sugestões de eventos com base nesses dados, assim como
              informações pertinentes aos eventos em que você tenha se
              cadastrado, as quais você poderá optar por deixar de receber a
              qualquer momento.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              2. Requisitos para utilização do serviço
            </p>
            <p className="text-400-black-16 mb-2">
              Você declara ser residente e domiciliado no território brasileiro
              e estar em pleno gozo de seus direitos civis, assumindo total
              responsabilidade pela veracidade de quaisquer informações que
              prestar durante seu cadastro ou durante a utilização da
              plataforma, e pelas consequências da divulgação de informações que
              tornar públicas na plataforma, bem como declara ser titular ou
              estar autorizado a utilizar qualquer material protegido por
              direitos autorais ou propriedade intelectual que divulgar na
              plataforma, isentando a Tickets App de qualquer responsabilidade
              por essas informações e conteúdos.
            </p>

            <p className="text-400-black-16 mb-2">
              Ao utilizar a Tickets App, você declara ter conhecimento de que o
              serviço é fornecido “COMO FORNECIDOS” (as is), e que a Tickets
              App:
            </p>
            <ul>
              <li>
                Não assume qualquer responsabilidade pela veracidade das
                informações divulgadas por seus usuários;
              </li>
              <li>
                Não é responsável pela organização dos eventos divulgados na
                plataforma e simplesmente atua como facilitadora da venda de
                ingressos, não garantindo, portanto, a realização dos eventos,
                nem assumindo qualquer responsabilidade por
                cancelamentos,alterações, atrasos ou por qualquer fato ocorrido
                no decorrer do evento, sendo que, em tais casos a
                responsabilidade será integralmente dos respectivos
                organizadores;
              </li>
              <li>
                Não mantém armazenado em seu banco de dados os seus dados
                bancários ou de pagamento, nem qualquer responsabilidade por
                eventuais prejuízos decorrentes da utilização de serviços de
                terceiros para intermediar os pagamentos.
              </li>
              <li>
                Não é responsável por fraudes praticadas por terceiros (hackers)
                que ocorram durante o processamento do pagamento pelo usuário,
                tais como a furto de dados bancários ou de cartão de crédito, ou
                ainda, vírus, trojans e qualquer outro tipo de malware instalado
                no computador, dispositivo móvel, tablet ou navegador do usuário
                e que altere os dados do boleto bancário gerado para pagamento.
              </li>
            </ul>

            <p className="text-400-black-16 mb-2">
              A utilização do serviço está condicionada a que o usuário mantenha
              conduta cordial com os demais usuários e que se abstenha de
              tentativas de utilizar para fins escusos ou criminosos, burlar,
              copiar ou distribuir sem autorização qualquer parte das
              propriedades da Tickets App –ClOSE FRIENDS PARTICIPACOES E LTDA.,
              inclusive marcas, símbolos, logotipos, slogans, interface e
              softwares associados à plataforma.
            </p>

            <p className="text-400-black-16 mb-2">
              Você também declara dispor dos meios e atender aos requisitos
              necessários para ter acesso e utilizar sua conta, assim como os
              ingressos que venha a adquirir, tais como computador conectado à
              internet, impressora e idade mínima para admissão no evento.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">3. Dos vouchers</p>
            <p className="text-400-black-16 mb-2">
              Ingressos são documentos ao portador. Assim, uma vez emitidos,
              qualquer pessoa poderá utilizá-los. Por esse motivo, a guarda e
              conservação dos ingressos são de inteira responsabilidade do
              usuário. A Tickets App não se responsabiliza pela perda, extravio,
              furto ou roubo dos seus ingressos.
            </p>
            <p className="text-400-black-16 mb-2">
              Cada ingresso só poderá ser utilizado uma vez. Uma vez utilizados,
              não será permitida em qualquer hipótese a troca do ingresso nem
              sua reutilização, ainda que pelo usuário responsável pela compra
              devidamente identificado. Nesses casos também não haverá devolução
              dos valores.
            </p>
            <p className="text-400-black-16 mb-2">
              Caso você suspeite que seu ingresso tenha sido extraviado, furtado
              ou roubado, comunique imediatamente a Tickets App para que esta
              possa intermediar e ajudar na busca pela melhor solução para os
              usuários.
            </p>

            <p className="text-500-black-14 mb-2">Cupom de Desconto</p>
            <p className="text-400-black-16 mb-2">
              A critério exclusivo dos organizadores, serão disponibilizados
              cupons de desconto para determinados ingressos de eventos vendidos
              pela Plataforma da Tickets App. O desconto será aplicado somente
              sobre o valor de face dos ingressos.
            </p>
            <p className="text-400-black-16 mb-2">
              A taxa de serviço cobrada pela Plataforma Tickets App será
              calculada e cobrada com base no valor de face dos ingressos
              anteriormente à aplicação do cupom de desconto
            </p>
            <p className="text-400-black-16 mb-2">
              A taxa de processamento, se aplicável, será calculada e cobrada
              com base no valor dos ingressos após a aplicação do cupom de
              desconto.
            </p>
            <p className="text-400-black-16 mb-2">
              Os cupons de desconto poderão ser incluídos no processo de compra
              dos ingressos elegíveis, antes do pagamento, no campo: "Cupom de
              desconto", ou, poderão ser aplicados automaticamente no caso de
              compartilhamento de link para compra dos ingressos com o cupom de
              desconto já incluso.
            </p>
            <p className="text-400-black-16 mb-2">
              A limitação de uso dos cupons de desconto também é determinada
              exclusivamente pelos organizadores dos eventos e, caso o valor do
              desconto seja maior do que o do ingresso, o valor de face do
              ingresso será zerado e somente serão cobradas as eventuais taxas
              aplicáveis.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              4. Da entrada nos eventos
            </p>
            <p className="text-400-black-16 mb-2">
              A aquisição de ingressos através do uso da Plataforma da Tickets
              App não garante vantagens ou prioridade de entrada no local do
              evento. O atraso do convidado para entrar no local do evento pode
              implicar que sua entrada seja adiada até o intervalo, por isso se
              recomenda a chegada ao evento com pelo menos uma hora de
              antecedência.
            </p>
            <p className="text-400-black-16 mb-2">
              Você declara estar ciente e concordar que os organizadores podem,
              eventualmente, impor requisitos ou restrições à entrada no local
              do evento. A Tickets App não possui qualquer responsabilidade pelo
              controle da entrada, nem por quaisquer constrangimentos que a
              organização do evento possa impor nesse momento.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              5. Das devoluções de valores
            </p>
            <p className="text-400-black-16 mb-2">
              Todas as compras são finais e em nenhuma hipótese haverá devolução
              dos valores pagos pelos ingressos ou pelos serviços prestados pela
              Tickets App, salvo em casos de arrependimento ou por expressa
              determinação dos organizadores. Portanto, certifique-se de que leu
              com atenção e concorda com todas as informações e condições
              estabelecidas pelos organizadores do evento.
            </p>
            <p className="text-400-black-16 mb-2">
              Em casos de arrependimento, o Código de Defesa do Consumidor
              (Artigo 49) prevê que em até 7 (sete) dias o consumidor pode
              desistir da compra, desde que esse prazo não ultrapasse 48 horas
              antes do evento. O reembolso é realizado via um processador de
              pagamentos online pela mesma forma de pagamento utilizada na
              compra, descontada a taxa de conveniência (se houver), no prazo de
              até 45 dias após o cancelamento. No caso de compras com cartões de
              crédito, o valor será devolvido como crédito nas faturas
              seguintes.
            </p>
            <p className="text-400-black-16 mb-2">
              Após os prazos supracitados não é possível desistir de uma compra
              visto que os valores intermediados não ficam em poder da Tickets
              App sendo repassados em sua totalidade aos responsáveis pelos
              espetáculos, inclusive, é de responsabilidade dos realizadores do
              evento a devolução, troca, cancelamento e transferência de
              qualquer ingresso ou evento.
            </p>
            <p className="text-400-black-16 mb-2">
              Somente será possível efetuar o cancelamento integral de uma
              compra, não sendo permitido o seu cancelamento parcial. Desta
              forma, não será possível o cancelamento de um ou mais itens
              (ingressos) dentro de uma compra, se tais itens não corresponderem
              a totalidade da compra.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              6. Da segurança e privacidade
            </p>

            <p className="text-500-black-14 mb-2">6.1 Sua senha</p>
            <p className="text-400-black-16 mb-2">
              Cada usuário é responsável pela segurança de sua senha, e, por
              consequência, das operações que utilizem sua senha.
            </p>
            <p className="text-400-black-16 mb-2">
              Você poderá alterar sua senha a qualquer momento. Recomendamos que
              evite senhas como aniversários e nomes, e que utilize uma senha
              com pelo menos oito caracteres, dentre números e letras, contendo
              letras maiúsculas e minúsculas.
            </p>
            <p className="text-400-black-16 mb-2">
              Também é de sua responsabilidade a segurança e manutenção da conta
              de e-mail associada à sua conta, que poderá ser utilizada para
              fins de recuperação da sua senha.
            </p>
            <p className="text-500-black-14 mb-2">
              6.2 Utilização da plataforma
            </p>
            <p className="text-400-black-16 mb-2">
              Você é responsável pelas consequências decorrentes daquilo que
              optar tornar público no site, inclusive dados pessoais, locais
              onde se encontra ou estará no futuro, comparecimento a eventos, as
              pessoas que o irão acompanhar, dentre outras informações.
              Disponibilize este conteúdo somente se sentir confortável em
              compartilhá-lo com outras pessoas e em conformidade com estes
              Termos de Serviço.
            </p>
            <p className="text-500-black-14 mb-2">
              6.3 Coleta e proteção de dados
            </p>
            <p className="text-400-black-16 mb-2">
              Ao utilizar a Tickets App, você autoriza a coleta e utilização de
              dados pessoais e socioeconômicos e de estatísticas de navegação,
              resguardado o sigilo das informações coletadas nos termos da nossa
              Política de Privacidade.
            </p>
            <p className="text-500-black-14 mb-2">
              6.3.1 Dos pedidos extrajudiciais de violação do sigilo
            </p>
            <p className="text-400-black-16 mb-2">
              Em caso de notificação ou petição extrajudicial requerendo sejam
              revelados seus dados cadastrais ou de navegação, inclusive
              endereço IP, em virtude de suposto uso da internet para fins
              ilegais ou ilegítimos, você será comunicado do pedido para, no
              prazo de 5 (cinco) dias, autorizar ou não a divulgação dos dados
              solicitados.
            </p>
            <p className="text-400-black-16 mb-2">
              Com sua autorização tempestiva, a Tickets App responderá a
              notificação revelando os dados solicitados.
            </p>
            <p className="text-400-black-16 mb-2">
              Caso não responda à comunicação da Tickets App dentro do prazo ou
              não autorize a divulgação dos seus dados, informaremos ao
              solicitante que as informações só poderão ser prestadas mediante
              ordem judicial. Nessa hipótese, você estará desde já obrigado a
              arcar com quaisquer custas, despesas, condenação e honorários
              advocatícios sucumbenciais e contratuais impostos à Tickets App em
              razão de eventual demanda judicial para revelação dos referidos
              dados.
            </p>
            <p className="text-500-black-14 mb-2">
              6.3.2 Dos pedidos judiciais de violação do sigilo
            </p>
            <p className="text-400-black-16 mb-2">
              A Tickets App atenderá, sem necessidade de comunicação prévia a
              você, qualquer ordem de autoridade judicial competente que tenha
              por fim determinar seja revelada sua identidade ou quaisquer dos
              dados fornecidos à Tickets App ou por ela coletados em virtude da
              sua utilização da plataforma.
            </p>
            <p className="text-500-black-14 mb-2">
              6.4 Correspondências eletrônicas
            </p>
            <p className="text-400-black-16 mb-2">
              Você toma ciência e concorda que a Tickets App poderá utilizar o
              endereço de e-mail associado à sua conta para enviar mensagens
              administrativas comunicando alterações do serviço, destes termos
              de serviço, da política de privacidade ou quaisquer outras
              pertinentes à sua conta. Estas mensagens são parte integrante do
              serviço oferecido e você não terá opção de recusá-las.
            </p>
            <p className="text-400-black-16 mb-2">
              Você desde logo autoriza o envio de correspondências ao endereço
              de e-mail associado à sua conta com sugestões de atrações baseadas
              em suas estatísticas de navegação e dados pessoais e
              socioeconômicos, as quais poderá optar por deixar de receber a
              qualquer momento.
            </p>
            <p className="text-400-black-16 mb-2">
              De igual forma, você desde logo autoriza o envio de
              correspondências ao endereço de e-mail associado à sua conta com
              informações pertinentes fornecidas pelos organizadores de eventos
              nos quais tenha se cadastrado, as quais poderá optar por deixar de
              receber a qualquer momento.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              7. Das limitações de Responsabilidade
            </p>
            <p className="text-500-black-14 mb-2">
              7.1 OS SERVIÇOS SÃO DISPONIBILIZADOS COMO FORNECIDOS (AS IS)
            </p>
            <p className="text-400-black-16 mb-2">
              A Tickets App é uma plataforma tecnológica online disponibilizada
              aos seus usuários compradores e vendedores para que eles possam
              divulgar seus eventos e transacionar
            </p>
            <p className="text-400-black-16 mb-2">
              ingressos entre si. A Tickets App não está envolvida diretamente
              na organização de quaisquer dos eventos anunciados.
            </p>
            <p className="text-400-black-16 mb-2">
              Dessa forma, seus serviços são oferecidos “COMO FORNECIDOS” (“as
              is”), sem que isso implique seu envolvimento direto com a
              organização dos eventos ou garantia própria de que os eventos
              serão realizados nos termos divulgados pelos organizadores.
            </p>
            <p className="text-400-black-16 mb-2">
              A Tickets App não presta serviços de entretenimento e tampouco tem
              por atividade comercial a promoção ou a organização de eventos de
              qualquer tipo. Apenas fornece uma plataforma de comunicação e
              interação que serve de ferramenta facilitadora da aproximação e do
              contato entre os organizadores e os usuários interessados nos
              eventos por eles divulgados.
            </p>
            <p className="text-400-black-16 mb-2">
              Você reconhece e concorda que a Tickets App não realiza a seleção
              dos organizadores de eventos, não fiscaliza sua atividade, não
              atesta sua idoneidade, nem a sua capacidade legal, administrativa,
              física ou econômica para realizar ou promover os eventos que eles
              divulgam, por sua conta e risco, na plataforma. Você também está
              ciente de que os dados do organizador, incluindo, mas não se
              limitando a isto, seu nome, endereço e demais dados do evento,
              como local, horário, tempo de duração e etc., foram fornecidos
              pelo próprio organizador. Ao aceitar adquirir o ingresso por meio
              do uso da nossa plataforma, você reconhece que a Tickets App não
              possui qualquer participação na relação contratual estabelecida
              entre convidado (adquirente do ingresso) e organizador do evento.
            </p>
            <p className="text-400-black-16 mb-2">
              Você declara ter ciência de que a responsabilidade civil,
              criminal, comercial, ambiental, fiscal, trabalhista, de natureza
              moral ou material, decorrente do cancelamento, atraso, alteração,
              organização, segurança, bem como de qualquer fato ocorrido no
              decorrer do evento é EXCLUSIVA dos respectivos organizadores e não
              poderá NUNCA ser imputada à Tickets App.
            </p>
            <p className="text-400-black-16 mb-2">
              A Tickets App não oferece qualquer garantia de que a plataforma
              descrita nestes Termos de Serviço estará disponível de maneira
              ininterrupta, estando sujeita a paralisações, parciais ou totais,
              decorrentes de falta temporária de energia elétrica, de pane nos
              serviços das concessionárias, de falhas nos serviços oferecidos
              pela Embratel, Oi, RNP e outros provedores, de greves, tumultos ou
              quaisquer outros fatos que não estejam diretamente ligados ao
              serviço e pelos quais não assume qualquer responsabilidade.
            </p>

            <p className="text-500-black-14 mb-2">
              7.2 Informações fornecidas por terceiros
            </p>
            <p className="text-400-black-16 mb-2">
              Por se tratar de uma plataforma social aberta ao público, a
              Tickets App divulga informações publicadas por iniciativa de
              terceiros, usuários dos serviços que disponibiliza. Os usuários da
              plataforma assumem a responsabilidade exclusiva pela utilização
              dos serviços da Tickets App e pelas consequências dele
              resultantes, bem como pela veracidade e boa-fé de qualquer
              conteúdo submetido, publicado, reproduzido, comunicado,
              disponibilizado ou exibido por sua iniciativa no site.
            </p>
            <p className="text-400-black-16 mb-2">
              Embora a Tickets App se reserve o direito de remover material
              criminoso, ofensivo, discriminatório ou racista, bem como a
              suspender as contas associadas a quaisquer práticas que violem
              estes Termos de Serviço, você concorda e compreende que isto não
              implica qualquer responsabilidade da Tickets App pelo controle
              prévio ou fiscalização do material publicado pelos usuários da
              plataforma.
            </p>

            <p className="text-500-black-14 mb-2">
              7.3 Conteúdo gerenciado por terceiros
            </p>
            <p className="text-400-black-16 mb-2">
              Durante sua experiência com a Tickets App, você poderá encontrar
              materiais armazenados em serviços externos, notadamente blogs,
              microblogs, redes sociais e provedores de conteúdo multimídia, que
              serão geridos conforme os Termos de Serviço e Políticas de
              Privacidade dos respectivos serviços, sendo o conteúdo de inteira
              responsabilidade dos serviços ou usuários responsáveis por sua
              publicação, conforme o caso.
            </p>
            <p className="text-500-black-14 mb-2">7.4 Links externos</p>
            <p className="text-400-black-16 mb-2">
              Ao utilizar a nossa plataforma, você poderá se deparar com links
              para outros websites indicados por nossos usuários, sobre os quais
              a Tickets App não assume qualquer responsabilidade. A existência
              de links para websites de terceiros não implica apoio,
              recomendação ou garantia de disponibilidade ou qualidade do
              conteúdo pela Tickets App.
            </p>
            <p className="text-400-black-16 mb-2">
              Ao clicar nesses links, você declara ter pleno conhecimento de que
              estará deixando o domínio da Tickets App e qualquer garantia
              oferecida por estes termos de serviço ou por nossa política de
              privacidade, passando a se submeter às regras do endereço ao qual
              for direcionado, se houver
            </p>
            <p className="text-500-black-14 mb-2">
              7.5 Operadores de pagamentos
            </p>

            <p className="text-400-black-16 mb-2">
              A Tickets App não gerencia diretamente os pagamentos efetuados por
              seus usuários e não armazena os seus dados bancários ou dos seus
              cartões de crédito, utilizando, para maior segurança, serviços
              independentes especializados em receber e repassar pagamentos (BR
              CASH).
            </p>
            <p className="text-400-black-16 mb-2">
              Assim, a Tickets App não assume qualquer responsabilidade por
              erros, contratempos ou prejuízos decorrentes do uso desses
              serviços, inclusive, mas não limitados a: perda de valores,
              cobranças em excesso, atrasos na confirmação de pagamentos ou
              divulgação de dados de uso desses serviços, cadastrais, bancários
              ou sigilosos fornecidos durante as transações.
            </p>
            <p className="text-400-black-16 mb-2">
              A Tickets App não se responsabiliza pelas fraudes e nem por
              qualquer dano causado pela ação de terceiros falsários que, por
              meios escusos, furtarem os dados bancários e/ou dados dos cartões
              de crédito dos usuários ou qualquer outra forma de pagamento.
              Também não se responsabiliza pelos vírus, trojans, malwares ou
              qualquer outro programa malicioso instalado no computador, tablet,
              celular, dispositivo móvel ou navegador do usuário e que efetuem a
              modificação dos números gerados para pagamento por boleto
              bancário.
            </p>
            <p className="text-400-black-16 mb-2">
              As compras realizadas pelos usuários poderão ser canceladas a
              qualquer momento e a sua causa não poderá ser declarada pela
              Tickets App, pois são informações protegidas pelo sigilo bancário.
              Para estes casos, os usuários deverão entrar em contato com a sua
              instituição financeira e/ou operadora do cartão de débito/crédito
              que possui relação jurídica.
            </p>
            <p className="text-400-black-16 mb-2">
              Adicionalmente, a Tickets App poderá, de maneira discricionária,
              requerer o cancelamento da compra de ingresso realizada pelos
              usuários quando esta suspeitar de ações escusas, tais como mas sem
              se limitar a cambistas, por exemplo. Esta medida é uma medida
              protetiva visando garantir uma maior distribuição de ingressos aos
              consumidores finais.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">8. Da propaganda</p>

            <p className="text-500-black-14 mb-2">
              8.1 Da utilização da marca Tickets App
            </p>
            <p className="text-400-black-14 mb-2">
              A licença para utilização de marcas, símbolos, logotipos ou
              slogans de propriedade da Tickets App – ClOSE FRIENDS
              PARTICIPACOES E LTDA.. se limita à propaganda de eventos que
              tenham ingressos disponibilizados por meio do uso da plataforma da
              Tickets App.
            </p>
            <p className="text-400-black-14 mb-2">
              Todo material promocional de eventos que tenham a disponibilização
              de ingressos para compra e venda por meio do uso da plataforma da
              Tickets App produzido após o início da divulgação do evento na
              plataforma deverá conter as marcas associadas a Tickets App, ou,
              ao mínimo, a indicação do endereço eletrônico ticketsapp.com.br
              (http://www.ticketsapp.com.br) como local para aquisição de
              ingressos, ou qualquer outro que venha a substituí-lo
            </p>
            <p className="text-500-black-14 mb-2">
              8.2 Do material promocional no website
            </p>
            <p className="text-400-black-14 mb-2">
              A Tickets App poderá estabelecer limites ao tamanho, dimensões e
              formato da publicidade a ser veiculada no site.
            </p>
            <p className="text-400-black-14 mb-2">
              Desde já, os organizadores autorizam a Tickets App a adaptar,
              conforme critérios próprios, a publicidade que esteja em
              desconformidade com os formatos gráficos utilizados no site.
            </p>

            <p className="text-500-black-14 mb-2">
              8.3 Da autorização para exploração de direitos autorais ou
              propriedades intelectuais
            </p>
            <p className="text-400-black-14 mb-2">
              Os usuários declaram ser proprietários ou estarem autorizados a
              utilizar quaisquer marcas, símbolos, logotipos, slogans ou
              quaisquer outros objetos protegidos pelas leis de direitos
              autorais e propriedade intelectual que sejam divulgados no Tickets
              App.
            </p>
            <p className="text-400-black-14 mb-2">
              A mesma declaração é válida para qualquer material publicitário no
              qual sejam utilizadas quaisquer das marcas, símbolos, logotipos ou
              slogans associados ao Tickets App.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              9. Do processamento das notícias de violação a direitos autorais
              ou de propriedades intelectuais
            </p>
            <p className="text-400-black-16 mb-2">
              Caso terceiro acredite que seu direito autoral ou parte de sua
              propriedade intelectual tenha sido utilizada sem autorização ou em
              desconformidade com a autorização concedida, deverá entrar em
              contato com a Tickets App pelo e-mail{" "}
              <a className="text-break" href={`mailto:${WhiteLabel().email}`}>
                {WhiteLabel().email}
              </a>
            </p>

            <p className="text-400-black-16 mb-2">
              Comprovada a violação ou estabelecida fundada dúvida sobre a
              autorização para utilizar a propriedade contestada, você desde já
              autoriza a Tickets App a promover sua imediata remoção,
              independentemente de prévia comunicação.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              10. Das violações aos Termos de Serviço
            </p>
            <p className="text-400-black-16 mb-2">
              Quaisquer violações a estes termos de serviço ou tentativas de
              utilizar a plataforma, os ingressos ou qualquer propriedade da
              Tickets App – ClOSE FRIENDS PARTICIPACOES E LTDA. para fins
              escusos estão sujeitas a suspensão ou cancelamento da(s) conta(s)
              associada(s), a critério da Tickets App e sem prévio aviso, sem
              prejuízo das providências legais cabíveis.
            </p>
            <p className="text-400-black-16 mb-2">
              Isto inclui, mas não está limitado a: tentativas de burlar
              (hackear ou crackear) a segurança da plataforma; tentativas de
              obtenção de dados sigilosos de usuários; distribuição de vírus ou
              outros softwares maliciosos; fraudes; condutas injuriosas,
              difamatórias ou caluniosas; cancelamentos ou alterações de
              eventos; violações de direitos autorais ou propriedades
              intelectuais; dentre outros.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              11. Do prazo decadencial
            </p>

            <p className="text-400-black-16 mb-2">
              Quaisquer reclamações judiciais ou administrativas decorrentes da
              prestação dos serviços objeto deste Termo de Serviço ou da
              Política de Privacidade a eles associada deverão ser propostas até
              90 (noventa) dias após o fato que as tenha provocado.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">
              12. Cessação e Vigência dos Termos de Serviço
            </p>

            <p className="text-400-black-16 mb-2">
              Estes Termos de Serviço têm vigência entre as partes enquanto
              subsistir a relação de prestação de serviços entre o usuário e a
              Tickets App, que se inicia com a efetivação do cadastro no site e
              pode ser encerrada mediante:
            </p>
            <ul>
              <li>
                Cancelamento do cadastro a pedido do usuário e descontinuação da
                utilização dos serviços;
              </li>
              <li>
                Cancelamento do cadastro por violação aos Termos de Serviço ou
                ao Contrato de Serviços;
              </li>
              <li>
                Falecimento do usuário comprovado mediante certidão de óbito.
              </li>
            </ul>

            <p className="text-400-black-16 mb-2">
              A Tickets App ainda se reserva o direito de interromper total ou
              parcialmente a disponibilização dos serviços caso sua exploração
              deixe de ser comercialmente viável.
            </p>
            <p className="text-400-black-16 mb-2">
              O encerramento da prestação dos serviços não implica desistência
              por qualquer das partes dos créditos a que ainda façam jus.
            </p>

            <p className="text-500-black-16 mb-2 mt-4">13. Do foro</p>
            <p className="text-400-black-16 mb-2">
              As partes elegem o foro da Comarca de São Paulo para dirimir
              quaisquer questões decorrentes do presente Contrato, à exclusão de
              qualquer outro, por mais privilegiado que seja.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
