import {
  Button,
  BreadCrumb,
  Input,
  Layout,
  Loading,
} from "../../../Components";
import { Page } from "../../../Components/Breadcrumb";

import AxiosClient from "../../../Services/AxiosClient";
import ServiceResult from "../../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  EstadosBrasileiros,
  SomenteNumeros,
  ValidarDataDeVencimentoCartao,
} from "../../../Utils";
import { useEffect, useState } from "react";
import { useViaCep } from "../../../Hooks/useViaCep";
import { ViaCepModel } from "../../../Interfaces/Models";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/meu-perfil",
    name: "Meu perfil",
  },
  {
    link: "/meu-perfil/cadastrar-cartao",
    name: "Cadastrar cartão de crédito",
  },
];

export default function AdicionarCartao() {
  const [estado, setEstado] = useState("SP");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cartao: "",
      validade: "",
      cvv: "",
      nomeTitular: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
    },
  });

  //#region CEP

  const { FetchCep, fetchingCep } = useViaCep();

  const [enderecoViaCepResponse, setEnderecoViaCepResponse] =
    useState<ViaCepModel>({} as ViaCepModel);

  const cep: string = watch("cep");

  const [viaCepErro, setViaCepErro] = useState<boolean>(true);

  const BuscarCep = async (cep: string) => {
    if (cep?.length > 8) {
      const viaCepResponse = await FetchCep(cep);

      setEnderecoViaCepResponse(viaCepResponse);

      if (viaCepResponse.erro) {
        setViaCepErro(true);
      } else {
        setViaCepErro(false);
      }
    }
  };

  // Buscar Cep quando alterar o CEP digitado
  useEffect(() => {
    BuscarCep(cep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep]);

  // Setar props do endereço com resultado da pesquisa do CEP
  useEffect(() => {
    if (enderecoViaCepResponse.erro) {
      toast.error("CEP inválido! Não foi possível obter o endereço.");
      return;
    }

    setValue("cep", enderecoViaCepResponse.cep);
    setValue("logradouro", enderecoViaCepResponse.logradouro);
    setValue("complemento", enderecoViaCepResponse.complemento);
    setValue("bairro", enderecoViaCepResponse.bairro);
    setValue("cidade", enderecoViaCepResponse.localidade);
    setEstado(enderecoViaCepResponse.uf?.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoViaCepResponse]);

  //#endregion

  const CadastrarNovoCartao = async (data: any) => {
    if (viaCepErro) {
      toast.error("Informe um CEP válido para continuar.");
      return;
    }

    setIsLoading(true);

    const body = {
      mesExpiracao: parseInt(data.validade.substring(0, 2)),
      anoExpiracao: parseInt(data.validade.substring(3)),
      numeroCartao: SomenteNumeros(data.cartao),
      nomeTitular: data.nomeTitular,
      cvv: data.cvv,
      endereco: {
        bairro: data.bairro,
        cep: SomenteNumeros(data.cep),
        cidade: data.cidade,
        complemento: data.complemento,
        logradouro: data.logradouro,
        numero: data.numero,
        pais: "Brasil",
        unidadeFederacao: estado,
      },
    };

    AxiosClient.post("conta/cartoes", body)
      .then(() => {
        navigate(-1);
        toast.success("Cartão cadastrado com sucesso!");
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Tente novamente mais tarde."
          );
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Layout>
      <div className="row g-3">
        <form onSubmit={handleSubmit(CadastrarNovoCartao)}>
          <BreadCrumb history={breadCrumbHistory} />

          <div className="col-lg-12">
            <div
              className="bg-white py-3 px-3 px-lg-5 shadow-sm"
              style={{ minHeight: "calc(100vh - 339px)" }}
            >
              <div className="row g-2">
                <p className="title-h1 text-black mb-4">
                  Informações do cartão de crédito
                </p>
              </div>

              {/* Form */}
              <div className="row g-2 mb-4">
                <div className="col-lg-8">
                  <div className="row g-4">
                    {/* Cartão + dados titular */}
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="cartao" className="text-500-dark-18">
                          Número do cartão
                        </label>
                        <Input
                          control={control}
                          errors={errors}
                          validation={{
                            required:
                              "Informe os 16 dígitos do cartão (somente números)",
                          }}
                          name="cartao"
                          placeholder="Número do cartão"
                          type="text"
                          variant="outlined"
                          height="24px"
                        />
                        {errors.cartao && (
                          <p className="text-danger text-break m-0 mt-1">
                            {errors.cartao?.message?.toString()}
                          </p>
                        )}
                      </div>

                      <div className="row g-2 mb-3">
                        <div className="col-lg-6">
                          <label
                            htmlFor="validade"
                            className="text-500-dark-18"
                          >
                            Validade
                          </label>
                          <Input
                            control={control}
                            errors={errors}
                            validation={{
                              required: "Informe a validade",
                              pattern: {
                                value: /\d{2}\/\d{2}/,
                                message: "Informe a validade no formato MM/AA",
                              },
                              validate: {
                                validade: (value) => {
                                  if (!ValidarDataDeVencimentoCartao(value)) {
                                    return "Informe uma data válida";
                                  }

                                  return true;
                                },
                              },
                            }}
                            name="validade"
                            mask="99/99"
                            maskChar="_"
                            placeholder="Validade"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                          {errors.validade && (
                            <p className="text-danger text-break m-0 mt-1">
                              {errors.validade?.message?.toString()}
                            </p>
                          )}
                        </div>

                        <div className="col-lg-6">
                          <label htmlFor="cvv" className="text-500-dark-18">
                            CVV
                          </label>
                          <Input
                            control={control}
                            errors={errors}
                            validation={{
                              required: "Informe o CVV",
                              pattern: {
                                value: /\d{3,4}/,
                                message: "Informe o CVV com 3 ou 4 dígitos",
                              },
                            }}
                            name="cvv"
                            mask="9999"
                            placeholder="CVV"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                          {errors.cvv && (
                            <p className="text-danger text-break m-0 mt-1">
                              {errors.cvv?.message?.toString()}
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="nomeTitular"
                          className="text-500-dark-18"
                        >
                          Nome do titular
                        </label>
                        <Input
                          control={control}
                          errors={errors}
                          validation={{
                            required: "Informe o nome do titular",
                            pattern: {
                              value: /^[a-zA-ZÀ-ÿ\s]*$/,
                              message: "Informe um nome válido",
                            },
                          }}
                          name="nomeTitular"
                          placeholder="Nome do titular"
                          type="text"
                          variant="outlined"
                          height="24px"
                        />
                        {errors.nomeTitular && (
                          <p className="text-danger text-break m-0 mt-1">
                            {errors.nomeTitular?.message?.toString()}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Endereço */}
                    <div className="col-lg-6">
                      {fetchingCep && (
                        <>
                          <div className="mb-3">
                            <label htmlFor="cep" className="text-500-dark-18">
                              CEP
                            </label>
                            <Input
                              control={control}
                              errors={errors}
                              validation={{
                                required: "Informe o CEP",
                                pattern: {
                                  value: /^\d{5}-\d{3}$/,
                                  message: "Informe um CEP válido",
                                },
                              }}
                              name="cep"
                              mask="99999-999"
                              placeholder="CEP"
                              type="text"
                              variant="outlined"
                              height="24px"
                            />
                            {errors.cep && (
                              <p className="text-danger text-break m-0 mt-1">
                                {errors.cep?.message?.toString()}
                              </p>
                            )}
                          </div>

                          <Loading container="30vh" />
                        </>
                      )}

                      {!fetchingCep && viaCepErro && (
                        <div className="mb-3">
                          <label htmlFor="cep" className="text-500-dark-18">
                            CEP
                          </label>
                          <Input
                            control={control}
                            errors={errors}
                            validation={{
                              required: "Informe o CEP",
                              pattern: {
                                value: /^\d{5}-\d{3}$/,
                                message: "Informe um CEP válido",
                              },
                            }}
                            name="cep"
                            mask="99999-999"
                            placeholder="CEP"
                            type="text"
                            variant="outlined"
                            height="24px"
                          />
                          {errors.cep && (
                            <p className="text-danger text-break m-0 mt-1">
                              {errors.cep?.message?.toString()}
                            </p>
                          )}
                        </div>
                      )}

                      {!fetchingCep && !viaCepErro && (
                        <>
                          {/* CEP + bairro */}
                          <div className="row g-2 mb-3">
                            <div className="col-lg-6">
                              <label htmlFor="cep" className="text-500-dark-18">
                                CEP
                              </label>
                              <Input
                                control={control}
                                errors={errors}
                                validation={{
                                  required: "Informe o CEP",
                                  pattern: {
                                    value: /^\d{5}-\d{3}$/,
                                    message: "Informe um CEP válido",
                                  },
                                }}
                                name="cep"
                                mask="99999-999"
                                placeholder="CEP"
                                type="text"
                                variant="outlined"
                                height="24px"
                              />
                              {errors.cep && (
                                <p className="text-danger text-break m-0 mt-1">
                                  {errors.cep?.message?.toString()}
                                </p>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="bairro"
                                className="text-500-dark-18"
                              >
                                Bairro
                              </label>
                              <Input
                                control={control}
                                errors={errors}
                                validation={{
                                  required: "Informe o bairro",
                                }}
                                name="bairro"
                                placeholder="Bairro"
                                type="text"
                                variant="outlined"
                                height="24px"
                              />
                              {errors.bairro && (
                                <p className="text-danger text-break m-0 mt-1">
                                  {errors.bairro?.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Rua + número */}
                          <div className="row g-2 mb-3">
                            <div className="col-lg-8">
                              <label htmlFor="rua" className="text-500-dark-18">
                                Rua
                              </label>
                              <Input
                                control={control}
                                errors={errors}
                                validation={{
                                  required: "Informe o endereço",
                                }}
                                name="logradouro"
                                placeholder="Rua (Logradouro)"
                                type="text"
                                variant="outlined"
                                height="24px"
                              />
                              {errors.logradouro && (
                                <p className="text-danger text-break m-0 mt-1">
                                  {errors.logradouro?.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="col-lg-4">
                              <label
                                htmlFor="numero"
                                className="text-500-dark-18"
                              >
                                Número
                              </label>
                              <Input
                                control={control}
                                errors={errors}
                                validation={{}}
                                name="numero"
                                placeholder="S/N"
                                type="text"
                                variant="outlined"
                                height="24px"
                              />
                            </div>
                          </div>

                          {/* Complemento + cidade */}
                          <div className="row g-2 mb-3">
                            <div className="col-lg-6">
                              <label
                                htmlFor="complemento"
                                className="text-500-dark-18"
                              >
                                Complemento
                              </label>
                              <Input
                                control={control}
                                errors={errors}
                                validation={{}}
                                name="complemento"
                                placeholder="Apto, bloco, etc."
                                type="text"
                                variant="outlined"
                                height="24px"
                              />
                            </div>

                            <div className="col-lg-6">
                              <label
                                htmlFor="cidade"
                                className="text-500-dark-18"
                              >
                                Cidade
                              </label>
                              <Input
                                control={control}
                                errors={errors}
                                validation={{
                                  required: "Informe a cidade",
                                }}
                                name="cidade"
                                placeholder="Cidade"
                                type="text"
                                variant="outlined"
                                height="24px"
                              />
                              {errors.cidade && (
                                <p className="text-danger text-break m-0 mt-1">
                                  {errors.cidade?.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Estado */}
                          <div className="mb-3">
                            <div className="col-lg-12">
                              <label
                                htmlFor="estado"
                                className="text-500-dark-18"
                              >
                                Estado
                              </label>
                              <select
                                className="form-select bc-input input-outlined p-2"
                                id="estado"
                                name="estado"
                                value={estado}
                                onChange={({ target: { value } }) =>
                                  setEstado(value)
                                }
                              >
                                <option disabled value="">
                                  Selecione um estado
                                </option>
                                {EstadosBrasileiros.map((estado) => (
                                  <option
                                    key={estado.sigla}
                                    value={estado.sigla}
                                  >
                                    {estado.nome}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 col-lg-12 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-end">
            <div className="col-12 col-lg-6 p-0 d-flex flex-wrap justify-content-center justify-content-lg-end">
              <Button
                className="bc-btn bc-btn-light mb-3 mb-sm-0 me-0 me-sm-3"
                text="Cancelar"
                type="button"
                click={() => navigate(-1)}
                width="216px"
                height="40px"
              />

              <Button
                className="bc-btn bc-btn-primary mb-3 mb-sm-0"
                text={isLoading ? "Carregando..." : "Cadastrar"}
                type="submit"
                width="216px"
                disabled={isLoading}
                height="40px"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}
