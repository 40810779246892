import {
  CalendarIcon,
  LocalIcon,
  UserIcon,
} from "../../../Components/Icons"

interface InfoCheckoutEventoUsuarioProps {
  tituloEvento: string;
  dataEvento: string;
  classificacaoEtariaEvento: number;
  localEvento: string;
  nomeUser: string;
  cpfUser: string;
  nascimentoUser: string;
  telefoneUser: string;
}

export default function InfoCheckoutEventoUsuario({
  tituloEvento,
  dataEvento,
  classificacaoEtariaEvento,
  localEvento,
  nomeUser,
  cpfUser,
  nascimentoUser,
  telefoneUser,
}: InfoCheckoutEventoUsuarioProps) {
  return (
    <>
      <div className="col-lg-12">
        <div className="bg-white py-3 px-3 px-lg-5 shadow-sm">
          <div className="row mt-2 mb-3">
            {/* Nome evento */}
            <div className="col-lg-12">
              <p className="title-h1 text-black">{tituloEvento}</p>
            </div>
            <div className="col-lg-8">
              <div className="row g-2">
                {/* Data evento */}
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="me-2">
                    <CalendarIcon dark />
                  </div>
                  <p className="text-500-black-16 m-0">{dataEvento}</p>
                </div>
                {/* Classificação etária */}
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="me-2">
                    <UserIcon />
                  </div>
                  <p className="text-500-black-16 m-0">
                    Classificação:{" "}
                    {classificacaoEtariaEvento < 1
                      ? "Livre"
                      : `+${classificacaoEtariaEvento}`}
                  </p>
                </div>
                {/* Localização */}
                <div className="col-lg-12 d-flex align-items-center mb-2">
                  <div className="me-2">
                    <LocalIcon dark />
                  </div>
                  <p className="text-500-black-16 m-0">{localEvento}</p>
                </div>

                {/* Dados pessoais */}
                <div className="col-lg-6">
                  <p className="text-500-black-16 m-0">Nome</p>
                  <p className="text-400-dark-16 m-0">{nomeUser}</p>
                </div>
                <div className="col-lg-6">
                  <p className="text-500-black-16 m-0">CPF</p>
                  <p className="text-400-dark-16 m-0">{cpfUser}</p>
                </div>
                <div className="col-lg-6">
                  <p className="text-500-black-16 m-0">Data de nascimento</p>
                  <p className="text-400-dark-16 m-0">{nascimentoUser}</p>
                </div>
                <div className="col-lg-6">
                  <p className="text-500-black-16 m-0">Telefone</p>
                  <p className="text-400-dark-16 m-0">{telefoneUser}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
