import { Button, Loading, Modal } from "../../../Components";

import AxiosClient from "../../../Services/AxiosClient";
import ReactInputMask from "react-input-mask";
import ServiceResult from "../../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { ProfileContext } from "../../../Contexts/ProfileContext";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckSuccessModalIcon } from "../../../Components/Icons";
import SexoPerfil from "../../../Enums/SexoPerfil";

export default function DadosPessoais() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [codigo, setCodigo] = useState<string>("");

  const [solicitacaoLoading, setSolicitacaoLoading] = useState<boolean>(false);
  const [confirmacaoLoading, setConfirmacaoLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { setPerfil, ...dadosPessoais } = useContext(ProfileContext);

  const solicitarNovoCodigo = async (): Promise<void> => {
    setSolicitacaoLoading(true);

    AxiosClient.post("conta/reenviar-email-confirmacao", {
      email: dadosPessoais.email,
    })
      .then((response) => {
        toast.success(`Código enviado para ${dadosPessoais.email}`);
        setIsModalOpen(true);
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Tente novamente mais tarde."
          );
        }
      })
      .finally(() => setSolicitacaoLoading(false));
  };

  const confirmarConta = async (payload: string) => {
    setConfirmacaoLoading(true);

    try {
      await AxiosClient.post("/cadastros/confirmar", {
        codigo: payload,
        email: dadosPessoais.email,
      });

      toast.success("Conta confirmada com sucesso!");
      setIsModalOpen(false);
    } catch (error: any) {
      toast.error("Erro ao confirmar conta.");
    } finally {
      setConfirmacaoLoading(false);
    }
  };

  const handleUploadClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const AlterarImagemPerfil = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("files", file, file.name);

      AxiosClient.putForm("/conta/dados-pessoais/imagem-perfil", formData)
        .then(({ data: { data } }) => {
          // const novoObj = { ...perfil };
          // novoObj.imagemPerfil = data;

          // setPerfil(novoObj as PerfilUsuario);
          // toast.success("Imagem de perfil atualizada com sucesso!");

          localStorage.setItem("userImg", data);
          navigate(0); // temp
        })
        .catch((error: any) => {
          if (error.response.status >= 400 && error.response.status < 500) {
            const result: ServiceResult = error.response.data;
            toast.error(
              result.messages.map((m) => "➡️ " + m.message).join("\n\n")
            );
          } else {
            toast.error(
              "Ocorreu um erro inesperado. Tente novamente mais tarde."
            );
          }
        });
    }
  };

  return (
    <div className="col-lg-12">
      <div className="bg-white py-3 px-3 px-lg-5 shadow-sm">
        <p className="title-h1 text-black mb-3 mb-lg-4">Dados pessoais</p>

        <div className="row g-5">
          <div className="col-xl-4">
            <p className="text-500-dark-18">Dados pessoais</p>
            <p className="text-400-black-16">
              Aqui você pode alterar alguns de seus dados cadastrais, os que
              estiverem bloqueados você deve entrar em contato com nossa equipe
              de suporte
            </p>

            {solicitacaoLoading && <Loading container="0" />}

            {!solicitacaoLoading &&
              (!dadosPessoais?.emailConfirmado ||
                dadosPessoais?.emailNovoAguardandoCodigo) && (
                <p className="text-400-black-16 text-danger">
                  Vimos que ainda não confirmou sua conta,
                  <span
                    className="fw-bold text-decoration-underline cursor-pointer m-1"
                    onClick={solicitarNovoCodigo}
                  >
                    clique aqui
                  </span>
                  para reenviar o código de confirmação em seu e-mail. Ou se já
                  possui um código válido (e não expirado),
                  <span
                    className="fw-bold text-decoration-underline cursor-pointer m-1"
                    onClick={() => setIsModalOpen(true)}
                  >
                    clique aqui
                  </span>
                  para confirmar sua conta.
                </p>
              )}
            {!solicitacaoLoading &&
              (!dadosPessoais?.telefoneConfirmado ||
                dadosPessoais?.celularNovoAguardandoCodigo) && (
                <>
                  <p className="text-400-black-16 text-danger">
                    Vimos que ainda não confirmou seu celular,se já possui um
                    código válido (e não expirado),
                    <span
                      className="fw-bold text-decoration-underline cursor-pointer m-1"
                      onClick={() => setIsModalOpen(true)}
                    >
                      clique aqui
                    </span>
                    para confirmar seu celular.
                  </p>
                </>
              )}
          </div>

          <div className="col-xl-8">
            <div className="row g-3">
              <div className="col-xl-2 d-flex justify-content-center justify-content-xl-end align-items-center">
                <div className="d-flex justify-content-end align-items-end">
                  {/* Input file hidden */}
                  <input
                    ref={inputFileRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={AlterarImagemPerfil}
                  />

                  {/* Btn para ativar input hidden */}
                  <button
                    type="button"
                    onClick={handleUploadClick}
                    className="bc-btn position-absolute rounded-circle d-flex justify-content-center align-items-center btn-upload-img"
                  >
                    <span className="material-symbols-rounded">upload</span>
                  </button>

                  <img
                    src={
                      dadosPessoais?.imagemPerfil
                        ? `${dadosPessoais?.imagemPerfil}`
                        : "/assets/images/anon_image.png"
                    }
                    className="rounded-circle"
                    style={{
                      width: "126px",
                      height: "126px",
                      objectFit: "cover",
                    }}
                    alt="Avatar"
                  />
                </div>
              </div>

              <div className="col-xl-5">
                <ReactInputMask
                  className={`form-control bc-input input-outlined p-2 mb-3 ${
                    dadosPessoais?.nomeCompleto ? "" : "is-invalid"
                  }`}
                  mask=""
                  value={dadosPessoais?.nomeCompleto}
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  height="24px"
                  onChange={({ target: { value } }) =>
                    setPerfil((prev) => ({ ...prev, nomeCompleto: value }))
                  }
                />

                <ReactInputMask
                  className={`form-control bc-input input-outlined p-2 mb-3 ${
                    dadosPessoais?.telefone ? "" : "is-invalid"
                  }`}
                  // value={dadosPessoais?.celularNovoAguardandoCodigo ?? dadosPessoais?.telefone}
                  value={dadosPessoais?.telefone}
                  mask="+55 (99) 99999-9999"
                  maskChar="*"
                  type="text"
                  name="celular"
                  placeholder="Celular"
                  height="24px"
                  onChange={({ target: { value } }) =>
                    setPerfil((prev) => ({ ...prev, telefone: value }))
                  }
                />

                <ReactInputMask
                  type="text"
                  value={dadosPessoais?.dataNascimento}
                  mask="99/99/9999"
                  maskChar="*"
                  name="nascimento"
                  className={`form-control bc-input input-outlined p-2 ${
                    !dadosPessoais?.dataNascimento ? "is-invalid" : ""
                  }`}
                  placeholder="Nascimento"
                  height="24px"
                  onChange={({ target: { value } }) =>
                    setPerfil((prev) => ({ ...prev, dataNascimento: value }))
                  }
                />
              </div>

              <div className="col-xl-5">
                <ReactInputMask
                  mask=""
                  // value={dadosPessoais?.emailNovoAguardandoCodigo ?? dadosPessoais?.email}
                  value={
                    dadosPessoais?.emailNovoAguardandoCodigo ??
                    dadosPessoais?.email
                  }
                  name="email"
                  className={`form-control bc-input input-outlined p-2 mb-3 ${
                    !dadosPessoais?.emailNovoAguardandoCodigo &&
                    !dadosPessoais?.email
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Email"
                  height="24px"
                  onChange={({ target: { value } }) =>
                    setPerfil((prev) => ({ ...prev, email: value }))
                  }
                />

                <ReactInputMask
                  value={dadosPessoais?.cpf}
                  mask="999.999.999-99"
                  maskChar="*"
                  type="text"
                  name="cpf"
                  className="form-control bc-input input-outlined p-2 mb-3"
                  placeholder="CPF"
                  disabled
                  height="24px"
                  onChange={({ target: { value } }) =>
                    setPerfil((prev) => ({ ...prev, cpf: value }))
                  }
                />

                <select
                  defaultValue={SexoPerfil.NaoInformado}
                  className="form-select bc-input input-outlined p-2"
                  onChange={({ target: { value } }) =>
                    setPerfil((prev) => ({
                      ...prev,
                      sexo: value as SexoPerfil,
                    }))
                  }
                  value={dadosPessoais.sexo as SexoPerfil}
                >
                  {Object.values(SexoPerfil).map((sexo) => (
                    <option key={sexo} value={sexo}>
                      {sexo}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row g-0 mt-4">
              <div className="col-xl-6 d-flex justify-content-center justify-content-xl-end mb-4 mb-xl-0">
                <a href="/meu-perfil/redefinir-senha" className="me-0 me-xl-4">
                  Redefinir minha senha
                </a>
              </div>

              <div className="col-xl-6 d-flex justify-content-center justify-content-xl-end">
                <div className="form-group">
                  <p className="text-400-black-14 m-0">
                    Permitir envio de notificações via:
                  </p>

                  <div className="d-flex align-items-center">
                    <div className="form-check form-switch d-flex align-items-center me-3">
                      <input
                        className="form-check-input mb-1"
                        style={{ width: "3.3em", height: "1.7em" }}
                        type="checkbox"
                        role="switch"
                        id="notificarWhatsApp"
                        checked={dadosPessoais.permiteNotificacaoWhatsApp}
                        onChange={() =>
                          setPerfil((prev) => ({
                            ...prev,
                            permiteNotificacaoWhatsApp:
                              !prev.permiteNotificacaoWhatsApp,
                          }))
                        }
                      />
                      <label
                        className="form-check-label ps-2"
                        htmlFor="notificarWhatsApp"
                      >
                        WhatsApp
                      </label>
                    </div>

                    <div className="form-check form-switch d-flex align-items-center">
                      <input
                        className="form-check-input mb-1"
                        style={{ width: "3.3em", height: "1.7em" }}
                        type="checkbox"
                        role="switch"
                        id="notificarSMS"
                        checked={dadosPessoais.permiteNotificacaoSMS}
                        onChange={() =>
                          setPerfil((prev) => ({
                            ...prev,
                            permiteNotificacaoSMS: !prev.permiteNotificacaoSMS,
                          }))
                        }
                      />
                      <label
                        className="form-check-label ps-2"
                        htmlFor="notificarSMS"
                      >
                        SMS
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        closeNonFocus={false}
        close={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      >
        <div className="row d-flex justify-content-center align-itens-center text-center">
          <div className="mb-5">
            <CheckSuccessModalIcon />
          </div>

          <p className="title-h2 m-0">
            Enviamos um código de confirmação para seu e-mail ou SMS
          </p>
          <p className="text-400-black-14 mb-4">
            Insira o código abaixo para confirmar seu cadastro
          </p>

          <form>
            <div className="form-group mb-5">
              <ReactInputMask
                className="form-control bc-input shadow-sm input-outlined text-center p-2 fs-4"
                style={{ letterSpacing: "0.5rem" }}
                mask="999999"
                maskChar="_"
                name="codigoConfirmacao"
                value={codigo}
                onChange={({ target: { value } }) => setCodigo(value)}
                placeholder="______"
                type="text"
              />
            </div>

            {(confirmacaoLoading || solicitacaoLoading) && <Loading />}

            {!confirmacaoLoading && !solicitacaoLoading && (
              <>
                <Button
                  type="button"
                  click={() => confirmarConta(codigo)}
                  text="Confirmar"
                  width="90%"
                  className="bc-btn bc-btn-primary mb-4"
                />
                <Button
                  type="button"
                  click={solicitarNovoCodigo}
                  text="Reenviar código"
                  width="90%"
                  className="bc-btn bc-btn-light mb-2"
                />
              </>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
}
