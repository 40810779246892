import { Layout } from "../../Components";

import { WhiteLabel } from "../../Utils";

export default function Ajuda() {
  return (
    <Layout>
      <div className="row g-3">
        <div className="col-lg-12">
          <div className="bg-white py-4 px-3 px-lg-5 shadow-sm">
            <p className="title-h1 text-black m-0">Precisa de ajuda?</p>
          </div>
        </div>

        <div className="col-lg-12">
          <div
            className="bg-white py-4 px-3 px-lg-5 shadow-sm"
            style={{ minHeight: "calc(100vh - 339px)" }}
          >
            <p className="text-400-black-16 mb-2">
              Se você está enfrentando um problema ou tem alguma dúvida, estamos
              aqui para ajudar! A maneira mais eficiente de obter suporte é
              entrando em contato conosco por e-mail.
            </p>

            <p className="text-400-black-16 mb-2">
              Envie um e-mail para{" "}
              <a className="text-break" href={`mailto:${WhiteLabel().email}`}>
                {WhiteLabel().email}
              </a>{" "}
              e inclua as seguintes informações para que possamos ajudar a
              resolver seu problema o mais rápido possível:
            </p>

            <ul>
              <li>Descrição detalhada do problema ou pergunta.</li>
              <li>Capturas de tela, se aplicável, para melhor compreensão.</li>
            </ul>

            <p className="text-400-black-16 mb-2">
              Nossa equipe de suporte estará pronta para responder ao seu e-mail
              e oferecer assistência personalizada. Nosso objetivo é garantir
              que você tenha a melhor experiência possível!
            </p>

            <p className="text-400-black-16 mb-4">
              Agradecemos por escolher TicketsApp! Estamos ansiosos para
              resolver seu problema e deixar você satisfeito.
            </p>

            <p className="text-400-black-16 m-0">Atenciosamente,</p>
            <p className="text-400-black-16 mb-2">A Equipe TicketsApp</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
