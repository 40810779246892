import { MinusIcon, PlusIcon } from "./Icons";
import IngressoSelecionado from "../Interfaces/IngressoSelecionado";
import { RemoverCaractereEspacosAcentos } from "../Utils";
import { FormatarCentavosParaReais } from "../Utils";
import { IngressoEventoModel } from "../Interfaces/Models";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ptBR } from "date-fns/locale";
import { isSameDay } from "date-fns";
import Loading from "./Loading";
import Button from "./Button";
import Modal from "./Modal";
registerLocale("pt-BR", ptBR);

type SelecaoIngressoProps = {
  nomeEvento: string;
  ingressos: IngressoEventoModel[];
  isLoading: boolean;
  comprar: (ingressosSelecionados: IngressoSelecionado[]) => void;
};

export default function SelecaoIngresso({
  nomeEvento,
  ingressos,
  isLoading,
  comprar,
}: SelecaoIngressoProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const session = searchParams.get("session");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inputCodigoAcesso, setInputCodigoAcesso] = useState("");
  const [ingressosSelecionados, setIngressosSelecionados] = useState<
    IngressoSelecionado[]
  >([] as IngressoSelecionado[]);

  const sessions = ingressos.map((ingresso) => ingresso.sessoes).flat();

  const sessionDates: Date[] = sessions
    .map((session) => new Date(session))
    .sort((a, b) => a.getTime() - b.getTime());

  const isHighlighted = (date: any): boolean => {
    return sessionDates.some((sessionDate) => isSameDay(date, sessionDate));
  };

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom": sessionDates,
    },
  ];

  const ingressosComCodigo: Record<string, IngressoEventoModel[]> = ingressos
    .filter((i) => i.codigoUtilizado)
    .reduce((acc: Record<string, IngressoEventoModel[]>, ingresso) => {
      const codigoUtilizado = ingresso.codigoUtilizado!;

      if (!acc[codigoUtilizado]) {
        acc[codigoUtilizado] = [];
      }

      acc[codigoUtilizado].push(ingresso);

      return acc;
    }, {});

  const validSessionDateFromQuery = (session: string | null) => {
    if (!session) {
      return false;
    }

    const sessionDate = moment(session, "YYYY-MM-DD", true);

    if (!sessionDate.isValid()) {
      return false;
    }

    if (!sessionDates.some((s) => moment(s).isSame(sessionDate, "day"))) {
      return false;
    }

    return true;
  };

  const ingressosSemCodigo: Record<string, IngressoEventoModel[]> = ingressos
    .filter((i) => !i.codigoUtilizado)
    .filter((i) =>
      session ? i.sessoes.some((s) => moment(s).isSame(session, "day")) : true,
    )
    .reduce((acc: Record<string, IngressoEventoModel[]>, ingresso) => {
      const grupoNome = ingresso.grupoNome!;
      if (!acc[grupoNome]) {
        acc[grupoNome] = [];
      }

      acc[grupoNome].push(ingresso);

      return acc;
    }, {});

  const handleDateChange = (date: Date | null) => {
    setSearchParams((state) => {
      if (date) {
        const data = moment(date);
        data.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        state.set("session", data.format("YYYY-MM-DD"));
      } else {
        state.delete("session");
      }

      return state;
    });
  };

  const addIngresso = (ingresso: IngressoEventoModel) => {
    const ingressoSelecionado = ingressosSelecionados.find(
      (i) => i.ingressoId === ingresso.id,
    );

    if (ingressoSelecionado) {
      ingressoSelecionado.quantidade++;
    } else {
      ingressosSelecionados.push({
        ingressoId: ingresso.id,
        nome: ingresso.nome,
        quantidade: 1,
        valorCentavos: ingresso.valorCentavos,
        valorCentavosSemTaxa: ingresso.valorCentavosSemTaxa,
        codigoAcessoUtilizado: ingresso.codigoUtilizado,
        valorTaxaProcessamento: ingresso.valorTaxaProcessamento,
        valorTaxaServico: ingresso.valorTaxaServico,
        valorTaxa: ingresso.valorTaxaProcessamento + ingresso.valorTaxaServico,
        valorCentavosComDesconto: null,
      });
    }

    setIngressosSelecionados([...ingressosSelecionados]);
  };

  const removeIngresso = (ingressoId: string) => {
    const ingressoSelecionado = ingressosSelecionados.find(
      (i) => i.ingressoId === ingressoId,
    );

    if (ingressoSelecionado) {
      ingressoSelecionado.quantidade--;

      if (ingressoSelecionado.quantidade === 0) {
        const index = ingressosSelecionados.findIndex(
          (i) => i.ingressoId === ingressoId,
        );

        ingressosSelecionados.splice(index, 1);
      }
    }

    setIngressosSelecionados([...ingressosSelecionados]);
  };

  const aplicarCodigo = () => {
    setSearchParams((state) => {
      state.set("codigo", inputCodigoAcesso);
      return state;
    });
  };

  useEffect(() => {
    if (session && !validSessionDateFromQuery(session)) {
      setSearchParams((state) => {
        state.delete("session");
        return state;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loading container="100vh" />
  ) : (
    <>
      <div className="row align-items-center mb-4">
        <div className="col-md-8">
          <p className="title-h1 m-0">Escolha seu ingresso</p>
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center align-items-md-end">
          <div className="form-group m-0 mt-3 mt-md-0">
            <p className="text-500-black-16 mb-1 ms-2">Filtrar por sessão:</p>
            <DatePicker
              locale="pt-BR"
              dateFormat="dd/MM/yyyy"
              showIcon
              isClearable
              withPortal
              // shouldCloseOnSelect={false}
              includeDates={sessionDates}
              highlightDates={highlightWithRanges}
              dayClassName={(date: Date) =>
                isHighlighted(date) ? "highlighted-date" : ""
              }
              selected={session ? moment(session).toDate() : null}
              onChange={handleDateChange}
              className="form-control cursor-pointer"
            />
          </div>
        </div>
      </div>

      {session && (
        <div className="row mb-5">
          <div className="col-12">
            <p className="text-400-black-16 text-center m-0">
              Exibindo ingressos válidos para as sessões do dia{" "}
              <strong>{moment(session).format("DD/MM/YYYY")}</strong>
            </p>
          </div>
        </div>
      )}

      {ingressos.length === 0 && (
        <p className="text-400-black-16 text-break text-center m-0">
          Não há ingressos disponíveis para este evento.
        </p>
      )}

      {ingressos.length > 0 && (
        <>
          {/* Accordion: Ingressos agrupados por codigo utilizado */}
          {Object.keys(ingressosComCodigo).map((codigo) => (
            <div key={codigo} className="row mb-5 justify-content-center">
              {/* Grupo */}
              <div className="col-lg-9">
                <div className="accordion" id="ticketsAccordion">
                  <div
                    className="accordion-item"
                    style={{
                      borderRadius: "7px",
                      border: "none",
                      boxShadow: "none",
                      background: "#f3f3f3",
                    }}
                  >
                    <h2 className="accordion-header grupo-ingressos bg-transparent">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={
                          "#collapse" + RemoverCaractereEspacosAcentos(codigo)
                        }
                        aria-expanded="true"
                        aria-controls={
                          "collapse" + RemoverCaractereEspacosAcentos(codigo)
                        }
                      >
                        <div className="d-flex flex-column">
                          <p className="text-500-black-16 mb-3">
                            Ingressos do código
                          </p>
                          <p className="bg-success rounded p-2 fs-5 text-white text-center text-break fw-semibold m-0">
                            {/* Exibir o codigo utilizado */}
                            {codigo}
                          </p>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={"collapse" + RemoverCaractereEspacosAcentos(codigo)}
                      className="row justify-content-center accordion-collapse collapse"
                      // data-bs-parent="#ticketsAccordion" -> Omit the data-bs-parent attribute on each .accordion-collapse to make accordion items stay open when another item is opened.
                    >
                      {/* Ingressos */}
                      {ingressosComCodigo[codigo].map(
                        (ingresso: IngressoEventoModel) => (
                          <div
                            key={ingresso.id}
                            className="col-11 col-lg-10 my-3"
                          >
                            <div className="card-ingresso d-flex flex-column flex-sm-row">
                              {/* Data */}
                              <div className="data-card-ingresso col col-sm-2 pt-3 p-sm-4">
                                <p className="m-0 text-dia-ingresso">
                                  {moment(ingresso.sessoes[0]).format("DD")}
                                </p>
                                <p className="m-0 text-mes-ingresso">
                                  {moment(ingresso.sessoes[0]).format("MMM")}
                                </p>
                              </div>
                              {/* Nome ingresso + Nome evento */}
                              <div className="col d-flex flex-column flex-sm-row justify-content-between align-items-center p-3 p-sm-4">
                                <div className="d-flex flex-column justify-content-center mb-4 mb-sm-0">
                                  <p className="text-500-black-16 text-break m-0">
                                    {ingresso.nome}
                                  </p>
                                  <p className="text-500-black-16 text-break m-0">
                                    {nomeEvento}
                                  </p>
                                </div>
                                <div className="d-flex flex-column flex-sm-row align-items-center">
                                  {/* Valor (dinâmico) + Qtde */}
                                  <p className="text-400-darkest-16 m-0 mb-3 mb-sm-0 mx-sm-3">
                                    {FormatarCentavosParaReais(
                                      ingresso.valorCentavosSemTaxa,
                                    )}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    {(ingressosSelecionados.find(
                                      (i) => i.ingressoId === ingresso.id,
                                    )?.quantidade ?? 0) < 1 ? (
                                      <div className="cursor-pointer">
                                        <MinusIcon disabled />
                                      </div>
                                    ) : (
                                      <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                          removeIngresso(ingresso.id)
                                        }
                                      >
                                        <MinusIcon />
                                      </div>
                                    )}
                                    <p
                                      className="text-500-black-16 m-0 text-center"
                                      style={{
                                        width: "50px",
                                        height: "25px",
                                      }}
                                    >
                                      {ingressosSelecionados.find(
                                        (i) => i.ingressoId === ingresso.id,
                                      )?.quantidade || 0}
                                    </p>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => addIngresso(ingresso)}
                                    >
                                      <PlusIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Accordion: Ingressos sem codigo */}
          {Object.keys(ingressosSemCodigo).map((grupo) => (
            <div key={grupo} className="row mb-5 justify-content-center">
              {/* Grupo */}
              <div className="col-lg-9">
                <div className="accordion" id="ticketsAccordion">
                  <div
                    className="accordion-item"
                    style={{
                      borderRadius: "7px",
                      border: "none",
                      boxShadow: "none",
                      background: "transparent",
                    }}
                  >
                    <h2 className="accordion-header grupo-ingressos">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={
                          "#collapse" + RemoverCaractereEspacosAcentos(grupo)
                        }
                        aria-expanded="true"
                        aria-controls={
                          "collapse" + RemoverCaractereEspacosAcentos(grupo)
                        }
                      >
                        <div className="d-flex flex-column">
                          <p className="text-500-black-16 mb-3">{grupo}</p>
                          <div
                            className="text-500-darkest-14 text-break d-flex flex-wrap m-0"
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            {/*
                            {session ? (
                              <span className="grupo-ingressos-sessao">
                                {moment(session).format("DD MMM - HH[h]mm")}
                              </span>
                            ) : (
                            */}
                            {ingressosSemCodigo[grupo][0].sessoes.map(
                              (sessao, index) => (
                                <span
                                  key={index}
                                  className="grupo-ingressos-sessao"
                                >
                                  {session &&
                                  moment(sessao).isSame(session, "day") ? (
                                    <strong className="text-danger">
                                      {moment(sessao).format(
                                        "DD MMM - HH[h]mm",
                                      )}
                                    </strong>
                                  ) : (
                                    moment(sessao).format("DD MMM - HH[h]mm")
                                  )}
                                </span>
                              ),
                            )}
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={"collapse" + RemoverCaractereEspacosAcentos(grupo)}
                      className="row justify-content-center accordion-collapse collapse"
                      // data-bs-parent="#ticketsAccordion" -> Omit the data-bs-parent attribute on each .accordion-collapse to make accordion items stay open when another item is opened.
                    >
                      {/* Ingressos */}
                      {ingressosSemCodigo[grupo].map(
                        (ingresso: IngressoEventoModel) => (
                          <div
                            key={ingresso.id}
                            className="col-11 col-lg-10 mt-3"
                          >
                            <div className="card-ingresso d-flex flex-column flex-sm-row">
                              {/* Data */}
                              <div className="data-card-ingresso col col-sm-2 pt-3 p-sm-4">
                                <p className="m-0 text-dia-ingresso">
                                  {moment(ingresso.sessoes[0]).format("DD")}
                                </p>
                                <p className="m-0 text-mes-ingresso">
                                  {moment(ingresso.sessoes[0]).format("MMM")}
                                </p>
                              </div>
                              {/* Nome ingresso + Nome evento */}
                              <div className="col d-flex flex-column flex-sm-row justify-content-between align-items-center p-3 p-sm-4">
                                <div className="d-flex flex-column justify-content-center mb-4 mb-sm-0">
                                  <p className="text-500-black-16 text-break m-0">
                                    {ingresso.nome}
                                  </p>
                                  <p className="text-500-black-16 text-break m-0">
                                    {nomeEvento}
                                  </p>
                                </div>
                                <div className="d-flex flex-column flex-sm-row align-items-center">
                                  {/* Valor (dinâmico) + Qtde */}
                                  <p className="text-400-darkest-16 m-0 mb-3 mb-sm-0 mx-sm-3">
                                    {FormatarCentavosParaReais(
                                      ingresso.valorCentavosSemTaxa,
                                    )}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    {(ingressosSelecionados.find(
                                      (i) => i.ingressoId === ingresso.id,
                                    )?.quantidade ?? 0) < 1 ? (
                                      <div className="cursor-pointer">
                                        <MinusIcon disabled />
                                      </div>
                                    ) : (
                                      <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                          removeIngresso(ingresso.id)
                                        }
                                      >
                                        <MinusIcon />
                                      </div>
                                    )}
                                    <p
                                      className="text-500-black-16 m-0 text-center"
                                      style={{
                                        width: "50px",
                                        height: "25px",
                                      }}
                                    >
                                      {ingressosSelecionados.find(
                                        (i) => i.ingressoId === ingresso.id,
                                      )?.quantidade || 0}
                                    </p>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => addIngresso(ingresso)}
                                    >
                                      <PlusIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Botões comprar / código */}
          <div className="row text-center">
            <div className="col-lg-12 mb-3">
              <Button
                click={() => comprar(ingressosSelecionados)}
                className="bc-btn bc-btn-primary"
                text="Continuar para pagamento"
                width="384px"
                disabled={ingressosSelecionados.length === 0}
              />
            </div>
            <div className="col-lg-12">
              <button
                className="bc-link-primary"
                onClick={() => setModalIsOpen(true)}
              >
                Possui algum código?
              </button>

              <Modal
                isOpen={modalIsOpen}
                close={() => setModalIsOpen(false)}
                title="Informe o código de acesso"
                width="448px"
              >
                <div className="row mb-3">
                  <div className="col-lg-12">
                    <p className="modal-text m-0">
                      Ao inserir o código de acesso, será adicionado os
                      ingressos promocionais na exibição
                    </p>
                  </div>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row mb-3">
                    <div className="col-lg-12">
                      <input
                        autoComplete="off"
                        autoFocus
                        type="text"
                        name="codigo"
                        id="codigo"
                        placeholder="Código"
                        className="mb-2 form-control"
                        value={inputCodigoAcesso}
                        onChange={({ target: { value } }) =>
                          setInputCodigoAcesso(value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row text-end">
                    <div className="col-lg-12">
                      <Button
                        type="button"
                        click={() => setModalIsOpen(false)}
                        text="Cancelar"
                        className="bc-btn bc-btn-gray me-3"
                        width="98px"
                        height="40px"
                      />
                      <Button
                        type="submit"
                        click={aplicarCodigo}
                        text="Validar código"
                        className="bc-btn bc-btn-primary"
                        width="136px"
                        height="40px"
                        disabled={!inputCodigoAcesso}
                      />
                    </div>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
        </>
      )}
    </>
  );
}
