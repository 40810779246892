import { Button, Input, Loading } from "../../../Components";

import AxiosClient from "../../../Services/AxiosClient";
import Carrinho from "../../../Interfaces/Carrinho";
import FormCadastrarCartaoArgs from "../../../Interfaces/Args/CadastrarCartaoArgs";
import ServiceResult from "../../../Interfaces/ServiceResult";
import TipoPagamento from "../../../Enums/TipoPagamento";
import toast from "react-hot-toast";

import {
  EstadosBrasileiros,
  SomenteNumeros,
  ValidarDataDeVencimentoCartao,
} from "../../../Utils";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useViaCep } from "../../../Hooks/useViaCep";
import { ViaCepModel } from "../../../Interfaces/Models";
import { useEmbeddedStore } from "../../../Stores/embedded.store";

type FormCadastrarCartaoProps = {
  cancelar: () => void;
  carrinho: Carrinho;
  totalParcelas: number[];
  numeroParcelas: number;
  setNumeroParcelas: React.Dispatch<React.SetStateAction<number>>;
};

export default function FormCadastrarCartao({
  cancelar,
  carrinho,
  totalParcelas,
  numeroParcelas,
  setNumeroParcelas,
}: FormCadastrarCartaoProps) {
  const { embedded } = useEmbeddedStore();

  // const [gravarCartao, setGravarCartao] = useState<boolean>(false);
  const [loadingPagamento, setLoadingPagamento] = useState<boolean>(false);
  const [estado, setEstado] = useState("SP");

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormCadastrarCartaoArgs>({
    defaultValues: {
      numeroCartao: "",
      dataValidade: "",
      codigoSeguranca: "",
      titular: "",
      cpf: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
    },
  });

  //#region CEP

  const { FetchCep, fetchingCep } = useViaCep();

  const [enderecoViaCepResponse, setEnderecoViaCepResponse] =
    useState<ViaCepModel>({} as ViaCepModel);

  const [viaCepErro, setViaCepErro] = useState<boolean>(true);

  const cep: string = watch("cep");

  const BuscarCep = async (cep: string) => {
    if (cep?.length > 8) {
      const viaCepResponse = await FetchCep(cep);

      setEnderecoViaCepResponse(viaCepResponse);

      if (viaCepResponse.erro) {
        setViaCepErro(true);
      } else {
        setViaCepErro(false);
      }
    }
  };

  // Buscar Cep quando alterar o CEP digitado
  useEffect(() => {
    BuscarCep(cep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep]);

  // Setar props do endereço com resultado da pesquisa do CEP
  useEffect(() => {
    if (enderecoViaCepResponse.erro) {
      toast.error("CEP inválido! Não foi possível obter o endereço.");
      return;
    }

    setValue("cep", enderecoViaCepResponse.cep);
    setValue("logradouro", enderecoViaCepResponse.logradouro);
    setValue("bairro", enderecoViaCepResponse.bairro);
    setValue("cidade", enderecoViaCepResponse.localidade);
    setEstado(enderecoViaCepResponse.uf?.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoViaCepResponse]);

  //#endregion

  const ComprarComCartao = async (data: FormCadastrarCartaoArgs) => {
    if (viaCepErro) {
      toast.error("Informe um CEP válido para continuar.");
      return;
    }

    setLoadingPagamento(true);

    AxiosClient.post("/checkout", {
      ingressos: carrinho.ingressosSelecionados,
      tipoPagamento: TipoPagamento.CartaoCredito,
      cartaoCredito: {
        numero: SomenteNumeros(data.numeroCartao),
        titular: data.titular,
        mesExpiracao: parseInt(data.dataValidade.substring(0, 2)),
        anoExpiracao: parseInt(data.dataValidade.substring(3)),
        codigoSeguranca: data.codigoSeguranca,
      },
      enderecoCobranca: {
        bairro: data.bairro,
        cep: SomenteNumeros(data.cep),
        cidade: data.cidade,
        complemento: data.complemento,
        logradouro: data.logradouro,
        numero: data.numero,
        pais: "Brasil",
        unidadeFederacao: estado,
      },
      parcelas: numeroParcelas,
    })
      .then((res) => {
        navigate(`/checkout/sucesso`);
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error(
            "Erro ao realizar o pagamento. Revise os dados e tente novamente."
          );
        }
      })
      .finally(() => setLoadingPagamento(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(ComprarComCartao)}>
        {loadingPagamento ? (
          <Loading container="30vh" />
        ) : (
          <div className="col-lg-8 mt-3">
            <div className="row g-3">
              {/* Número do cartão */}
              <div className="col-lg-12">
                <label htmlFor="numeroCartao" className="text-500-dark-18 mb-2">
                  Número do cartão
                </label>
                <Input
                  autoFocus
                  control={control}
                  errors={errors}
                  validation={{
                    required:
                      "Informe os 16 dígitos do cartão (somente números)",
                  }}
                  name="numeroCartao"
                  placeholder="Número do cartão"
                  type="text"
                  variant="outlined"
                  height="24px"
                />
                {errors.numeroCartao && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.numeroCartao?.message?.toString()}
                  </p>
                )}
              </div>

              {/* Validade / CVV */}
              <div className="col-lg-8">
                <label htmlFor="dataValidade" className="text-500-dark-18 mb-2">
                  Validade
                </label>
                <Input
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe a validade",
                    pattern: {
                      value: /\d{2}\/\d{2}/,
                      message: "Informe a validade no formato MM/AA",
                    },
                    validate: {
                      validade: (value) => {
                        if (!ValidarDataDeVencimentoCartao(value)) {
                          return "Informe uma data válida";
                        }

                        return true;
                      },
                    },
                  }}
                  name="dataValidade"
                  mask="99/99"
                  maskChar="_"
                  placeholder="Validade"
                  type="text"
                  variant="outlined"
                  height="24px"
                />
                {errors.dataValidade && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.dataValidade?.message?.toString()}
                  </p>
                )}
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="codigoSeguranca"
                  className="text-500-dark-18 mb-2"
                >
                  CVV
                </label>
                <Input
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o CVV",
                    pattern: {
                      value: /\d{3,4}/,
                      message: "Informe o CVV com 3 ou 4 dígitos",
                    },
                  }}
                  name="codigoSeguranca"
                  mask="9999"
                  placeholder="CVV"
                  type="text"
                  variant="outlined"
                  height="24px"
                />
                {errors.codigoSeguranca && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.codigoSeguranca?.message?.toString()}
                  </p>
                )}
              </div>

              {/* Nome do titular */}
              <div className="col-lg-12">
                <label htmlFor="titular" className="text-500-dark-18 mb-2">
                  Nome do titular
                </label>
                <Input
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o nome do titular",
                    pattern: {
                      value: /^[a-zA-ZÀ-ÿ\s]*$/,
                      message: "Informe um nome válido",
                    },
                  }}
                  name="titular"
                  placeholder="Nome do titular"
                  type="text"
                  variant="outlined"
                  height="24px"
                />
                {errors.titular && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.titular?.message?.toString()}
                  </p>
                )}
              </div>

              {/* CEP */}
              <div
                className={`col-lg-${
                  !fetchingCep && viaCepErro ? "12 mb-4" : "8"
                }`}
              >
                <label htmlFor="cep" className="text-500-dark-18 mb-2">
                  CEP
                </label>
                <Input
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o CEP",
                    pattern: {
                      value: /^\d{5}-\d{3}$/,
                      message: "Informe um CEP válido",
                    },
                  }}
                  name="cep"
                  mask="99999-999"
                  placeholder="CEP"
                  type="text"
                  variant="outlined"
                  height="24px"
                />
                {errors.cep && (
                  <p className="text-danger text-break m-0 mt-1">
                    {errors.cep?.message?.toString()}
                  </p>
                )}
              </div>

              {fetchingCep && <Loading container="30vh" />}

              {!fetchingCep && !viaCepErro && (
                <>
                  <div className="col-lg-4">
                    <label htmlFor="bairro" className="text-500-dark-18 mb-2">
                      Bairro
                    </label>
                    <Input
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe o bairro",
                      }}
                      name="bairro"
                      placeholder="Bairro"
                      type="text"
                      variant="outlined"
                      height="24px"
                    />
                    {errors.bairro && (
                      <p className="text-danger text-break m-0 mt-1">
                        {errors.bairro?.message?.toString()}
                      </p>
                    )}
                  </div>

                  {/* Rua / Número */}
                  <div className="col-lg-8">
                    <label
                      htmlFor="logradouro"
                      className="text-500-dark-18 mb-2"
                    >
                      Rua
                    </label>
                    <Input
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe a rua",
                      }}
                      name="logradouro"
                      placeholder="Rua (Logradouro)"
                      type="text"
                      variant="outlined"
                      height="24px"
                    />
                    {errors.logradouro && (
                      <p className="text-danger text-break m-0 mt-1">
                        {errors.logradouro?.message?.toString()}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="numero" className="text-500-dark-18 mb-2">
                      Número
                    </label>
                    <Input
                      control={control}
                      errors={errors}
                      validation={{}}
                      name="numero"
                      placeholder="S/N"
                      type="text"
                      variant="outlined"
                      height="24px"
                    />
                  </div>

                  {/* Complemento */}
                  <div className="col-lg-12">
                    <label
                      htmlFor="complemento"
                      className="text-500-dark-18 mb-2"
                    >
                      Complemento
                    </label>
                    <Input
                      control={control}
                      errors={errors}
                      validation={{}}
                      name="complemento"
                      placeholder="Apartamento, bloco, etc."
                      type="text"
                      variant="outlined"
                      height="24px"
                    />
                  </div>

                  {/* Cidade */}
                  <div className="col-lg-12">
                    <label htmlFor="cidade" className="text-500-dark-18 mb-2">
                      Cidade
                    </label>
                    <Input
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe a cidade",
                      }}
                      name="cidade"
                      placeholder="Cidade"
                      type="text"
                      variant="outlined"
                      height="24px"
                    />
                    {errors.cidade && (
                      <p className="text-danger text-break m-0 mt-1">
                        {errors.cidade?.message?.toString()}
                      </p>
                    )}
                  </div>

                  <div className="col-lg-12 mb-4">
                    <label htmlFor="estado" className="text-500-dark-18">
                      Estado
                    </label>
                    <select
                      className="form-select bc-input input-outlined p-2"
                      id="estado"
                      name="estado"
                      value={estado}
                      onChange={({ target: { value } }) => setEstado(value)}
                    >
                      <option disabled value="">
                        Selecione um estado
                      </option>
                      {EstadosBrasileiros.map((estado) => (
                        <option key={estado.sigla} value={estado.sigla}>
                          {estado.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

              {/* Parcelas */}
              <div className="col-lg-12 mb-3">
                <select
                  className="form-select bc-input input-outlined p-2"
                  value={numeroParcelas.toString()}
                  onChange={({ target: { value } }) =>
                    setNumeroParcelas(+value)
                  }
                >
                  {totalParcelas.map((parcela) => (
                    <option key={parcela} value={parcela}>
                      {parcela}x
                    </option>
                  ))}
                </select>
              </div>

              {/* Gravar cartão */}
              {/* <div className="col-lg-12 d-flex align-items-center">
                <input
                  type="checkbox"
                  name="gravar_cartao"
                  id="gravar_cartao"
                  className="me-3 cursor-pointer"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  checked={gravarCartao}
                  onChange={() => setGravarCartao(!gravarCartao)}
                />
                <label
                  htmlFor="gravar_cartao"
                  className="text-400-black-16 m-0 cursor-pointer"
                >
                  Desejo gravar esse cartão de crédito para compras futuras
                </label>
              </div> */}
            </div>
          </div>
        )}
        <div className="col-lg-8 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between mt-5">
          <Button
            click={cancelar}
            type="button"
            width="216px"
            height="40px"
            className="bc-btn bc-btn-gray mb-4 mb-lg-0 me-0 me-lg-4"
            text="Cancelar"
            disabled={loadingPagamento}
          />

          <Button
            type="submit"
            width="216px"
            height="40px"
            className="bc-btn bc-btn-primary"
            text="Continuar"
            disabled={loadingPagamento}
          />
        </div>
      </form>

      {!embedded && (
        <div className="col-lg-12 mt-4">
          <p className="text-500-black-14 m-0">
            Ao continuar o processo de compra, você estará de acordo com com
            nossos{" "}
            <Link to="/termos-de-servico" className="bc-link">
              Termos de Serviço
            </Link>{" "}
            e{" "}
            <Link to="/politicas-de-privacidade" className="bc-link">
              Políticas de Privacidade
            </Link>
            .
          </p>
        </div>
      )}
    </>
  );
}
