export default function CheckSuccessModalIcon() {
  return (
    <>
      <svg
        width="125"
        height="125"
        viewBox="0 0 125 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="62.3182"
          cy="62.3182"
          rx="61.8182"
          ry="61.8182"
          fill="#52CC6C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.98 46.166C81.9596 46.166 80.9816 46.574 80.2637 47.2991L80.2608 47.302L55.677 71.8858L47.578 63.7869L47.575 63.7838C46.8572 63.0588 45.8792 62.6509 44.8588 62.6509C43.8396 62.6509 42.8627 63.0579 42.1451 63.7813C41.4217 64.499 41.0146 65.4758 41.0146 66.495C41.0146 67.5153 41.4226 68.4932 42.1475 69.2111L42.1507 69.2142L52.9375 80.0011C54.4449 81.5085 56.8832 81.5082 58.3907 80.0011L85.6882 52.7294L85.691 52.7266C86.4161 52.0087 86.8242 51.0307 86.8242 50.0102C86.8242 48.9909 86.4171 48.014 85.6936 47.2964C84.976 46.573 83.9992 46.166 82.98 46.166Z"
          fill="white"
        />
      </svg>
    </>
  );
}
