import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../../Components";
import { useState } from "react";

interface FormValues {
  cupom: string;
}

interface Props {
  loading: boolean;
}

export default function CupomDesconto({ loading }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCoupon, setShowCoupon] = useState(false);

  const { handleSubmit, register } = useForm<FormValues>({
    values: {
      cupom: searchParams.get("cupom") || "",
    },
  });

  const handleApplyCupom = (data: FormValues) => {
    setSearchParams((state) => {
      state.set("cupom", data.cupom);

      return state;
    });
  };

  return (
    <form onSubmit={handleSubmit(handleApplyCupom)} className="row g-2">
      <div className="col-12">
        <label
          htmlFor="cupom"
          className="text-primary text-decoration-underline"
          style={{ cursor: "pointer", marginLeft: "12px" }}
          onClick={() => setShowCoupon(!showCoupon)}
        >
          Possui cupom?
        </label>
      </div>
      {showCoupon && (
        <>
          <div className="col-6">
            <input
              className="form-control bc-input shadow-sm input-standard p-0 px-1"
              style={{ height: 30 }}
              id="cupom"
              type="text"
              {...register("cupom", { required: true })}
              disabled={loading}
            />
          </div>

          <div className="col-6 d-flex align-items-end">
            <Button
              className="bc-btn bc-btn-primary"
              height="30px"
              width="100%"
              text={loading ? "Aplicando..." : "Aplicar Cupom"}
              disabled={loading}
            />
          </div>
        </>
      )}
    </form>
  );
}
