import { CardInfo } from "../../../Components";

type UserInfoProps = {
  nome: string;
  email: string;
  telefone: string;
};

export default function UserInfo(props: UserInfoProps) {
  return (
    <>
      <div className="col-lg-6 d-flex align-items-center justify-content-center mt-3 mt-lg-0">
        <div className="row">
          <div className="col">
            <div className="row mb-3">
              <p className="text-500-black-16 m-0">Nome</p>
              <p className="text-400-darkest-16 m-0">{props.nome}</p>
            </div>

            <div className="row mb-3">
              <p className="text-500-black-16 m-0">E-mail</p>
              <p className="text-400-darkest-16 m-0">{props.email}</p>
            </div>

            <div className="row mb-3">
              <p className="text-500-black-16 m-0">Telefone</p>
              <p className="text-400-darkest-16 m-0">{props.telefone}</p>
            </div>

            <CardInfo text="Atenção! Essa ação não pode ser desfeita." />
          </div>
        </div>
      </div>
    </>
  );
}
