import { useEffect, useState } from "react";
import { SelecaoIngresso } from "../Evento/Components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AxiosClient from "../../Services/AxiosClient";
import ServiceResult from "../../Interfaces/ServiceResult";
import { EventoModel } from "../../Interfaces/Models";
import toast from "react-hot-toast";
import IngressoSelecionado from "../../Interfaces/IngressoSelecionado";
import Carrinho from "../../Interfaces/Carrinho";
import useLogin from "../../Hooks/useLogin";
import GrantType from "../../Enums/GrantType";
import { Layout, Loading } from "../../Components";

export default function Embedded() {
  const { eventoEmbeddedId } = useParams();

  const navigate = useNavigate();

  const { handleLogin } = useLogin();

  const [searchParams, setSearchParams] = useSearchParams();
  const codigo = searchParams.get("codigo");
  const magicLink = searchParams.get("magicLink");

  const [evento, setEvento] = useState<EventoModel>({} as EventoModel);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const localPrivado: boolean =
    evento.enderecoPrivado ||
    !evento.endereco?.logradouro ||
    !evento.endereco?.cidade ||
    !evento.endereco?.unidadeFederacao;

  const Comprar = (ingressosSelecionados: IngressoSelecionado[]) => {
    const carrinho: Carrinho = {
      imagemEvento: evento.imagemQuadrada,
      tituloEvento: evento.titulo,
      dataFimVendas: new Date(evento.dataFimVendas).toLocaleDateString(
        "pt-br",
        {
          day: "2-digit",
          month: "numeric",
          year: "numeric",
        }
      ),
      dataEvento: new Date(evento.dataInicio).toLocaleDateString("pt-br", {
        day: "2-digit",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
      classificacaoEtariaEvento: evento.idadeMinima,
      localEvento: localPrivado
        ? "Local Privado"
        : `${evento.endereco?.logradouro} - ${evento.endereco?.cidade}, ${evento.endereco?.unidadeFederacao}`,
      ingressosSelecionados: ingressosSelecionados,
      parcelamentoMaximo: evento.parcelamentoMaximo,
      eventoId: evento.id,
    };

    sessionStorage.setItem("carrinho", JSON.stringify(carrinho));
    navigate("/checkout");
  };

  // obter o evento da api pelo id da url
  const getEvento = async () => {
    setIsLoading(true);

    AxiosClient.get(`/eventos/${eventoEmbeddedId}?codigo=${codigo}`)
      .then((response) => {
        const serviceResult: ServiceResult<EventoModel> = response.data;

        setEvento(serviceResult.data as EventoModel);

        if (codigo) {
          const codigoUtilizado = serviceResult?.data?.ingressos.some(
            (i) => !!i.codigoUtilizado
          );

          if (codigoUtilizado) {
            toast.success("Código de acesso aplicado com sucesso!");
          } else {
            toast.error(
              <p className="m-0">
                Não foi possível aplicar o código de acesso:{" "}
                <strong>{codigo}</strong>
              </p>
            );
            setSearchParams((state) => {
              state.delete("codigo");
              return state;
            });
          }
        }
      })
      .catch((error: any) => {
        navigate("/");
        toast.error("Não foi possível obter o evento solicitado.");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEvento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo]);

  useEffect(() => {
    if (magicLink) {
      handleLogin(GrantType.MagicLink, { code: magicLink });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <Loading container="50vh" />
      ) : (
        <>
          <SelecaoIngresso
            nomeEvento={evento.titulo}
            isLoading={isLoading}
            ingressos={evento.ingressos}
            comprar={Comprar}
          />
        </>
      )}
    </Layout>
  );
}
