import { InfoIcon } from "./Icons";

type CardInfoProps = {
  text: string | JSX.Element;
  width?: string;
  padding?: string;
  textClassName?: string;
};

export default function CardInfo({
  textClassName = "",
  width,
  padding,
  text,
}: CardInfoProps) {
  return (
    <div
      className="card-info"
      style={{
        maxWidth: width ? width : "100%",
        padding: padding ? padding : "0",
      }}
    >
      <InfoIcon className="info-icon ms-2" />
      <div className={`text-400-darkest-14 m-2 ${textClassName}`}>{text}</div>
    </div>
  );
}
