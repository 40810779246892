import { Button, CardEvento, Footer, Header, Loading } from "../../Components";

import AxiosClient from "../../Services/AxiosClient";
import Carousel from "./Components/Carousel";
import toast from "react-hot-toast";
import ServiceResult from "../../Interfaces/ServiceResult";
import RolagemInfinita from "../../Interfaces/RolagemInfinita";
import { PiMouse } from "react-icons/pi";

import { BannerModel, EventoCardModel } from "../../Interfaces/Models";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Home() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [possuiMaisEventos, setPossuiMaisEventos] = useState<boolean>(true);
  const [pagina, setPagina] = useState<number>(1);
  const [eventos, setEventos] = useState<EventoCardModel[]>([]);
  const [banners, setBanners] = useState<BannerModel[]>([]);
  const [loadingEventos, setLoadingEventos] = useState<boolean>(false);
  const [loadingBanners, setLoadingBanners] = useState<boolean>(true);

  const getBanners = async () => {
    setLoadingBanners(true);

    AxiosClient.get<ServiceResult<BannerModel[]>>(`/banners`)
      .then(({ data: { data } }) => {
        setBanners(data as BannerModel[]);
      })
      .catch((err: any) => {
        toast.error(
          err.response?.data?.messages[0].message ??
            "Não foi possível obter os banners"
        );
      })
      .finally(() => setLoadingBanners(false));
  };

  const getEventos = async (searchString: string) => {
    setLoadingEventos(true);

    AxiosClient.get<ServiceResult<RolagemInfinita<EventoCardModel>>>(
      `/eventos?searchString=${searchString}&pagina=${pagina}&paginaTamanho=12`,
    )
      .then(({ data: { data } }) => {
        setEventos((prevValue) => [...prevValue, ...(data?.registros ?? [])]);
        setPossuiMaisEventos(data!.possuiMais);
      })
      .catch((err: any) =>
        toast.error(
          err.response?.data?.messages[0].message ??
            "Não foi possível obter os eventos"
        )
      )
      .finally(() => setLoadingEventos(false));
  };

  useEffect(() => {
    getEventos(searchParams.get("searchString") || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina]);

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregarMaisEventos = () => {
    setPagina(pagina + 1);
  };

  return (
    <>
      <Header />
      <section className="main-section">
        {/* BANNERS */}
        {loadingBanners && <Loading container="50vh" />}
        {!loadingBanners && banners.length > 0 && (
          <Carousel banners={banners} />
        )}

        {/* EVENTOS */}
        {loadingEventos && <Loading container="50vh" />}
        {!loadingEventos && (
          <div className="container py-4">
            <div className="row g-3">
              {eventos.map((e) => (
                <Link
                  to={`/evento/${e.slug}`}
                  key={e.id}
                  className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                >
                  <CardEvento
                    imagemThumbEvento={e.imagemThumbEvento}
                    dataInicio={e.dataInicio}
                    titulo={e.titulo}
                    localEvento={e.logradouro}
                    enderecoPrivado={e.enderecoPrivado}
                    possuiIngressosDisponiveisParaVenda={
                      e.possuiIngressosDisponiveisParaVenda
                    }
                  />
                </Link>
              ))}

              {possuiMaisEventos && (
                <div className="col-lg-12 pt-5 d-flex justify-content-center">
                  <Button
                    iconSvg={<PiMouse fontSize={20} />}
                    click={carregarMaisEventos}
                    className="bc-btn bc-btn-primary"
                    width="200px"
                    text="Carregar mais"
                    disabled={!possuiMaisEventos}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}
