import { BreadCrumb, Loading, Layout } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import MinhasComprasMainContent from "./Components/MainContainer";
import toast from "react-hot-toast";

import { CompraDetalheModel } from "../../Interfaces/Models";
import { ConverterStringTipoPagamento, FormatarCentavosParaReais } from "../../Utils";
import { statusColor } from ".";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { useFetch } from "../../../Hooks/useFetch";

export default function DetalhesCompra() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CompraDetalheModel | null>(null);

  const { compraId } = useParams<{ compraId: string }>();

  const navigate = useNavigate();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "Página inicial",
    },
    {
      link: "/minhas-compras",
      name: "Minhas compras",
    },
    {
      link: `/minhas-compras/${compraId}`,
      name: "Detalhes da compra",
    },
  ];

  const ObterPedido = () => {
    AxiosClient.get(`conta/pedidos/${compraId}`)
      .then(({ data: { data } }) => {
        setData(data);
      })
      .catch((error) => {
        toast.error("Erro ao carregar a compra solicitada.");
        navigate("/minhas-compras");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ObterPedido();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compraId]);

  return (
    <Layout>
      {
        // Alterar para o isFetching que vem do useFetch
        loading ? (
          <Loading container="75vh" />
        ) : (
          <div className="row g-3">
            <BreadCrumb history={breadCrumbHistory} />
            <MinhasComprasMainContent
              title="Detalhes da compra"
              backButtonNavigateTo="/minhas-compras"
            >
              <div className="row">
                <div className="col-lg-3">
                  <p className="text-500-black-16 text-500-black-16 m-0">
                    Nome completo
                  </p>
                  <p className="text-400-dark-16 ">
                    {data?.dadosPessoais.nomeCompleto}
                  </p>
                </div>
                <div className="col-lg-4">
                  <p className="text-500-black-16 m-0">Evento</p>
                  <p className="text-400-dark-16">{data?.tituloEvento}</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Valor do ingresso</p>
                  <p className="text-400-dark-16">
                    {FormatarCentavosParaReais(data?.totalCentavos || 0)}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Data de nascimento</p>
                  <p className="text-400-dark-16">
                    {new Date(data?.ultimaAtualizacaoEm || "").toLocaleString(
                      "pt-BR",
                      { timeZone: "UTC", dateStyle: "short" }
                    )}
                  </p>
                </div>
                <div className="col-lg-4">
                  <p className="text-500-black-16 m-0">Localização</p>
                  <p className="text-400-dark-16">{`${data?.endereco.logradouro},
                  ${data?.endereco.numero} - ${data?.endereco.bairro} - ${data?.endereco.cidade}/${data?.endereco.unidadeFederacao}`}</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Forma de pagamento</p>
                  <p className="text-400-dark-16">
                    {ConverterStringTipoPagamento(
                      data?.tipoPagamento.toString() || ""
                    )}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  {data?.dadosPessoais.endereco && (
                    <>
                      <p className="text-500-black-16 m-0">Estado</p>
                      <p className="text-400-dark-16">
                        {data?.dadosPessoais.endereco.unidadeFederacao || ""}
                      </p>
                    </>
                  )}
                </div>

                <div className="col-lg-4">
                  {/* <p className="text-500-black-16 m-0">Data e Hora</p>
                  <p className="text-400-dark-16">
                    Data evento
                    {new Date(data?.data || "").toLocaleString("pt-BR", {
                      timeZone: "UTC",
                      dateStyle: "medium",
                    })}
                  </p> */}
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Número do pedido</p>
                  <p className="text-400-dark-16">#{data?.numeroDoPedido}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Telefone</p>
                  <p className="text-400-dark-16">
                    {data?.dadosPessoais.telefone}
                  </p>
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Status do Ingresso</p>
                  <p style={{ color: statusColor[data?.status || "Pago"] }}>
                    {data?.status}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3"></div>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">E-mail</p>
                  <p className="text-400-dark-16">
                    {data?.dadosPessoais.email}
                  </p>
                </div>
              </div>
            </MinhasComprasMainContent>
          </div>
        )
      }
    </Layout>
  );
}
