import { Layout, Button, Cartao } from "../../Components";
import { CartaoIcon, PixIcon } from "../../Components/Icons";

import AxiosClient from "../../Services/AxiosClient";
import Carrinho from "../../Interfaces/Carrinho";
import Loading from "../../Components/Loading";
import ServiceResult from "../../Interfaces/ServiceResult";
import TipoPagamento from "../../Enums/TipoPagamento";
import toast from "react-hot-toast";

import {
  FormCadastrarCartao,
  InfoCheckoutEventoUsuario,
  InfoCheckoutIngressoCupom,
} from "./Components";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CartaoModel, PerfilUsuarioModel } from "../../Interfaces/Models";
import CheckoutArgs from "../../Interfaces/Args/CheckoutArgs";
import CheckoutIngressoArgs from "../../Interfaces/Args/CheckoutIngressoArgs";
import { useEmbeddedStore } from "../../Stores/embedded.store";

export default function Checkout() {
  const { embedded } = useEmbeddedStore();

  const [searchParams, setSearchParams] = useSearchParams();
  const cupom = searchParams.get("cupom") || "";

  const navigate = useNavigate();

  const [meioPagamento, setMeioPagamento] = useState("PIX");
  const [cadastrarCartao, setCadastrarCartao] = useState<boolean>(false);
  const [carrinho, setCarrinho] = useState<Carrinho>({} as Carrinho);
  const [userInfo, setUserInfo] = useState<PerfilUsuarioModel>(
    {} as PerfilUsuarioModel
  );
  const [cartoes, setCartoes] = useState<CartaoModel[]>([] as CartaoModel[]);
  const [numeroParcelas, setNumeroParcelas] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingCartoes, setloadingCartoes] = useState<boolean>(true);
  const [loadingPagamento, setLoadingPagamento] = useState<boolean>(false);
  const [cartaoSelecionado, setCartaoSelecionado] = useState<string>("");

  const obterTotalParcelas = (): number[] => {
    const n = carrinho.parcelamentoMaximo;
    let parcelas: number[] = [];

    for (let i = 1; i <= n; i++) {
      parcelas.push(i);
    }

    return parcelas;
  };

  const ComprarComPix = async (data: any) => {
    setLoadingPagamento(true);

    let args: CheckoutArgs = {
      ingressos: carrinho.ingressosSelecionados as CheckoutIngressoArgs[],
      tipoPagamento: TipoPagamento.Pix,
      parcelas: null,
    } as CheckoutArgs;

    if (cupom) {
      args.cupomCodigo = cupom;
    }

    AxiosClient.post("/checkout", args)
      .then(({ data: { data } }) => {
        sessionStorage.setItem("CheckoutPixKey", data.pixQrCode);
        navigate(`/checkout/pix/${data.id}`);
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error("Não foi possível realizar o pagamento.");
        }
      })
      .finally(() => setLoadingPagamento(false));
  };

  const ComprarComCartao = async (id: string) => {
    setLoadingPagamento(true);

    let args: CheckoutArgs = {
      ingressos: carrinho.ingressosSelecionados as CheckoutIngressoArgs[],
      tipoPagamento: TipoPagamento.CartaoCredito,
      parcelas: numeroParcelas,
      cartaoCreditoId: id,
    } as CheckoutArgs;

    if (cupom) {
      args.cupomCodigo = cupom;
    }

    AxiosClient.post("/checkout", args)
      .then((response) => {
        navigate(`/checkout/sucesso`);
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error(
            "Erro ao realizar o pagamento. Revise os dados e tente novamente."
          );
        }
      })
      .finally(() => setLoadingPagamento(false));
  };

  const SelecionarCartao = async (id: string) => {
    if (cartaoSelecionado === id) {
      setCartaoSelecionado("");
      return;
    }

    setCartaoSelecionado(id);
    return;
  };

  const ObterDadosPessoais = async () => {
    AxiosClient.get("conta/dados-pessoais")
      .then(({ data: { data } }) => {
        const result: PerfilUsuarioModel = data;

        setUserInfo({
          nomeCompleto: result.nomeCompleto,
          cpf: result.cpf,
          dataNascimento: new Intl.DateTimeFormat("pt-br").format(
            new Date(result.dataNascimento)
          ),
          telefone: result.telefone,
        } as PerfilUsuarioModel);
      })
      .catch(() => toast.error("Não foi possível obter seus dados pessoais."))
      .finally(() => setIsLoading(false));
  };

  const ObterCartoes = async () => {
    AxiosClient.get("conta/cartoes")
      .then(({ data: { data } }) => setCartoes(data))
      .catch(() => toast.error("Não foi possível obter seus cartões."))
      .finally(() => setloadingCartoes(false));
  };

  useEffect(() => {
    const carrinhoStr = sessionStorage.getItem("carrinho");

    if (!carrinhoStr) {
      navigate("/");
      return;
    }

    const carrinho: Carrinho = JSON.parse(carrinhoStr);
    setCarrinho(carrinho);

    ObterDadosPessoais();
    ObterCartoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        {isLoading ? (
          <Loading container="100vh" />
        ) : (
          <>
            {embedded && (
              <Button
                click={() => navigate(`/embedded/${carrinho.eventoId}`)}
                width="216px"
                height="40px"
                className="bc-btn bc-btn-primary mb-3"
                text="Voltar"
              />
            )}
            <div className="row g-3">
              <div className="col-lg-8 order-2 order-lg-1">
                <div className="row g-3">
                  {/* Informações do evento / Usuário */}
                  <InfoCheckoutEventoUsuario
                    tituloEvento={carrinho.tituloEvento}
                    dataEvento={carrinho.dataEvento}
                    classificacaoEtariaEvento={
                      carrinho.classificacaoEtariaEvento
                    }
                    localEvento={carrinho.localEvento}
                    nomeUser={userInfo.nomeCompleto}
                    cpfUser={userInfo.cpf}
                    nascimentoUser={userInfo.dataNascimento}
                    telefoneUser={userInfo.telefone}
                  />

                  {/* Pagamento */}
                  <div className="col-lg-12">
                    <div className="bg-white py-3 px-3 px-lg-5 shadow-sm">
                      <div className="row g-3 mb-3">
                        <div className="col-lg-12">
                          <p className="title-h2 text-black m-0">
                            Pagamento do ingresso
                          </p>
                        </div>

                        {/* Meios de pagamento */}
                        <div className="row px-2 g-5 mt-4">
                          {/* Pagamento: PIX */}
                          <div className="col-md-6 col-lg-4 m-0">
                            <label
                              htmlFor="PIX"
                              className="bg-white py-3 px-3 d-flex flex-column align-items-center justify-content-center cursor-pointer h-100"
                              style={{
                                boxShadow: "0px 2.7px 6.7px 0px #0000001A",
                              }}
                            >
                              <div className="mb-2">
                                <PixIcon />
                              </div>
                              <p className="text-400-black-16 mb-2">PIX</p>
                              <input
                                type="radio"
                                name="pagamento"
                                id="PIX"
                                value="PIX"
                                className="cursor-pointer"
                                style={{ width: "18px", height: "18px" }}
                                checked={meioPagamento === "PIX"}
                                onChange={({ target: { value } }) =>
                                  setMeioPagamento(value)
                                }
                              />
                            </label>
                          </div>

                          {/* Pagamento: Cartão de crédito */}
                          <div className="col-md-6 col-lg-4 m-0">
                            <label
                              htmlFor="Credito"
                              className="bg-white py-3 px-3 d-flex flex-column align-items-center justify-content-center cursor-pointer h-100"
                              style={{
                                boxShadow: "0px 2.7px 6.7px 0px #0000001A",
                              }}
                            >
                              <div className="mb-2">
                                <CartaoIcon />
                              </div>
                              <p className="text-400-black-16 mb-2 text-center">
                                Cartão de crédito
                              </p>
                              <input
                                type="radio"
                                name="pagamento"
                                id="Credito"
                                value="Credito"
                                className="cursor-pointer"
                                style={{ width: "18px", height: "18px" }}
                                checked={meioPagamento === "Credito"}
                                onChange={({ target: { value } }) =>
                                  setMeioPagamento(value)
                                }
                              />
                            </label>
                          </div>
                        </div>

                        {meioPagamento === "Credito" &&
                          !cadastrarCartao &&
                          (loadingPagamento ? (
                            <div className="row g-3 d-flex justify-content-center align-items-center">
                              <Loading container="30vh" />
                            </div>
                          ) : (
                            <div className="row g-3">
                              {loadingCartoes ? (
                                <Loading container="30vh" />
                              ) : (
                                cartoes.map((c) => (
                                  <div
                                    key={c.id}
                                    className="col-lg-8"
                                    style={{
                                      border:
                                        c.id === cartaoSelecionado
                                          ? "2px solid black"
                                          : "none",
                                    }}
                                    onClick={() => SelecionarCartao(c.id)}
                                  >
                                    <Cartao
                                      id={c.id}
                                      bandeira={c.bandeira}
                                      digitosIniciais={c.cartaoPrimeirosDigitos}
                                      digitosFinais={c.cartaoUltimosDigitos}
                                    />
                                  </div>
                                ))
                              )}
                              <div className="col-lg-8 mb-3">
                                <button
                                  onClick={() => setCadastrarCartao(true)}
                                  className="btn w-100 shadow-sm bg-white card-cartao p-3 mb-4"
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="icons">
                                      <img
                                        src="/assets/images/icons/add_blue_circle.png"
                                        alt="add icon"
                                        width="30"
                                      />
                                      <span className="ps-3">Novo cartão</span>
                                    </div>
                                    <p className="text-400-black-16 m-0 fw-bolder">
                                      &gt;
                                    </p>
                                  </div>
                                </button>

                                {cartaoSelecionado && (
                                  <div className="col-lg-12 mb-3">
                                    <p className="text-400-black-16 mb-2">
                                      Número de parcelas
                                    </p>
                                    <select
                                      className="form-select bc-input input-outlined p-2"
                                      value={numeroParcelas.toString()}
                                      onChange={({ target: { value } }) =>
                                        setNumeroParcelas(+value)
                                      }
                                    >
                                      {obterTotalParcelas().map((parcela) => (
                                        <option key={parcela} value={parcela}>
                                          {parcela}x
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}

                                <Button
                                  className="shadow-sm bc-btn bc-btn-primary mt-4"
                                  disabled={!cartaoSelecionado}
                                  text="Comprar"
                                  click={() =>
                                    ComprarComCartao(cartaoSelecionado)
                                  }
                                />
                              </div>
                            </div>
                          ))}

                        {meioPagamento === "Credito" && cadastrarCartao && (
                          <FormCadastrarCartao
                            cancelar={() => setCadastrarCartao(false)}
                            carrinho={carrinho}
                            totalParcelas={obterTotalParcelas()}
                            numeroParcelas={numeroParcelas}
                            setNumeroParcelas={setNumeroParcelas}
                          />
                        )}

                        {meioPagamento === "PIX" && (
                          <>
                            <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start">
                              <Button
                                disabled={loadingPagamento}
                                click={ComprarComPix}
                                width="216px"
                                height="40px"
                                className="bc-btn bc-btn-primary mt-4"
                                text={
                                  loadingPagamento
                                    ? "Carregando..."
                                    : "Continuar"
                                }
                              />
                            </div>

                            {!embedded && (
                              <div className="col-lg-12 mt-4">
                                <p className="text-500-black-14 m-0">
                                  Ao continuar o processo de compra, você estará
                                  de acordo com com nossos{" "}
                                  <Link
                                    to="/termos-de-servico"
                                    className="bc-link"
                                  >
                                    Termos de Serviço
                                  </Link>{" "}
                                  e{" "}
                                  <Link
                                    to="/politicas-de-privacidade"
                                    className="bc-link"
                                  >
                                    Políticas de Privacidade
                                  </Link>
                                  .
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Informações do ingresso */}

              <InfoCheckoutIngressoCupom />
            </div>
          </>
        )}
      </Layout>
    </>
  );
}
