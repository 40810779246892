export default function PixIcon() {
  return (
    <>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.7877 36.2251L41.619 45.0571C43.2469 46.6843 45.4114 47.5802 47.7138 47.5802H49.4474L38.3033 58.7244C34.8223 62.2047 29.1794 62.2047 25.6985 58.7244L14.5223 47.5482H15.5821C17.8838 47.5482 20.0484 46.6517 21.6762 45.0251L30.4755 36.2251C31.0932 35.6061 32.17 35.608 32.7877 36.2251ZM52.0337 19.0096L58.7239 25.6993C62.2049 29.1796 62.2049 34.8232 58.7239 38.3035L52.0343 44.9925H47.7136C46.1256 44.9925 44.5722 44.3487 43.4505 43.2263L34.6185 34.3949C33.0226 32.8003 30.2397 32.799 28.6451 34.3955L19.8458 43.1943C18.724 44.3167 17.17 44.9605 15.5826 44.9605H11.9345L5.27819 38.3035C1.79789 34.8232 1.79789 29.1796 5.27819 25.6993L11.9345 19.0423H15.5826C17.17 19.0423 18.724 19.6861 19.8458 20.8086L28.6451 29.608C29.4685 30.4314 30.5505 30.8434 31.6312 30.8434C32.7138 30.8434 33.7944 30.4314 34.6178 29.6093L43.4505 20.7766C44.5722 19.6541 46.1256 19.0103 47.7136 19.0103L52.0337 19.0096ZM38.3033 5.2787L49.4467 16.4229H47.7138C45.4121 16.4229 43.2475 17.3194 41.6197 18.946L32.7883 27.7793C32.1523 28.4153 31.1128 28.4179 30.4762 27.7787L21.6769 18.978C20.049 17.3514 17.8838 16.4549 15.5821 16.4549H14.5223L25.6991 5.2787C29.1788 1.79773 34.823 1.79773 38.3033 5.2787Z"
          fill="#00BDAE"
        />
      </svg>
    </>
  );
}
