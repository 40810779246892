import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CleanLayout, Loading } from "../../../Components";
import GrantType from "../../../Enums/GrantType";
import useLogin from "../../../Hooks/useLogin";
import { useValidateToken } from "../../../Hooks/useValidateToken";

export default function LoginMagicLink() {
  const { magicLink } = useParams();

  useValidateToken();

  const { handleLogin, loading } = useLogin();

  useEffect(() => {
    if (magicLink) {
      handleLogin(GrantType.MagicLink, { code: magicLink });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CleanLayout>
      {loading && <Loading container="75vh" />}

      {!loading && (
        <>
          <p className="mb-2">Não foi possível realizar o login.</p>

          <Link to="/">
            <p className="mb-4">Clique aqui para voltar à página inicial.</p>
          </Link>

          {/* spacing divs (due to CleanLayout) */}
          <div className="my-5 py-5"></div>
          <div className="my-5 py-5"></div>
          <div className="my-5 py-5"></div>
        </>
      )}
    </CleanLayout>
  );
}
