export default function ArrowLeftIcon() {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5824 11.5612H7.41244L12.2924 6.68119C12.6824 6.29119 12.6824 5.65119 12.2924 5.26119C12.1056 5.07397 11.852 4.96875 11.5874 4.96875C11.3229 4.96875 11.0693 5.07397 10.8824 5.26119L4.29244 11.8512C4.10522 12.038 4 12.2917 4 12.5562C4 12.8207 4.10522 13.0743 4.29244 13.2612L10.8824 19.8512C11.1343 20.1031 11.5014 20.2014 11.8455 20.1092C12.1896 20.017 12.4583 19.7483 12.5505 19.4042C12.6427 19.0602 12.5443 18.6931 12.2924 18.4412L7.41244 13.5612H18.5824C19.1324 13.5612 19.5824 13.1112 19.5824 12.5612C19.5824 12.0112 19.1324 11.5612 18.5824 11.5612Z"
          fill="black"
        />
      </svg>
    </>
  );
}
