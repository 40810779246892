export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        margin: "2rem",
        textAlign: "center",
      }}
    >
      <h1>Oops! A página que está tentando acessar não foi encontrada.</h1>
    </div>
  );
}