import moment from "moment";

// Formatar a data no formato: 29 mar - 15:00
export const FormatDate = (date: string): string => {
  return `${new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "short",
  })} - ${new Date(date).toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

/**
 * Formatar a data no formato: 29/03/2021
 * @param date 1976-02-11T00:00:00-03:00
 * @returns 11/02/1976
 */
export const FormatDatePTBR = (date: string): string => {
    return moment.utc(date).format("DD/MM/YYYY");
};

/**
 * Formatar a data no formato JSON
 * @param date 11/02/1976
 * @returns 1976-11-02T03:00:00.000Z
 */
export const FormatDateJSON = (date: string): string => {
    return moment(date, "DD/MM/YYYY").toDate().toJSON();
}

