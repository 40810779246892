import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "./Icons";

export default function LinkVoltarParaLogin() {
  return (
    <>
      <Link
        to="/login"
        className="d-flex align-items-center mb-5 text-decoration-none"
      >
        <ArrowLeftIcon />

        <p className="text-500-black-18 ps-3 m-0">Voltar para login</p>
      </Link>
    </>
  );
}
