import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { NotFound, PrivateRoute } from "../Components";

import {
    AdicionarCartao,
    Ajuda,
    Cadastro,
    Checkout,
    CheckoutPIX,
    CheckoutSucesso,
    DetalhesCartao,
    DetalhesCompra,
    DetalhesIngressosEvento,
    Embedded,
    EsqueciSenha,
    Evento,
    Home,
    IngressoAguardandoAprovacao,
    Login,
    LoginMagicLink,
    MeusFavoritos,
    MeusIngressos,
    MinhasCompras,
    Perfil,
    PoliticasDePrivacidade,
    RedefinirSenha,
    TermosDeServico,
    TransferenciaIngresso,
} from "../Pages";

export default function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/auth/:magicLink" element={<LoginMagicLink />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/esqueci-senha" element={<EsqueciSenha />} />
                <Route path="/redefinir-senha/:token" element={<RedefinirSenha />} />

                <Route path="/" element={<Home />} />

                <Route path="/evento/:eventoId" element={<Evento />} />

                <Route path="/embedded/:eventoEmbeddedId" element={<Embedded />} />

                <Route path="/evento/:eventoId/ingresso-aguardando-aprovacao/:ingressoId"
                    element={<Navigate to="/meus-ingressos" />} />

                <Route path="/checkout"
                    element={<PrivateRoute><Checkout /></PrivateRoute>} />
                <Route path="/checkout/pix/:pedidoId"
                    element={<PrivateRoute><CheckoutPIX /></PrivateRoute>} />
                <Route path="/checkout/sucesso"
                    element={<PrivateRoute><CheckoutSucesso /></PrivateRoute>} />

                <Route path="/meu-perfil"
                    element={<PrivateRoute><Perfil /></PrivateRoute>} />
                <Route path="/meu-perfil/redefinir-senha"
                    element={<PrivateRoute><RedefinirSenha /></PrivateRoute>} />
                <Route path="/meu-perfil/cadastrar-cartao"
                    element={<PrivateRoute><AdicionarCartao /></PrivateRoute>} />
                <Route path="/meu-perfil/cartao/:cartaoId"
                    element={<PrivateRoute><DetalhesCartao /></PrivateRoute>} />

                <Route path="/meus-ingressos"
                    element={<PrivateRoute><MeusIngressos /></PrivateRoute>} />
                <Route path="/meus-ingressos/:eventoId"
                    element={<PrivateRoute><DetalhesIngressosEvento /></PrivateRoute>} />

                <Route path="/meus-favoritos"
                    element={<PrivateRoute><MeusFavoritos /></PrivateRoute>} />

                <Route path="/minhas-compras"
                    element={<PrivateRoute><MinhasCompras /></PrivateRoute>} />
                <Route path="/minhas-compras/:compraId"
                    element={<PrivateRoute><DetalhesCompra /></PrivateRoute>} />

                <Route path="/transferir-ingresso/:id"
                    element={<PrivateRoute><TransferenciaIngresso /></PrivateRoute>} />

                <Route path="/termos-de-servico"
                    element={<TermosDeServico />} />

                <Route path="/politicas-de-privacidade"
                    element={<PoliticasDePrivacidade />} />

                <Route path="/ajuda"
                    element={<Ajuda />} />

                {/* 404 pages */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}
