import RedefinirSenhaArgs from "../Interfaces/Args/RedefinirSenhaArgs";
import AxiosClient from "../Services/AxiosClient";
import toast from "react-hot-toast";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRouteHistoryStore } from "../Stores/route-history.store";

export default function useRedefinirSenha() {
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const { setRedirect } = useRouteHistoryStore();

  const RedefinirSenha = async (data: RedefinirSenhaArgs): Promise<void> => {
    try {
      setLoading(true);

      if (window.location.pathname === "/meu-perfil/redefinir-senha") {
        AxiosClient.post("/conta/redefinir-senha", {
          ...data,
        }).then(() => {
          toast.success("Senha alterada com sucesso!");
          navigate("/meu-perfil");
        });

        return;
      }

      AxiosClient.post("/conta/esqueci-minha-senha/nova-senha", {
        token,
        ...data,
      }).then(() => {
        toast.success("Sua senha foi redefinida com sucesso!");

        setRedirect("/");

        navigate("/login", { replace: true });
      });
    } catch (err) {
      toast.error(
        `Erro ao redefinir a senha, verifique os dados informados e tente novamente`,
      );
    } finally {
      setLoading(false);
    }
  };

  return { loading, RedefinirSenha };
}
