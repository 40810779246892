import { useSearchParams } from "react-router-dom";
import { CertificadoSSLIcon } from "../../../Components/Icons";
import IngressoSelecionado from "../../../Interfaces/IngressoSelecionado";
import { FormatarCentavosParaReais } from "../../../Utils";
import CupomDesconto from "./CupomDesconto";
import { useEffect, useState } from "react";
import Carrinho from "../../../Interfaces/Carrinho";
import ServiceResult from "../../../Interfaces/ServiceResult";
import SimulacaoPedidoModel from "../../../Interfaces/Models/SimulacaoPedidoModel";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";

interface SimulacaoPedidoArgs {
  cupomCodigo: string;
  ingressos: Record<string, number>;
  canalVenda: "Site";
}

export default function InfoCheckoutIngressoCupom() {
  const [searchParams, setSearchParams] = useSearchParams();
  const cupom = searchParams.get("cupom");

  const [carrinho, setCarrinho] = useState<Carrinho>({} as Carrinho);
  const [loading, setLoading] = useState<boolean>(false);

  const cupomDescontoAplicado =
    carrinho.ingressosSelecionados?.every((i) => i.valorCentavosComDesconto) &&
    cupom &&
    !loading;

  const handleApplyCupom = () => {
    setLoading(true);

    let args: SimulacaoPedidoArgs = {
      ingressos: carrinho.ingressosSelecionados?.reduce((acc, ingresso) => {
        acc[ingresso.ingressoId] = ingresso.quantidade;
        return acc;
      }, {} as Record<string, number>),
      canalVenda: "Site",
      cupomCodigo: cupom!,
    };

    AxiosClient.post<ServiceResult<SimulacaoPedidoModel>>(
      `/Eventos/${carrinho.eventoId}/simulacao-pedido`,
      args
    )
      .then(({ data: result }) => {
        // pegar os ingressos com o valor aplicado do desconto
        // setar o valor atualizado no carrinho para exibir no checkout
        carrinho.ingressosSelecionados =
          result.data?.itens.reduce((acc, item) => {
            const tmp = carrinho.ingressosSelecionados.find(
              (i) => i.ingressoId === item.ingressoId
            );

            if (tmp) {
              const ingresso: IngressoSelecionado = {} as IngressoSelecionado;

              ingresso.ingressoId = item.ingressoId;

              ingresso.nome = tmp.nome;

              ingresso.valorCentavos = item.valorIngressoMaisTaxasCentavos;

              // manter o valor antigo para exibir no checkout
              ingresso.valorCentavosSemTaxa = tmp.valorCentavosSemTaxa;

              // valor com desconto (prop só existe no front)
              ingresso.valorCentavosComDesconto =
                item.valorIngressoBaseCentavos;

              ingresso.quantidade = tmp.quantidade;

              ingresso.valorTaxa =
                item.taxaAdministracaoCentavos + item.taxaProcessamentoCentavos;

              ingresso.valorTaxaProcessamento = item.taxaProcessamentoCentavos;

              ingresso.valorTaxaServico = item.taxaAdministracaoCentavos;

              ingresso.codigoAcessoUtilizado = tmp.codigoAcessoUtilizado;

              acc.push(ingresso);
            }

            return acc;
          }, [] as IngressoSelecionado[]) ?? [];

        sessionStorage.setItem("carrinho", JSON.stringify(carrinho));

        toast.success("Cupom aplicado com sucesso!");
      })
      .catch((error: any) => {
        setSearchParams((state) => {
          state.delete("cupom");
          return state;
        });

        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error("Erro ao aplicar o cupom.");
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (cupom) {
      handleApplyCupom();
    }
  }, [cupom]);

  useEffect(() => {
    const carrinho = JSON.parse(
      sessionStorage.getItem("carrinho") ?? ""
    ) as Carrinho;

    setCarrinho(carrinho);
  }, []);

  return (
    <div className="col-lg-4 order-1">
      <div className="bg-white px-2 py-2 shadow-sm">
        {carrinho &&
          carrinho.ingressosSelecionados &&
          carrinho.ingressosSelecionados.map((i) => (
            <div
              className="d-flex flex-wrap justify-content-start align-items-center"
              key={i.ingressoId}
            >
              {/* Img evento */}
              <img
                src={carrinho.imagemEvento}
                style={{
                  width: "118px",
                  height: "118px",
                  borderRadius: "5px",
                  margin: "8px",
                }}
                alt="evento"
              />
              {/* Ingresso / qtde / valor + taxa / data limite vendas */}
              <div className="d-flex flex-column justify-content-center">
                <p className="text-500-black-18 text-break mb-1">{`${i.quantidade}x ${i.nome}`}</p>
                <p className="text-500-black-16 mb-1">
                  {FormatarCentavosParaReais(i.valorCentavosSemTaxa)} + (
                  {FormatarCentavosParaReais(i.valorTaxaServico)})
                </p>
                <p className="text-500-dark-16 mb-1">
                  Vendas até {carrinho.dataFimVendas}
                </p>
              </div>
            </div>
          ))}

        {/* Valor do ingresso */}
        {/* <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Valor do ingresso</p>
          <p className="text-500-black-14 m-0">R$ 35,00</p>
        </div> */}

        {/* Taxa do ingresso */}
        {/* <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Taxas</p>
          <p className="text-500-black-14 m-0">R$ 25,00</p>
        </div> */}

        {/* Descontos */}
        {/* <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Descontos</p>
          <p className="text-500-black-14 m-0" style={{ color: "#52CC6C" }}>
            -R$ 5,00
          </p>
        </div> */}

        {/* Valor ingressos */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between mt-4">
          <p className="text-500-black-14 m-0">Valor do(s) ingresso(s)</p>
          <p className="text-500-black-14 m-0">
            {cupomDescontoAplicado ? (
              <del className="m-0 text-danger">
                {FormatarCentavosParaReais(
                  carrinho.ingressosSelecionados?.reduce((acc, curr) => {
                    return acc + curr.valorCentavosSemTaxa * curr.quantidade;
                  }, 0)
                )}
              </del>
            ) : (
              <>
                {FormatarCentavosParaReais(
                  carrinho.ingressosSelecionados?.reduce((acc, curr) => {
                    return acc + curr.valorCentavosSemTaxa * curr.quantidade;
                  }, 0)
                )}
              </>
            )}
          </p>
        </div>

        {cupomDescontoAplicado && (
          <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
            <p className="text-500-black-14 m-0">Valor com desconto</p>
            <p className="text-500-black-14 m-0">
              {FormatarCentavosParaReais(
                carrinho.ingressosSelecionados?.reduce((acc, curr) => {
                  return acc + curr.valorCentavosComDesconto! * curr.quantidade;
                }, 0)
              )}
            </p>
          </div>
        )}

        {/* Taxa de serviço */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Taxa de serviço</p>
          <p className="text-500-black-14 m-0">
            {FormatarCentavosParaReais(
              carrinho.ingressosSelecionados?.reduce((acc, curr) => {
                return acc + curr.valorTaxaServico * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        {/* Taxa de processamento */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Processamento</p>
          <p className="text-500-black-14 m-0">
            {FormatarCentavosParaReais(
              carrinho.ingressosSelecionados?.reduce((acc, curr) => {
                return acc + curr.valorTaxaProcessamento * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        <hr />

        <CupomDesconto loading={loading} />

        <hr />

        {/* Valor total */}
        <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
          <p className="text-500-black-14 m-0">Valor total</p>
          <p className="text-500-black-14 m-0 text-success">
            {FormatarCentavosParaReais(
              carrinho.ingressosSelecionados?.reduce((acc, curr) => {
                return acc + curr.valorCentavos * curr.quantidade;
              }, 0)
            )}
          </p>
        </div>

        {/* Cupom */}
        {/* <div className="mt-4 px-3 d-flex flex-column">
          <p className="text-400-black-16 m-0">Possui um cupom de desconto?</p>
          <form onSubmit={handleSubmit(AplicarCupom)}>
            <Input
              control={control}
              defaultValue=""
              name="cupom"
              hasErrors={!!errors.cupom}
              rules={{
                required: "Por favor, informe um cupom para ser aplicado",
              }}
              type="text"
              className="my-2"
              placeholder="Cupom"
              variant="outlined"
            />
            {errors.cupom && (
              <p className="text-danger text-break m-0 mt-1">
                {errors.cupom.message?.toString()}
              </p>
            )}

            <button type="submit" className="bc-link-primary w-100 text-center">
              Aplicar cupom
            </button>
          </form>
        </div> */}
      </div>

      <div className="d-flex flex-column align-items-end mt-4">
        <p className="text-500-black-14 m-0">Pagamento seguro</p>
        <div
          className="my-2 w-75"
          style={{ border: "1px solid #45395B" }}
        ></div>
        <CertificadoSSLIcon />
      </div>
    </div>
  );
}
