import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "../../../Components/Icons";

export default function LinkVoltarParaPerfil() {
  return (
    <Link
      to="/meu-perfil"
      className="d-flex align-items-center mb-4 text-decoration-none"
    >
      <ArrowLeftIcon />

      <p className="text-500-black-18 ps-3 m-0">Voltar para o perfil</p>
    </Link>
  );
}
