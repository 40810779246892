export default function CartaoIcon() {
  return (
    <>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0594 38.7299C10.0638 40.0335 11.1195 41.0892 12.4231 41.0936H33.7462C33.9176 42.7368 34.3768 44.337 35.1029 45.821H10.0594C8.80435 45.8252 7.59944 45.3285 6.71196 44.4411C5.82448 43.5536 5.32779 42.3487 5.33206 41.0936L5.35604 12.7274C5.34643 11.4748 5.8381 10.2705 6.72155 9.38252C7.605 8.49457 8.80686 7.99678 10.0594 8.00002H47.8904C49.1441 7.99788 50.3469 8.49551 51.2327 9.38276C52.1184 10.27 52.614 11.4737 52.6097 12.7274V29.7532C51.597 29.3958 50.5559 28.8838 49.5064 28.3676C46.7712 27.0224 43.9787 25.6489 41.4806 26.9115H10.0594V38.7299ZM12.4222 12.7265C11.1186 12.7309 10.0629 13.7866 10.0586 15.0902V17.4539H47.8895V15.0902C47.8851 13.7827 46.8233 12.7254 45.5158 12.7265H12.4222Z"
          fill="#003967"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.1196 31.4362C36.8512 26.4986 43.3494 24.5442 49.1855 26.604C54.868 28.6083 58.6678 33.9793 58.6663 40.0049C58.6659 46.1939 54.6598 51.6709 48.7617 53.5461C42.8636 55.4213 36.4301 53.2634 32.8557 48.211C29.2812 43.1585 29.388 36.3737 33.1196 31.4362ZM35.1829 43.8464C36.7381 47.6014 40.4021 50.0497 44.4664 50.0497C47.1312 50.0503 49.687 48.9921 51.5714 47.108C53.4559 45.2239 54.5146 42.6683 54.5146 40.0036C54.5154 35.9393 52.0677 32.2748 48.3131 30.7189C44.5584 29.163 40.2362 30.0222 37.362 32.8957C34.4878 35.7693 33.6278 40.0914 35.1829 43.8464Z"
          fill="#003967"
        />
        <path
          d="M50.1424 38.5823H45.8806V34.3224C45.8519 33.558 45.2239 32.9531 44.459 32.9531C43.694 32.9531 43.066 33.558 43.0374 34.3224V38.5823H38.7756C38.0111 38.6109 37.4062 39.2389 37.4062 40.0039C37.4062 40.7688 38.0111 41.3968 38.7756 41.4255H43.0374V45.6873C43.066 46.4517 43.694 47.0566 44.459 47.0566C45.2239 47.0566 45.8519 46.4517 45.8806 45.6873V41.4275H50.1424C50.9068 41.3988 51.5117 40.7708 51.5117 40.0059C51.5117 39.2409 50.9068 38.6129 50.1424 38.5842V38.5823Z"
          fill="#003967"
        />
      </svg>
    </>
  );
}
