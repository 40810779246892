interface CartaoProps {
  id: string;
  bandeira: string;
  digitosIniciais: string | number;
  digitosFinais: string | number;
}

export default function Cartao({
  id,
  bandeira,
  digitosIniciais,
  digitosFinais,
}: CartaoProps) {
  function handleBandeira(): string {
    switch (bandeira) {
      case "visa":
        return "visa.png";
      case "mastercard":
        return "mastercard.png";
      case "amex":
        return "amex.png";
      default:
        return "default_card.png";
    }
  }

  return (
    <div className="btn w-100 shadow-sm bg-white card-cartao p-3">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <img
            src={"/assets/images/bandeiras_cartao/" + handleBandeira()}
            className="me-2"
            alt="bandeira do cartão"
            width="30"
          />
          <span>
            {digitosIniciais}.••••.••••.{digitosFinais}
          </span>
        </div>
        <p className="text-400-black-16 m-0 fw-bolder">&gt;</p>
      </div>
    </div>
  );
}
