import { useState } from "react";
import LoginArgs from "../Interfaces/Args/LoginArgs";
import ServiceResult from "../Interfaces/ServiceResult";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../Services/AxiosClient";
import { FieldValues } from "react-hook-form";
import { useRouteHistoryStore } from "../Stores/route-history.store";
import GrantType from "../Enums/GrantType";
import ToGrantType from "../Utils/ToGrantType";
import { AuthModel } from "../Interfaces/Models";
import { useEmbeddedStore } from "../Stores/embedded.store";

let requestCouter = 0;

export default function useLogin(lembrarSenha: boolean = false) {
  const { embedded } = useEmbeddedStore();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { redirect, setRedirect } = useRouteHistoryStore();

  const handleLogin = async (grantType: GrantType, data: FieldValues) => {
    if (requestCouter > 0) {
      return;
    }

    requestCouter++;

    setLoading(true);

    const args: LoginArgs = {
      ...data,
      grant_type: ToGrantType(grantType),
      lembrarSenha: lembrarSenha,
    };

    AxiosClient.postForm<ServiceResult<AuthModel>>("/auth/token", args)
      .then(({ data: result }) => {
        if (lembrarSenha) {
          localStorage.setItem("auth", JSON.stringify(result.data));
        } else {
          sessionStorage.setItem("auth", JSON.stringify(result.data));
        }

        if (embedded) {
          // se o embedded estiver como true na session, redireciona para /checkout
          navigate("/checkout");
        }
        // verifica se possui uma rota de redirecionamento na store
        if (redirect) {
          navigate(redirect);
          setRedirect(null);
        } else {
          // caso contrário, volta 1 página
          navigate(-1);
        }

        toast.success("Bem vindo!!!");
      })
      .catch((error: any) => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const result: ServiceResult = error.response.data;
          toast.error(
            result.messages.map((m) => "➡️ " + m.message).join("\n\n")
          );
        } else {
          toast.error(
            "Ocorreu um erro inesperado. Tente novamente mais tarde."
          );
        }
      })
      .finally(() => {
        setLoading(false);
        requestCouter = 0;
      });
  };

  return { handleLogin, loading };
}
