import clsx from "clsx";
import ReactInputMask from "react-input-mask";

import { ComponentProps } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";

interface Props extends Omit<ComponentProps<"input">, "ref"> {
  control: Control<any>;
  errors: FieldErrors;
  height?: "24px" | "32px" | "40px" | "48px";
  name: string;
  mask?: string;
  maskChar?: string;
  validation: RegisterOptions;
  variant?: "standard" | "filled" | "outlined";
}

const Input: React.FC<Props> = ({
  className = "",
  control,
  errors,
  height = "32px",
  name,
  mask = "",
  maskChar = "",
  validation,
  variant = "standard",
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <ReactInputMask
          id={name}
          mask={mask}
          maskChar={maskChar}
          className={clsx(
            `${className} form-control bc-input shadow-sm input-${variant}`,
            errors[name] && "border border-danger text-danger"
          )}
          name={field.name}
          onChange={field.onChange}
          style={{
            height: height,
          }}
          value={field.value}
          {...rest}
        />
      )}
    />
  );
};

export default Input;
