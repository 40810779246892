import { BreadCrumb, Loading, Layout } from "../../Components"
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import MinhasComprasMainContent from "./Components/MainContainer";
import toast from "react-hot-toast";

import { CompraModel } from "../../Interfaces/Models";
import { ConverterStringTipoPagamento, FormatarCentavosParaReais } from "../../Utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceResult from "../../Interfaces/ServiceResult";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/minhas-compras",
    name: "Minhas compras",
  },
];

export const statusColor = {
  Pago: "#52CC6C",
  Pendente: "#F9A000",
  Cancelado: "#FF0000",
};

export default function MinhasCompras() {
  const [loading, setLoading] = useState<boolean>(true);
  const [compras, setCompras] = useState<CompraModel[]>([] as CompraModel[]);

  const navigate = useNavigate();

  useEffect(() => {
    AxiosClient.get<ServiceResult<CompraModel[]>>("/conta/pedidos")
      .then(({ data: { data } }) => {
        setCompras(data!.sort((a, b) => {
          if (Number(a.numeroPedido) > Number(b.numeroPedido)) return -1;
          if (Number(a.numeroPedido) < Number(b.numeroPedido)) return 1;
          return 0;
        }));
      })
      .catch((error) => {
        toast.error("Erro ao carregar as compras. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const irParaDetalhesDaCompra = (id: string) => {
    navigate(`/minhas-compras/${id}`);
  };

  return (
    <>
      <Layout>
        <div className="row g-3">
          <BreadCrumb history={breadCrumbHistory} />

          <MinhasComprasMainContent
            title="Minhas compras"
            backButtonNavigateTo="/"
          >
            <>
              {loading && <Loading container="50vh" />}

              {!loading && compras && (
                <table className="table table-hover">
                  <thead>
                    <tr
                      style={{
                        borderBottom: "10px solid #fff",
                      }}
                    >
                      <th className="text-500-black-18 m-0">Nome do evento</th>
                      <th className="text-500-black-18 m-0">
                        Data do pagamento
                      </th>
                      <th className="text-500-black-18 m-0">
                        Valor total do pedido
                      </th>
                      <th className="text-500-black-18 m-0">
                        Forma de pagamento
                      </th>
                      <th className="text-500-black-18 m-0">Status</th>
                      <th className="text-500-black-18 m-0">Quantidade</th>
                      <th className="text-500-black-18 m-0">
                        Número do pedido
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {compras.map((c) => (
                      <tr
                        key={c.id}
                        onClick={() => irParaDetalhesDaCompra(c.id)}
                        className="cursor-pointer align-middle"
                        style={{
                          background: "#F9F8F8",
                          height: "65px",
                          borderRadius: "4px",
                          borderBottom: "10px solid #fff",
                        }}
                      >
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Nome do evento"
                        >
                          {c.tituloEvento}
                        </td>
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Data do pagamento"
                        >
                          {new Date(c.ultimaAtualizacaoEm).toLocaleString(
                            "pt-BR",
                            { timeZone: "UTC", dateStyle: "short" }
                          )}
                        </td>
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Valor total"
                        >
                          {FormatarCentavosParaReais(c.totalCentavos)}
                        </td>
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Forma de pagamento"
                        >
                          {ConverterStringTipoPagamento(
                            c.tipoPagamento.toString()
                          )}
                        </td>
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Status"
                        >
                          <p
                            className="text-500-darkest-16 m-0"
                            style={{ color: statusColor[c.status] }}
                          >
                            {c.status}
                          </p>
                        </td>
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Quantidade"
                        >
                          {c.quantidadeIngressos}
                        </td>
                        <td
                          className="text-500-darkest-16 m-0"
                          data-label="Número do pedido"
                        >
                          #{c.numeroPedido}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!loading && compras?.length === 0 && (
                <p className="mt-5 text-center">Nenhuma compra encontrada.</p>
              )}
            </>
          </MinhasComprasMainContent>
        </div>
      </Layout>
    </>
  );
}
