import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

type ModalProps = {
  children: string | JSX.Element | JSX.Element[];
  isOpen: boolean;
  close: () => void | ((args: any) => void);
  title?: string;
  width?: string;
  closeNonFocus?: boolean;
};

export default function Modal(props: ModalProps) {
  const customStyles = {
    content: {
      maxWidth: props.width,
    },
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      className="modal-content"
      overlayClassName="modal-overlay"
      contentLabel="Example Modal"
    >
      {props.title ? (
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <p className="modal-title">{props.title}</p>
          <img
            onClick={props.close}
            className="p-2 cursor-pointer"
            width={30}
            height={30}
            src="/assets/images/icons/close.png"
            alt="close"
          />
        </div>
      ) : (
        <div className="mb-4 d-flex justify-content-end align-items-center">
          <img
            onClick={props.close}
            className="p-2 cursor-pointer"
            width={30}
            height={30}
            src="/assets/images/icons/close.png"
            alt="close"
          />
        </div>
      )}
      {props.children}
    </ReactModal>
  );
}
