import { BreadCrumb, Loading, Layout, CardEvento } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import RolagemInfinita from "../../Interfaces/RolagemInfinita";
import ServiceResult from "../../Interfaces/ServiceResult";
import toast from "react-hot-toast";

import { EventoCardModel } from "../../Interfaces/Models";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TablePagination } from "../../Components/TablePagination";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "Página inicial",
  },
  {
    link: "/meus-favoritos",
    name: "Meus favoritos",
  },
];

export default function MeusFavoritos() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [favoritos, setFavoritos] = useState<EventoCardModel[]>(
    [] as EventoCardModel[],
  );
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("size")) || 8;

  const ObterEventosFavoritos = async () => {
    setIsLoading(true);

    AxiosClient.get<ServiceResult<RolagemInfinita<EventoCardModel>>>(
      `/eventos?favoritos=true&pagina=${page}&paginaTamanho=${pageSize}`,
    )
      .then(({ data: { data } }) => {
        setFavoritos(data?.registros ?? ([] as EventoCardModel[]));
        setNumeroPaginas(data?.paginas ?? 0);
      })
      .catch((error) => {
        toast.error(
          "Erro ao carregar os favoritos. Tente novamente mais tarde.",
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    ObterEventosFavoritos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  return (
    <Layout>
      <div className="row g-3">
        <BreadCrumb history={breadCrumbHistory} />

        <div className="col-lg-12">
          <div
            className="bg-white py-3 px-3 px-lg-5 shadow-sm"
            style={{ minHeight: "calc(100vh - 339px)" }}
          >
            <p className="title-h1 text-black m-0">Meus favoritos</p>

            <div className="row g-3">
              {isLoading && <Loading container="50vh" />}

              {!isLoading &&
                favoritos.length > 0 &&
                favoritos.map((e) => (
                  <Link
                    to={`/evento/${e.id}`}
                    key={e.id}
                    className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                  >
                    <CardEvento
                      imagemThumbEvento={e.imagemThumbEvento}
                      dataInicio={e.dataInicio}
                      titulo={e.titulo}
                    />
                  </Link>
                ))}

              {!isLoading && favoritos?.length === 0 && (
                <p className="mt-5 text-center">
                  Você não possui nenhum ingresso favoritado.
                </p>
              )}
            </div>
          </div>

          {!isLoading && favoritos.length > 0 && (
            <TablePagination numeroPaginas={numeroPaginas} />
          )}
        </div>
      </div>
    </Layout>
  );
}
