import { WhiteLabel } from "../../Utils";

interface MinusIconProps {
  disabled?: boolean;
}

export default function MinusIcon({ disabled }: MinusIconProps) {
  return (
    <>
      {disabled ? (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="14.0377"
            cy="14.0377"
            rx="14.0377"
            ry="14.0377"
            transform="matrix(0 -1 -1 0 28.0762 29)"
            fill="#D8D8D8"
          />
          <path
            d="M6.14105 14.9611C6.14105 14.7866 6.21037 14.6192 6.33378 14.4958C6.45718 14.3724 6.62455 14.3031 6.79907 14.3031L21.2756 14.3031C21.4501 14.3031 21.6175 14.3724 21.7409 14.4958C21.8643 14.6192 21.9336 14.7866 21.9336 14.9611C21.9336 15.1356 21.8643 15.303 21.7409 15.4264C21.6175 15.5498 21.4501 15.6191 21.2756 15.6191L6.79907 15.6191C6.62455 15.6191 6.45718 15.5498 6.33378 15.4264C6.21037 15.303 6.14105 15.1356 6.14105 14.9611Z"
            fill="#9D9D9D"
          />
        </svg>
      ) : (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="14.0377"
            cy="14.0377"
            rx="14.0377"
            ry="14.0377"
            transform="matrix(0 -1 -1 0 28.0762 29)"
            fill={WhiteLabel().colorPrimary}
          />
          <path
            d="M6.14105 14.9611C6.14105 14.7866 6.21037 14.6192 6.33378 14.4958C6.45718 14.3724 6.62455 14.3031 6.79907 14.3031L21.2756 14.3031C21.4501 14.3031 21.6175 14.3724 21.7409 14.4958C21.8643 14.6192 21.9336 14.7866 21.9336 14.9611C21.9336 15.1356 21.8643 15.303 21.7409 15.4264C21.6175 15.5498 21.4501 15.6191 21.2756 15.6191L6.79907 15.6191C6.62455 15.6191 6.45718 15.5498 6.33378 15.4264C6.21037 15.303 6.14105 15.1356 6.14105 14.9611Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
