export default function WalletIcon() {
  return (
    <>
      <>
        {/*?xml version="1.0" encoding="utf-8"?*/}
        {/* Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
        <svg
          version="1.1"
          id="US"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 110.095 34.016"
          // style={{ enableBackground: "new 0 0 110.095 34.016" }}
          xmlSpace="preserve"
        >
          <g>
            <g>
              <path
                style={{ fill: "#B1B3B6" }}
                d="M99.846,0H10.254C9.858,0,9.469,0,9.076,0.002C8.749,0.005,8.421,0.009,8.087,0.016
			c-0.714,0.019-1.439,0.062-2.155,0.19C5.214,0.332,4.545,0.548,3.889,0.881C3.245,1.209,2.657,1.639,2.15,2.148
			C1.636,2.657,1.208,3.247,0.882,3.89C0.548,4.543,0.336,5.217,0.21,5.937C0.08,6.649,0.036,7.369,0.019,8.09
			C0.008,8.419,0.006,8.75,0,9.078c0,0.391,0,0.782,0,1.174v13.511c0,0.397,0,0.785,0,1.176c0.006,0.334,0.008,0.659,0.019,0.991
			c0.017,0.72,0.061,1.441,0.191,2.152c0.126,0.72,0.338,1.394,0.672,2.048c0.326,0.641,0.754,1.231,1.268,1.738
			c0.506,0.511,1.094,0.941,1.739,1.267c0.655,0.335,1.325,0.547,2.043,0.676c0.716,0.13,1.441,0.17,2.155,0.192
			c0.334,0.007,0.662,0.009,0.989,0.009c0.393,0.002,0.781,0.002,1.178,0.002h89.591c0.384,0,0.779,0,1.166-0.002
			c0.328,0,0.662-0.002,0.991-0.009c0.72-0.022,1.443-0.062,2.146-0.192c0.724-0.129,1.394-0.341,2.054-0.676
			c0.643-0.327,1.235-0.756,1.739-1.267c0.512-0.507,0.939-1.097,1.271-1.738c0.332-0.653,0.544-1.327,0.67-2.048
			c0.128-0.712,0.172-1.432,0.195-2.152c0.004-0.332,0.004-0.657,0.004-0.991c0.013-0.391,0.013-0.779,0.013-1.176V10.253
			c0-0.393,0-0.784-0.013-1.174c0-0.328,0-0.659-0.004-0.989c-0.023-0.721-0.067-1.441-0.195-2.153
			c-0.126-0.72-0.338-1.393-0.67-2.047c-0.332-0.643-0.758-1.233-1.271-1.742c-0.504-0.509-1.096-0.939-1.739-1.267
			c-0.659-0.333-1.329-0.549-2.054-0.675c-0.704-0.128-1.426-0.17-2.146-0.19c-0.33-0.007-0.664-0.011-0.991-0.014
			C100.625,0,100.23,0,99.846,0L99.846,0z"
              />
              <path
                d="M99.846,0.664c0.382,0,0.775,0,1.157,0.002l0.057,0.001l0.922,0.013c0.577,0.017,1.327,0.051,2.054,0.18
			c0.674,0.119,1.285,0.32,1.863,0.614c0.586,0.298,1.113,0.682,1.573,1.146c0.462,0.46,0.846,0.99,1.147,1.571
			c0.296,0.579,0.491,1.189,0.607,1.869c0.13,0.704,0.17,1.433,0.187,2.045c0.006,0.325,0.006,0.651,0.006,0.991
			c0.008,0.386,0.008,0.771,0.008,1.159v13.511c0,0.393,0,0.775-0.008,1.176c0,0.33,0,0.653-0.006,0.97
			c-0.017,0.622-0.057,1.35-0.187,2.06c-0.115,0.67-0.311,1.28-0.601,1.856c-0.307,0.584-0.691,1.113-1.155,1.575
			c-0.458,0.461-0.985,0.845-1.571,1.142c-0.567,0.29-1.178,0.491-1.863,0.616c-0.71,0.125-1.436,0.161-2.045,0.181
			c-0.328,0.007-0.655,0.009-0.985,0.009c-0.384,0.002-0.777,0.002-1.159,0.002H10.254c-0.393,0-0.783,0-1.178-0.002
			c-0.325,0-0.647-0.002-0.968-0.009c-0.617-0.02-1.344-0.056-2.058-0.181c-0.683-0.125-1.292-0.327-1.859-0.616
			c-0.58-0.293-1.107-0.679-1.575-1.149c-0.46-0.454-0.842-0.981-1.142-1.567c-0.29-0.571-0.489-1.177-0.611-1.865
			c-0.134-0.735-0.166-1.479-0.181-2.054c-0.006-0.227-0.011-0.456-0.013-0.685l-0.004-0.286v-1.176V10.253V9.089L0.67,8.791
			c0.002-0.227,0.006-0.454,0.013-0.685c0.015-0.568,0.046-1.312,0.183-2.053c0.12-0.686,0.319-1.294,0.611-1.862
			c0.298-0.587,0.68-1.114,1.144-1.572c0.464-0.466,0.991-0.85,1.573-1.146C4.767,1.18,5.378,0.979,6.05,0.859
			c0.729-0.129,1.48-0.162,2.05-0.18l0.977-0.014c0.395-0.002,0.785-0.002,1.178-0.002H99.846z"
              />
            </g>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_1_"
                    d="M33.601,21.437c0,0.199,0,0.398-0.001,0.597c-0.001,0.168-0.003,0.335-0.008,0.503
					c-0.01,0.365-0.032,0.733-0.097,1.094c-0.067,0.367-0.176,0.708-0.347,1.041c-0.169,0.327-0.389,0.627-0.652,0.886
					c-0.263,0.26-0.566,0.477-0.898,0.644c-0.337,0.169-0.683,0.277-1.054,0.343c-0.366,0.065-0.739,0.086-1.109,0.096
					c-0.17,0.005-0.34,0.006-0.509,0.007c-0.202,0.001-0.403,0.001-0.605,0.001l-0.168,0H15.155l-2.316,0
					c-0.202,0-0.403,0-0.605-0.001c-0.17-0.001-0.34-0.003-0.509-0.007c-0.37-0.01-0.743-0.031-1.109-0.096
					c-0.371-0.066-0.717-0.173-1.054-0.343c-0.331-0.167-0.635-0.384-0.898-0.644c-0.263-0.26-0.483-0.559-0.652-0.886
					c-0.172-0.333-0.281-0.674-0.347-1.041C7.6,23.27,7.578,22.902,7.568,22.537c-0.005-0.168-0.007-0.335-0.008-0.503
					c-0.001-0.199-0.001-0.398-0.001-0.597l0-2.286v-6.999l0-1.459c0-0.127,0-0.254,0.001-0.381
					c0.001-0.107,0.002-0.214,0.005-0.321c0.006-0.233,0.02-0.468,0.062-0.698c0.043-0.234,0.112-0.452,0.222-0.664
					c0.108-0.209,0.248-0.4,0.416-0.565c0.168-0.166,0.361-0.305,0.573-0.411C9.052,7.544,9.273,7.475,9.51,7.433
					c0.233-0.041,0.472-0.055,0.708-0.061c0.108-0.003,0.217-0.004,0.325-0.005c0.129-0.001,0.257-0.001,0.386-0.001l1.478,0h17.722
					l-1.373,0.001l1.478-0.001c0.129,0,0.257,0,0.386,0.001c0.108,0.001,0.217,0.002,0.325,0.005
					c0.236,0.006,0.474,0.02,0.708,0.061c0.237,0.042,0.457,0.111,0.672,0.219c0.211,0.106,0.405,0.245,0.573,0.411
					c0.168,0.166,0.308,0.357,0.416,0.565c0.11,0.213,0.179,0.43,0.222,0.664c0.042,0.23,0.056,0.465,0.062,0.698
					c0.003,0.107,0.004,0.214,0.005,0.321c0.001,0.127,0.001,0.254,0.001,0.381l0,1.459v6.999L33.601,21.437z"
                  />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
                <path
                  id="wallet_back_2_"
                  style={{ clipPath: "url(#SVGID_2_)", fill: "#D3D1C8" }}
                  d="M33.601,9.87v16.78H7.559V9.852h0
				c0-1.423,1.101-2.457,2.459-2.457v0h21.123v0h0c1.358,0,2.459,1.025,2.459,2.457C33.601,9.858,33.601,9.864,33.601,9.87z"
                />
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="20.5806"
                  y1="16.4079"
                  x2="20.5806"
                  y2="18.3485"
                >
                  <stop offset="0.0038" style={{ stopColor: "#D3D1C8" }} />
                  <stop offset="0.2318" style={{ stopColor: "#C4C3BB" }} />
                  <stop offset="0.6887" style={{ stopColor: "#A5A59F" }} />
                  <stop offset={1} style={{ stopColor: "#8C8D88" }} />
                </linearGradient>
                <rect
                  x="7.56"
                  y="16.408"
                  style={{ clipPath: "url(#SVGID_2_)", fill: "url(#SVGID_3_)" }}
                  width="26.041"
                  height="1.941"
                />
                <g style={{ clipPath: "url(#SVGID_2_)" }}>
                  <defs>
                    <path
                      id="blue_1_"
                      d="M8.644,20.764V11.55l0-0.936c0-0.082,0-0.163,0-0.245c0-0.069,0.001-0.137,0.003-0.206
						c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426c0.068-0.134,0.157-0.257,0.264-0.363
						C9.198,8.82,9.321,8.731,9.455,8.663c0.136-0.069,0.276-0.113,0.427-0.14c0.148-0.027,0.299-0.035,0.449-0.039
						c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0l0.937,0c0.082,0,0.163,0,0.245,0
						c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039c0.15,0.027,0.29,0.071,0.427,0.14
						c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363c0.07,0.136,0.114,0.276,0.141,0.426
						c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206c0,0.082,0,0.163,0,0.245l0,0.936v9.214H8.644z"
                    />
                  </defs>
                  <clipPath id="blue_2_">
                    <use xlinkHref="#blue_1_" style={{ overflow: "visible" }} />
                  </clipPath>
                  <path
                    id="blue_4_"
                    style={{ clipPath: "url(#blue_2_)", fill: "#319DCC" }}
                    d="M8.644,20.764V11.55l0-0.936c0-0.082,0-0.163,0-0.245
					c0-0.069,0.001-0.137,0.003-0.206c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426
					c0.068-0.134,0.157-0.257,0.264-0.363C9.198,8.82,9.321,8.731,9.455,8.663c0.136-0.069,0.276-0.113,0.427-0.14
					c0.148-0.027,0.299-0.035,0.449-0.039c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0
					l0.937,0c0.082,0,0.163,0,0.245,0c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039
					c0.15,0.027,0.29,0.071,0.427,0.14c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363
					c0.07,0.136,0.114,0.276,0.141,0.426c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206
					c0,0.082,0,0.163,0,0.245l0,0.936v9.214H8.644z"
                  />
                  <g id="yellow_shdw" style={{ clipPath: "url(#blue_2_)" }}>
                    <image
                      style={{ overflow: "visible", opacity: "0.17" }}
                      width={171}
                      height={99}
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALUAAABqCAYAAAD6OxBTAAAACXBIWXMAAEU0AABFNAGuxrUdAAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABfpJREFUeNrsmw1v2kgURcfGkEC3
abb7///jfjQkBLAbpDfq7dOM8dgm7cbnSCMTSMioPb5cP0gIAAAAAAAAAEL1i5634p/+w9NNfPy3
kLrKHBEZuszX3S0Er24gsl/+9yD3MmVWgf2aVfBmBpl11ZljcJIj+LJSWYVtM8cucxK8i9QpkeNa
ZW73JTd8bLlV2NbWOXO7zaT3TaXOibyy54rHRu6vE3Ij9rKEbp3El3WSFb9WyUcndjNB6Cjwumd5
uUns5Sa0ynyU9Sq3T+aEpnex2M0IoaOkUdw7Wfd23NjyYvuejdgf+8Iw1gkv9EXkg6wXue19KBa7
KRS6FqGjyNu3tXNHlTuK7WsIUi9D6ri80BeR92/r2Y578SN3oTl7UnuhL+J+svWHrE9ObE1rpF6m
1JrSKvTT2/pm4acV9drYb5LUfsrRiNRR5oe39cWOD05slbrhYnGxF4nxgjCm9LMIfW+OqNB+EtLe
on74lN6auBeJ/7T1aHJ/NrG3UkEadyYGxP7wQgd3kahS703onaR05fq3rrokrYcmtZd6Z+J+MaH/
eltfTWwv9TozAaF+fPz64ScfR5F6ZyndOKGPbhoSf7aao35ULqkbl9Sxejya0F9N8AepH5vE9KPK
iIzcH0NmL7Wffhwk8KLQrSS5TkJeJQzboWIPSWo/xruXpI59+lFqyGeT+j4x+aBPL7dXq7gbl9BH
17X38j11orZ2U5K66knqeKH42daD3N7Jmbhyoxqqx7IqSHBTkLW8arcmdJR5lxgw+Ff40Untq4dK
vbEU3rpxXhzvxa60SWwKqZcptaZ1LaIfzZfc+xu5MfCkTh2X1o9N+DGn3rrl31FM9WhEXmYNib04
fn0KP78bfeemZamJ2WSpfVKvXFqnNrPObIiLw2UmdeW6dQg/fwAu9VmhPn+qOaTuE3stgnuZV66P
I/FyqBKSx/tqqSAr51Tq051FMpckdWoK0vRshE/jQU7yLuQ/i19n/Cl2qB4otE9rPcNWA6QGCCH9
537+r6aqK5WjmiJ130b6zi56M4yRPMz1Cl8P+KUhk9j1lTMPmaHvlf9aAo8e/dYDy/6QvxZHYpiS
1mGuQKxHnGlhrjMK4BbUhTIPKe1MPWBqUN5c6qHlHuB/k9QAi5WatAaSGgCpAZAakBoAqQGQGgCp
AZAakJp/AkBqAKQGQGoApAZAakBqAKQGQGoApAZAakBqAKQGQGoApAZAakBqAKQGQGoApAZAagCk
BqQGQGoApAZAagCkBqQGQGoApAZAagCkBqQGQGoApAZAaoBfInXHPyf8Ds7UN94sosO7O1HPtNmO
xIaRCd3N7UwzQeLuyub8Riv+Lxef0Nd86eaQvBlxVvUJHlebkJgER+7oRs6bod7NltRdZnOtrLOt
OlFvSGqqhvdE3fHCjw7CZuBVqU/hs6yTW0f7mZWIjNCIHY9nc8R7c3ayD0nwUUmdSudWZH61DR5s
xec7i9QIDepQdOcg62gunVyCj0rtpmAzrZxlcROXDT3bWtv3n+x564TUCM6UoxWp9+LPQULymEjs
WTp1qnKcJJmfbVPfROjLY3dOamQGTVuV+uLQf+bQXuQ+utQumooMSerWpXTczJNtqBGhtyY4UsM1
qY/m0UXof8ylJye2VpHZph++B11eHl7sl9/Jz59sM5f7Nq5PIzT4V/6z1Ne9Cf23iP3iOnZRBRlS
P+KZoim9SQi9lftX4cc4D6lB09oPGvRV/99EWrel3bpvpFclXi4OIm2QbhSTe22PMc6DvgpyztTZ
J6sjvlu3JVOQkgvFeGbFBD7LmRaFXtvj9Gm41qtbmXQcrHI8y3p1Qs92oaiTD+3HrXv5WLvagdAw
RGydqMWOHUd7r4kJyCyd2m8gJC4cG6kcNReIUHDBqO9OnyS5T+EdRnqpaUisHytXORAaSsVuReCT
+7p4pFcVfE+UVo/+vmrE88Py6oeX2384zt836AKxVDrtyKkV6NEwoV/79PZ1o+ht8lIB+wRGZpia
3l3mGG4p9dCfRXAoqSFDHwMAAAAAAAAo57sAAwDM848n9UM41wAAAABJRU5ErkJggg=="
                      transform="matrix(0.16 0 0 0.16 6.919 8.4428)"
                    ></image>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M8.644,22.932v-9.214l0-0.936c0-0.082,0-0.163,0-0.245c0-0.069,0.001-0.137,0.003-0.206
								c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426c0.068-0.134,0.157-0.257,0.264-0.363
								c0.106-0.106,0.229-0.195,0.363-0.264c0.136-0.069,0.276-0.113,0.427-0.14c0.148-0.027,0.299-0.035,0.449-0.039
								c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0l0.937,0c0.082,0,0.163,0,0.245,0
								c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039c0.15,0.027,0.29,0.071,0.427,0.14
								c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363c0.07,0.136,0.114,0.276,0.141,0.426
								c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206c0,0.082,0,0.163,0,0.245l0,0.936v9.214H8.644z"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="yellow_5_"
                    style={{ clipPath: "url(#blue_2_)", fill: "#FDB921" }}
                    d="M8.644,22.932v-9.214l0-0.936c0-0.082,0-0.163,0-0.245
					c0-0.069,0.001-0.137,0.003-0.206c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426
					c0.068-0.134,0.157-0.257,0.264-0.363c0.106-0.106,0.229-0.195,0.363-0.264c0.136-0.069,0.276-0.113,0.427-0.14
					c0.148-0.027,0.299-0.035,0.449-0.039c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0
					l0.937,0c0.082,0,0.163,0,0.245,0c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039
					c0.15,0.027,0.29,0.071,0.427,0.14c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363
					c0.07,0.136,0.114,0.276,0.141,0.426c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206
					c0,0.082,0,0.163,0,0.245l0,0.936v9.214H8.644z"
                  />
                  <g id="green_shdw" style={{ clipPath: "url(#blue_2_)" }}>
                    <image
                      style={{ overflow: "visible", opacity: "0.16" }}
                      width={171}
                      height={99}
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALUAAABqCAYAAAD6OxBTAAAACXBIWXMAAEU0AABFNAGuxrUdAAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABaxJREFUeNrsm4tS20gQRTWyAGMI
hM3/f2QWiJ+SVq7qqb30ztjWwxuCzqnqkrEVZ4o6vu5piaIAAAAAAAAAAACAP4jwh70vfB3azy51
SDwOyA4ZedvEa5NJXk0ksx61zkkO8xJbj1qTJng1gcxapTumJId5Sp2qxh0nSe5qoNBeZK2FO55K
b5hHOqdkrt1Rqx0jdjVCaJV4Ye8Vj5U8n5KbPnse/XNO5mMdpGo5BpF7kNjVAKE1laPAN13dWt1I
VRmxkXl+Kd2IvHupnTse5H0GiV2NEFpFvrNaWsWfvdhIPV+pvdDbrjZSlT0X5a6Hil31EFrFVpnv
u1q5Urm1HUm1IMj9NdsP33ocROqjxGurX1L6bb6T92umlroQmRfSWixN4IeuvnX1aMcHq/tEWpdM
QmY58WhE6J1I/dbVqwVkJX74fx+daaeQ2id0JULfm8RPVs9WTyb1yqS+TUhNQs+r/VCpt5LQr+ZR
dCTI+X4aMpnUvp9e2AKWkszfu/qrqxd7/GSvITVS55I6pvTK/IhutDId0Wr6pHU1IKXvbDGPlswv
JvUPe/yckHpxoqeGr99+RFlV6rj3qkRoPxnRkV87hdSKpnTcHD5YKr+Y0D9M7mfXfuT6acSe1/Sj
EVnXst8qJcl3biKyFXeaqTaK2nYsJKm1n362tuPFKvbUS9d6cPFlXtOP3ARk73roxmTeWp/9bnVn
Yqcu3rVTtB/BbRLj6O6b2yzGn1fySSSlSWttQTTkougbE3llgbk8sRcbnNQh0XrES+C3xcdx3qOV
jvLijJpeGrFV7Cho6YTWax05oSdPap1Ra1/ta3li4sEFl/m2IlFqTdxD8fFqdPRGb7WYNKlzfbW/
z8MvKnXPB0LPE03U4NoQ9akq/nu/0KiLdX0vvgxZDH30vMWOSR2knfW3KKcqd9vyWcoeiwsJqf1t
ptw/Def2aEUmKEvXcw++T6hPUqf+IKDMLAKhISd2ONEJTPLXUmXPReUEDwV/vgX9XcpJXozZg42R
+hKRERsuTe9zck8udej5aQP4bZQDPlm55h2ZYczmsZgqIEt+tzDnpA4TnQPwqdoPAKQGQGoApAZA
akBqAKQGQGoApAZAakBqAKQGQGoApAZAakBqAKQGQGoApAZAagCkBqQGQGoApAZAagCkBqQGQGoA
pAZAagCkBqQGQGoApAZAagCkBqQGQGoApAZAagCkBkBqQGoApAZAagCkBkBqQOoBtPxK4Xe7Ul55
EUgO6kLb05n22lKfW2ybWQhiQ86JNuHPaKoRAreJT1VzjUXCl5Fa3WgukP2qUrcnBG8SVbMRhYwz
zYlqp/imr3ouqE3Ie6yD1L6rYOcGKwBN6b1zppY6JfhVpI7/oQq8cxXsnIVLauSmn27FH+/N3sne
ZFrc0VK3iT5IZd52telq3dWtiVvb+y5Iasi4FB36ZbU2j6LgKbEvTu1Lk7qRNiMKvbYFvTuhtyI1
CQ2pTWD06OjQa1dvIvfWJXZsSSZrPzSh64TQryL0wT5tdyY0UkNO6lqkPnr0s6u/Re6NvV4P6a+r
HhvDuJDYbry5hI5C3yI1XCj1TtL6px2j1DGxz01HBrUfUey4iI0JHaWNCb0yqSvZJCI05ILy4NL6
1dI6iq1tyCRJ3Tqha9mxrmUT2Nhz75LSuklk8gGpCUib2J/Fb/83J3Uc9V28Ybw0qaPUW5loNPJJ
80LTesAlLcjBdQBrqY0k9VU3ilHs1iX3jRM6IDWckdqLrWPibfHvaK/3WK+6YAGN9M5F8fECzM7J
nJtNIzcyp8KyLT5eld7LMT7nrzKeJVz4etz0lbIJVJFLOYeUhj5p7Sdserm8HpLUocc5wclduue4
eghDpyH+zr3GPS76TD9Cz/NC4jHpDFOmdur20143NvWVMGREp3+GKfps//ygu/TGCIi88H/IDgAA
APDZ+UeAAQDgfmoG1hDY0AAAAABJRU5ErkJggg=="
                      transform="matrix(0.16 0 0 0.16 6.9433 10.6889)"
                    ></image>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M8.644,25.1v-9.214l0-0.936c0-0.082,0-0.163,0-0.245c0-0.069,0.001-0.137,0.003-0.206
								c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426c0.068-0.134,0.157-0.257,0.264-0.363
								c0.106-0.106,0.229-0.195,0.363-0.264c0.136-0.069,0.276-0.113,0.427-0.14c0.148-0.027,0.299-0.035,0.449-0.039
								c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0l0.937,0c0.082,0,0.163,0,0.245,0
								c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039c0.15,0.027,0.29,0.071,0.427,0.14
								c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363c0.07,0.136,0.114,0.276,0.141,0.426
								c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206c0,0.082,0,0.163,0,0.245l0,0.936V25.1H8.644z"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="green_5_"
                    style={{ clipPath: "url(#blue_2_)", fill: "#71BF49" }}
                    d="M8.644,25.1v-9.214l0-0.936c0-0.082,0-0.163,0-0.245
					c0-0.069,0.001-0.137,0.003-0.206c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426
					c0.068-0.134,0.157-0.257,0.264-0.363c0.106-0.106,0.229-0.195,0.363-0.264c0.136-0.069,0.276-0.113,0.427-0.14
					c0.148-0.027,0.299-0.035,0.449-0.039c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0
					l0.937,0c0.082,0,0.163,0,0.245,0c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039
					c0.15,0.027,0.29,0.071,0.427,0.14c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363
					c0.07,0.136,0.114,0.276,0.141,0.426c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206
					c0,0.082,0,0.163,0,0.245l0,0.936V25.1H8.644z"
                  />
                  <g id="red_shdw" style={{ clipPath: "url(#blue_2_)" }}>
                    <image
                      style={{ overflow: "visible", opacity: "0.17" }}
                      width={171}
                      height={85}
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALUAAABYCAYAAACzibb1AAAACXBIWXMAAEU0AABFNAGuxrUdAAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABcBJREFUeNrsnQ1v2kgURW1jCKFJ
utn+/x+5mwZCYjxrS2+0V69j8Bdtgs+RnkwoIaPqcLkeHCXLAG6M/Is+N3x9wrWeuLyixDmywwB5
w2eSOj9zzBEaEsIGmdnlLmeSOd4u5Ovc/VuO1IuWWiVuj/W15C4nCK1TyNFPSm5YptBBhPYT758s
djlBaC/vSqaU+4ozyQ23XzmCS+b2eJKp3H21kzlcW2qfyqVIvE4cVyJ2kaggiL0MoTWdK5P3w6aS
YyWS12PFLkcIXYjQUd5NM3c28fZaBI+pTVIvV2pN5ncT+SjzbpPbY7KxYpcDhM5dQq9F5PtmtnaM
t6PgmthdUiP47e1y+B4dEzrK+2azb+Zgt1fOtSpRR2aRWmXWqrG12TXz0Mw3mSj3nTy+q4Ig9e1L
XUvleLdk3tu8NvPTjoXzIX5/PiSthyR1lHot6dwK/NjMkzvubLSGpKRG5tuvH7WT+igJ/WKzMRfV
B78zMlv96KodMaFbgf9y82jJvZMKUrqdEIRe3s5HJVIfLJl35tJaUjq4nZA4vdO6b/3Q6rGxlH6w
ZG5F/tHM3808230pqQukXpzYXfXjYJXjXs65Mie/7oYUQ9K6HJDSvnq04n43kX/YPNt9D9KpdQeE
6rFMqYPb+TiYHxs5OfSd+13kPklaz5rUvnp8k6R+lqT+LieLmwsniYi9jPrhK8jW3IiV4yQ7IgfZ
EemqrWFqpy7OJPWTSazzZFJvB6Q0ct/e7kcqraPUpVSKk+yGxEqylXd5H4iTklrrxyohdUzrBzs5
fJSTRN3O83uPCLzcXZBSnNCE3ptLu0Rt9Uk9ulP7K+u0fmwSYu9kQfrBS+raD9J5eamtFSQXoWMV
SX1oV2bd1w6Nrh95ooLoJ4lbN/oJ4rrHjgdSL09q3ZZbS0huEu6kpJ6U1OcqSNmxoE3iLYMdj+WS
Om+qs1+v6izd6NWeoypr0XNxvoaUZxbDpabQp876y5aLhNDFmPOxIVJ3XTvtLy/lFwOgT3Jn2flf
LBlcO/pK3fUKyy8sgu4Ml971U57MskNWTFhQ169tZRlbdzDeq1SgXkXqvGMhlx6L2DC0kkz2ppi4
ED5UgT8h/axSc+IHnzqlx3Tqq7yyAOak4L8AkBoAqQGQGgCpAZAakBoAqQGQGgCpAZAakBoAqQGQ
GgCpAZAakBoAqQGQGgCpAZAaAKkBqQGQGgCpAZAaAKkBqQGQGgCpAZAaAKkBqQGQGmA44atIHX7X
wgHZ55A6fLZXJHw5WYeGXri21PpDglvouUFsCAm5r+ZLOWGBfjG1m/hXcePj+ctdSO096SN2uJbU
qVeaLu7kJr4D1MgMzp2UL6cO0a+e1CEhcdXMh5uVPXaVdf+9aVhm9YjStt68izOVHKNbQR4/q9Sh
Q+i4mHZhx2bebFay6EISG5khuKRu3TmIO17y05lqMjmp/WLiD44y75vZ2nMF+7e1Cc3fLAcfkF7q
n+bQwcldSS0ZlNZ9pa5d3Ygyvzqh2wXd2dcrUho63vWjRwdz6F8ndxT75Hr2ZKlDR0of3WJWInS7
qI3dh9TQJbW6tDeP/mnmxb6OiV2NOYHs06njk8Zy/yYL8ULfWfVYSf1AbPD1Q32KFeRFErsrrScl
td+LPjmpX+Uk8MOEvreULtn5gDM7IEGk1rR+NbFjWh+lWw+qIH06de2kPpigsRu9Wa+OXbqrTyM1
UmtQVu4c7WAy76WCpJJ6lvoR3NuFvtqi5F5otvLgUgOondhxNy2esx3HniyWAxYQy72W/aN16LWc
HBYkNPSstVHs6Na7HKvs1/3qybsfWj+yjk5UOpn93jRCw6WwrF3F1U8VU7sfk6UO0p9rt5gi+/+T
w5zdDhghtya2NgJ/cdygD1/ygY/TJM47ZEZoGCO2F3z05aj5iBdA6kiHhrl2RUJHVcmuIXXqe/IZ
nxeWK/Q50ScJ+ie+H6Cv7AAAAAAA8Jv4T4ABAIfieNtnuXZCAAAAAElFTkSuQmCC"
                      transform="matrix(0.16 0 0 0.16 6.9433 12.8568)"
                    ></image>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M8.644,25.1v-7.046l0-0.936c0-0.082,0-0.163,0-0.245c0-0.069,0.001-0.137,0.003-0.206
								c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426c0.068-0.134,0.157-0.257,0.264-0.363
								c0.106-0.106,0.229-0.195,0.363-0.264c0.136-0.069,0.276-0.113,0.427-0.14c0.148-0.027,0.299-0.035,0.449-0.039
								c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0l0.937,0c0.082,0,0.163,0,0.245,0
								c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039c0.15,0.027,0.29,0.071,0.427,0.14
								c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363c0.07,0.136,0.114,0.276,0.141,0.426
								c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206c0,0.082,0,0.163,0,0.245l0,0.936V25.1H8.644z"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="red_5_"
                    style={{ clipPath: "url(#blue_2_)", fill: "#F37163" }}
                    d="M8.644,25.1v-7.046l0-0.936c0-0.082,0-0.163,0-0.245
					c0-0.069,0.001-0.137,0.003-0.206c0.004-0.15,0.013-0.3,0.039-0.448c0.027-0.15,0.071-0.29,0.141-0.426
					c0.068-0.134,0.157-0.257,0.264-0.363c0.106-0.106,0.229-0.195,0.363-0.264c0.136-0.069,0.276-0.113,0.427-0.14
					c0.148-0.027,0.299-0.035,0.449-0.039c0.069-0.002,0.137-0.003,0.206-0.003c0.082,0,0.163,0,0.245,0l0.937,0h18.592l-0.869,0
					l0.937,0c0.082,0,0.163,0,0.245,0c0.069,0,0.137,0.001,0.206,0.003c0.15,0.004,0.301,0.013,0.449,0.039
					c0.15,0.027,0.29,0.071,0.427,0.14c0.134,0.068,0.257,0.157,0.363,0.264c0.106,0.106,0.196,0.229,0.264,0.363
					c0.07,0.136,0.114,0.276,0.141,0.426c0.027,0.148,0.035,0.299,0.039,0.448c0.002,0.069,0.003,0.137,0.003,0.206
					c0,0.082,0,0.163,0,0.245l0,0.936V25.1H8.644z"
                  />
                </g>
                <g
                  id="wallet_pocket_shdw"
                  style={{ clipPath: "url(#SVGID_2_)" }}
                >
                  <image
                    style={{ overflow: "visible", opacity: "0.12" }}
                    width={181}
                    height={79}
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALwAAABYCAYAAABPnJ0/AAAACXBIWXMAAEU0AABFNAGuxrUdAAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACPlJREFUeNrsnQtz2joQhSXjhIS8
2rxu//8PvL3Nq+FhdPHM7uSwrAzmZdOeM6OxoQSK9Hl1tBJWCBRFURRFURRFnZJiz97nEEps5j+2
fVu3bbmnLx9PvEETYT7ZgNaqLcstKgaP8cSiQGoJefoLwI4tz/varmmTtitbVJZ+6ULO8ehdAH2p
lGTOk6mQtG3lnRjkTYEqF8xiT9sW22gu53jMtlvZAnaFuy4DU4pMpcQOr3oL+dw5D5nzuXOBnBL4
FuAiE6yCOY+mLQsH/i7aNteudTtVpsQm6NtEeAX9DMq5vIeC37XNSQ2wV3KcG7DtedXw+r6DHxuC
VAHBaZDpqXOvjx23rdeuCvhsUSaLMoUSnDbbCHj8ogr7cFEuFuVSjkN5vgTo+wL83ICMJfdvMylT
OK9OAHxrO7EnLp2CvXOxpvcuTE/QNfAVtNF4UT4X5bcc8XXR/v2mlkYr4lxAv4ZyJdCfm4jQB4+H
QCO8Mwf0KUSLsZQJRI+ZuVj6BL2N6Aq59sK2aA/twV2ai2RggI8dt6u2m7bT+6K8yf81Oj13a0sT
oSJqsEeLcrso3xflm5yPJNqXHVdMylROZSL2zInqCrdGCyx6AeB79AV62wsj6NgTXzq9sgc99gJn
zjity95b23Qm7fSxKC/yfQoT3GxgamVpPODvF+VRjjdSmecQDfoS4a1VscBr16gR40PKm5R3KR9S
yWOpE32PLqG3Ph1BH0nvewW98QiC0zlY0bLB/qxLTBxTGrknEohe5bsUEPUnEJwiRP2tIvy5VNiN
RPgngf4OrE3ZccWkTISvGqL7FKK7wv4q0UPLqzw/kNfp9+sKes/CXEi5lja6k+B0J49vAPhhBvhB
5rnCyf50EcRmYGVG8n+roO0+jNPYOMLji7FShwL3rViaB4ny12Br+uD1mlJX6MVxkKqV9irl16L8
NFHRq8xjQx+dzJn2vtfSLt9NuRXgrwB4L4oP1qSdY8dtqnZGffvMWBscd2B9pbZ5eIzyF1C5Cv6N
4+O7jPChIeXoZWimYGneBfhbsAJNwB8T+lzmbGSC0KMcHwT4O7CeFyalXGSyNUVYnWDsMsKjf1fY
3xvap1WEt3ndAqDXSr4EX6iR4ywsz772YeDaNPk0N9ZGo/wNZKEu4bsNGiJddWTYvUTCo1jNZzl/
hAh/Zfy7B3VsOO863TyXNlIbM5K2QXtWOParlYcPxtoUYXUSStNdwx5E+Jy1yT3GaK/ZmhGUS0i7
2rmG3EzuIaN8LrIr7DXo/0h5Ert5JxcvwpFLN8Y1j7tOSWo2xqZXi00SJtuulmyaqBj0CHhvINvU
C1Rw4Q4B9DNzIduxwdzpPfYNvWctL8GzPwjsPwD4B7CbIwf2phnULgeouTbUut9kYmxvwNur3nZ7
RY+Bb3qcTB7bS89F6ForJ+NzqCjvDVI1G3MnUfxJIP8h4D9J1Lewl2F10V9osCuxZ23orfHZuAcq
t6j0TTx/37SuEZOxC7n1JCGsTm3jsTK9RdwD9LmkgWbK7sGzP0OqGH17LrL3uc22ZayR0zJQWImF
Ga/ENbBPoOAMrDetnXaEHSP7VWaQ+pwZpOZg/+tE4PPgJ+j+g/HtODOra26mBvhd0pU2OzYw6WAL
u5aHFpGdwFPZiH+2BvgxRHm7ojI6F0EO/rhhZNdBKsL+CJ49t6Av/u0NS+A3gx6hs4vNcrArsOjv
kwN+LhlgP9euYXpusDGM7AR+Z+hLx8NPwc7g6jxcivEJF0Rlon3K+HW7ZOBKIvc3gVvTjgr7vePZ
S8JO4LeFvoD6GobV1Zc4w4qz0bWlqGdtf5sLowrLk1Y4OB6AZ9fZbF0IpjYGJ5YewvLE0jlhJ/D7
EA5kh2F1fQ7CrpNWdbTVJca6tHhq7E8K/g83FHbNtecyMjqxRNgJ/F6jfDIZk2TsSQzLefJLsRi6
8lIjPUKPwHuw4yD1HiI8LgpT2HFZR0HYCfwh/LydsLKw1jC+GOg10mOUjwZ2HaTeAPDfAfpv4Wv1
48iBnSLwB/HzwQBfGitya4DXX06Nwc9b36+w4/LrO4EcQb+S19iVj4HRncAfws/bx7kIr7+UejPA
Txzgzw3w+h5YdLkyenamHwn8Ufw8Rn37WwGN0Dfh66dn1sdX5oJR4HUMgODrUuXcrVEIO4E/ip8P
YfVHGXbg+hm+7oaAE1VVpofALA8ec2vAaWMI/FE9fVNq8SIsLzKbhtWJKpulwR/W4GNrX5iNIfCd
QB8N9PYXYcPg3zHBLkPAuwZ45946cIrA9wb8BOB7PyBP5m/tOnz7gxqCTuB7DX4y4CenhODfjrzv
t6wm8JQLfgj+jT1TZiDc19+UEnhqa/jXvZYi8H8U/FRH4roLisBTFIGnKAJPUQSeogg8RRF4iiLw
FEXgKYrAUxSBpygCT1EEniLwFEXgKYrAUxSBpygCT1EEnqIIPEUReIoi8BRF4CmKwFPUtsDjvRCb
XkNRh1TahdNyhw+1ZR6Wd5rmHbaofYM+N0fvxrSNKnf4YNynFAveMpqi9g285c27/fjegLeg4/br
E7FI88BtWKjD2Ji54c3upoL75m4FfHKi+kw+pN6rqN6zSDfrGsgH407QFLXvCF/z9xn8TeJmTrRP
bSN8Mh82gQ+st2J8kfep/+1CznnzfuoQwCcAXtl7C18bPk/C8obPqU2ERx+uFmYmV9O7fNgIYK+f
GxJ46gjAK4O/FuU/YfE9fO1wXhlrk9pGeIzu9dX0KtFcbUz9nG6Uy+3PqUNJ7cpErEzN4b+L8lPO
P5wo39rDI/BjedMXgX0uXckI7Ax3hKYOGeGtj3+BKP8BUd61NW0j/Dh8bZ84l8dv4WvT3AEHrNQR
Bq6VRHK1Nm/g5ce7RHjvQ6IZPNRdid0GXUXwqX2Bjuc6ntRsoe5yrgPXahdLgx+CXmoqH4Ab50YC
Tx0BeOWxgrHlNCzn5d0Z2E2AxP1CcdNcLDnfTuCpfQNv/Xxu5nVr4EPwN87FY+BAlepgABvC6vqa
ufOarSKwt2Eu7QvVJ5sTQn7j560hjXt6H4rat9XJgq4asM6oPwx4iqIoiqIoiqIoijpN/S/AANGT
iwIAKRr0AAAAAElFTkSuQmCC"
                    transform="matrix(0.16 0 0 0.16 6.1214 15.4254)"
                  ></image>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M33.601,26.906H7.559v-9.756l6.324,0c0.155,0,0.311,0,0.466,0.001
							c0.131,0.001,0.262,0.002,0.392,0.006c0.285,0.008,0.572,0.024,0.854,0.075c0.286,0.051,0.552,0.135,0.812,0.267
							c-0.055-0.028,0.715,0.288,1.391,1.339c0.488,0.758,1.436,1.562,2.763,1.562s2.276-0.804,2.763-1.562
							c0.642-0.998,1.356-1.322,1.39-1.339c0.26-0.132,0.526-0.216,0.812-0.267c0.282-0.051,0.569-0.067,0.854-0.075
							c0.131-0.004,0.262-0.005,0.392-0.006c0.155-0.001,0.311-0.001,0.466-0.001l6.362,0V26.906z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="wallet_pocket_1_"
                  style={{ clipPath: "url(#SVGID_2_)", fill: "#D3D1C8" }}
                  d="M33.601,26.906H7.559v-9.756l6.324,0
				c0.155,0,0.311,0,0.466,0.001c0.131,0.001,0.262,0.002,0.392,0.006c0.285,0.008,0.572,0.024,0.854,0.075
				c0.286,0.051,0.552,0.135,0.812,0.267c-0.055-0.028,0.715,0.288,1.391,1.339c0.488,0.758,1.436,1.562,2.763,1.562
				s2.276-0.804,2.763-1.562c0.642-0.998,1.356-1.322,1.39-1.339c0.26-0.132,0.526-0.216,0.812-0.267
				c0.282-0.051,0.569-0.067,0.854-0.075c0.131-0.004,0.262-0.005,0.392-0.006c0.155-0.001,0.311-0.001,0.466-0.001l6.362,0V26.906z
				"
                />
              </g>
            </g>
            <path
              style={{ fill: "#FFFFFF" }}
              d="M43.704,23.416h-3.211l-0.811,2.31h-1.057l2.976-8.072h0.996l2.976,8.072h-1.057L43.704,23.416z
		 M40.79,22.56h2.618l-1.292-3.681h-0.034L40.79,22.56z M52.102,22.711c0,1.84-1.04,3.071-2.562,3.071
		c-0.856,0-1.544-0.375-1.924-1.035h-0.023v2.993H46.62v-8.044h0.923v0.979h0.022c0.352-0.626,1.119-1.035,1.952-1.035
		C51.056,19.64,52.102,20.871,52.102,22.711z M51.107,22.711c0-1.331-0.705-2.204-1.767-2.204c-1.041,0-1.751,0.895-1.751,2.204
		c0,1.315,0.71,2.204,1.751,2.204C50.402,24.915,51.107,24.048,51.107,22.711z M58.856,22.711c0,1.84-1.04,3.071-2.562,3.071
		c-0.856,0-1.544-0.375-1.924-1.035h-0.023v2.993h-0.973v-8.044h0.923v0.979h0.022c0.352-0.626,1.119-1.035,1.952-1.035
		C57.81,19.64,58.856,20.871,58.856,22.711z M57.86,22.711c0-1.331-0.705-2.204-1.767-2.204c-1.041,0-1.751,0.895-1.751,2.204
		c0,1.315,0.71,2.204,1.751,2.204C57.155,24.915,57.86,24.048,57.86,22.711z M60.011,17.302h0.973v8.424h-0.973V17.302z
		 M67.521,24.003c-0.168,1.035-1.214,1.779-2.5,1.779c-1.695,0-2.763-1.18-2.763-3.049c0-1.868,1.074-3.093,2.719-3.093
		c1.611,0,2.618,1.136,2.618,2.959v0.38h-4.33v0.056c0,1.147,0.705,1.891,1.779,1.891c0.755,0,1.342-0.358,1.516-0.923H67.521z
		 M63.264,22.202h3.317c-0.023-1.024-0.671-1.706-1.622-1.706C64.014,20.496,63.331,21.184,63.264,22.202z M74.815,25.726h-0.951
		l-2.198-8.072h1.052l1.617,6.589h0.045l1.818-6.589h1.018l1.818,6.589h0.045l1.617-6.589h1.051l-2.198,8.072h-0.951l-1.863-6.405
		h-0.056L74.815,25.726z M82.103,24.009c0-1.035,0.795-1.644,2.26-1.734l1.69-0.101v-0.503c0-0.75-0.492-1.163-1.382-1.163
		c-0.699,0-1.219,0.358-1.337,0.928h-0.934c0.028-1.018,1.018-1.796,2.283-1.796c1.426,0,2.344,0.761,2.344,1.947v4.139h-0.923
		V24.68H86.08c-0.341,0.671-1.096,1.102-1.941,1.102C82.914,25.782,82.103,25.078,82.103,24.009z M86.052,23.433v-0.487
		l-1.578,0.101c-0.906,0.056-1.376,0.386-1.376,0.968c0,0.56,0.487,0.923,1.247,0.923C85.331,24.938,86.052,24.3,86.052,23.433z
		 M88.473,17.302h0.973v8.424h-0.973V17.302z M91.289,17.302h0.973v8.424h-0.973V17.302z M98.798,24.003
		c-0.168,1.035-1.214,1.779-2.5,1.779c-1.695,0-2.763-1.18-2.763-3.049c0-1.868,1.074-3.093,2.719-3.093
		c1.611,0,2.618,1.136,2.618,2.959v0.38h-4.33v0.056c0,1.147,0.705,1.891,1.779,1.891c0.755,0,1.342-0.358,1.516-0.923H98.798z
		 M94.542,22.202h3.317c-0.023-1.024-0.671-1.706-1.622-1.706C95.291,20.496,94.609,21.184,94.542,22.202z M101.435,18.337v1.359
		h1.175v0.805h-1.175v3.619c0,0.554,0.235,0.806,0.749,0.806c0.117,0,0.352-0.017,0.42-0.028v0.822
		c-0.117,0.028-0.397,0.05-0.626,0.05c-1.091,0-1.516-0.453-1.516-1.611v-3.659h-0.839v-0.805h0.839v-1.359H101.435z M42.523,12.49
		h-2.407l-0.608,1.732h-0.792l2.231-6.051h0.746l2.231,6.051h-0.792L42.523,12.49z M40.339,11.849h1.963l-0.948-2.7h-0.067
		L40.339,11.849z M44.416,11.962c0-1.417,0.751-2.34,1.899-2.34c0.629,0,1.162,0.298,1.417,0.792h0.063V7.907h0.721v6.315h-0.688
		v-0.721h-0.067c-0.285,0.503-0.822,0.801-1.447,0.801C45.158,14.302,44.416,13.384,44.416,11.962z M45.163,11.962
		c0,1.056,0.495,1.69,1.321,1.69c0.822,0,1.329-0.646,1.329-1.69c0-1.036-0.512-1.69-1.329-1.69
		C45.662,10.272,45.163,10.91,45.163,11.962z M49.693,11.962c0-1.417,0.751-2.34,1.899-2.34c0.629,0,1.162,0.298,1.417,0.792h0.063
		V7.907h0.721v6.315h-0.688v-0.721h-0.067c-0.285,0.503-0.822,0.801-1.447,0.801C50.435,14.302,49.693,13.384,49.693,11.962z
		 M50.439,11.962c0,1.056,0.495,1.69,1.321,1.69c0.822,0,1.329-0.646,1.329-1.69c0-1.036-0.512-1.69-1.329-1.69
		C50.938,10.272,50.439,10.91,50.439,11.962z M58.557,8.574v1.128h1.048v0.604h-1.048v2.558c0,0.532,0.201,0.759,0.671,0.759
		c0.13,0,0.193-0.004,0.327-0.017v0.608c-0.142,0.025-0.272,0.042-0.415,0.042c-0.931,0-1.304-0.344-1.304-1.208v-2.742h-0.759
		V9.702h0.759V8.574H58.557z M60.365,11.962c0-1.455,0.797-2.34,2.084-2.34s2.084,0.885,2.084,2.34c0,1.451-0.797,2.34-2.084,2.34
		S60.365,13.413,60.365,11.962z M63.787,11.962c0-1.073-0.482-1.69-1.338-1.69s-1.338,0.616-1.338,1.69
		c0,1.069,0.482,1.69,1.338,1.69S63.787,13.031,63.787,11.962z"
            />
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </>
    </>
  );
}
