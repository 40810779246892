import {
  BreadCrumb,
  Button,
  Input,
  Layout,
 } from "../../Components";
import { Page } from "../../Components/Breadcrumb";

import AxiosClient from "../../Services/AxiosClient";
import ModalSucessoIngressoTransferido from "./Components/ModalSucessoIngressoTransferido";
import toast from "react-hot-toast";
import TransferenciaIngressoArgs from "../../Interfaces/Args/TransferenciaIngressoArgs";
import UserInfo from "./Components/UserInfo";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

enum Etapas {
  email = 1, // preencher email + buscar
  senha = 2, // preencher senha + confirmar
  transferido = 3, // exibir modal sucesso
}

interface UserInfoData {
  nome: string;
  email: string;
  telefone: string;
}

export default function TransferenciaIngresso() {
  const navigate = useNavigate();

  const { id } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "Página inicial",
    },
    {
      link: "/meus-ingressos",
      name: "Meus ingressos",
    },
    {
      link: `/meus-ingressos/${id}`,
      name: "Informações do ingresso",
    },
    {
      link: `/transferir-ingresso/${id}`,
      name: "Transferir ingresso",
    },
  ];

  const [emailValido, setEmailValido] = useState(false);
  const [etapa, setEtapa] = useState<Etapas>(Etapas.email);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoData | null>(null);

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<TransferenciaIngressoArgs>();

  const verificarEmail = (data: TransferenciaIngressoArgs) => {
    AxiosClient.get(`conta/transferencias/validar-destinatario/${data.email}`)
      .then((res) => {
        const result: UserInfoData = res.data.data;
        setUserInfo(result);
        setEmailValido(true);
      })
      .catch(() => {
        setEmailValido(false);
        setError("email", { message: "Não foi possível obter o destinatário" });
      });
  };

  const cancelar = () => {
    if (etapa === Etapas.email && !emailValido) {
      navigate(`/meus-ingressos/${id}`);
      return;
    }

    setEtapa(Etapas.email);
    setEmailValido(false);
  };

  const continuar = (data: TransferenciaIngressoArgs) => {
    switch (etapa) {
      case Etapas.email:
        setEtapa(Etapas.senha);
        break;

      case Etapas.senha:
        const body = {
          ingressoId: id,
          emailDestinatario: data.email,
          senha: data.senha,
        };

        AxiosClient.post("conta/transferencias", body)
          .then(() => {
            setEtapa(Etapas.transferido);
            setModalIsOpen(true);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Senha incorreta.");
            } else {
              toast.error(err.response.data.messages[0].message);
            }
          });
        break;
    }
  };

  return (
    <Layout>
      <div className="row g-3">
        <BreadCrumb history={breadCrumbHistory} />

        <div className="col-lg-12">
          <div className="bg-white py-5 px-3 px-lg-5 shadow-sm">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <p className="title-h1 text-black mb-4">
                    Transferir Ingresso
                  </p>
                  <p className="text-400-black-16 mb-5">
                    Busque por um usuário utilizando o e-mail e informe sua
                    senha para prosseguir com a transferência do ingresso.
                  </p>
                </div>
                {etapa === Etapas.email ? (
                  <>
                    <p className="text-400-darkest-16 m-0">
                      {etapa === Etapas.email
                        ? "E-mail que vai transferir o ingresso"
                        : "Digite sua senha"}
                    </p>

                    <Input
                      control={control}
                      defaultValue=""
                      errors={errors}
                      validation={{
                        required: "E-mail é obrigatório",
                        validate: {
                          email: (value: string) =>
                            (value.includes("@") && value.includes(".")) ||
                            `O e-mail '${getValues(
                              "email"
                            )}' é inválido. Insira um e-mail corretamente.`,
                        },
                      }}
                      type="email"
                      name="email"
                      variant="outlined"
                      className="mt-2"
                      placeholder="email@exemplo.com"
                      disabled={emailValido}
                    />

                    {errors.email && (
                      <p className="text-danger text-break m-0 mt-1">
                        {errors.email.message?.toString()}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <Input
                      control={control}
                      defaultValue=""
                      errors={errors}
                      validation={{
                        required:
                          "Informe sua senha para prosseguir com a transferência do ingresso.",
                      }}
                      type="password"
                      name="senha"
                      variant="outlined"
                      className="mt-2"
                      placeholder="**********"
                    />

                    <p className="text-break m-0 mt-1">
                      Informe sua senha para prosseguir com a transferência do
                      ingresso.
                    </p>
                  </>
                )}

                {!emailValido && (
                  <div className="d-flex justify-content-center justify-content-lg-end mt-4">
                    <Button
                      className="bc-btn bc-btn-primary"
                      click={handleSubmit(verificarEmail)}
                      text="Buscar"
                      type="submit"
                    />
                  </div>
                )}
              </div>

              {emailValido && etapa === Etapas.email && userInfo && (
                <UserInfo
                  nome={userInfo.nome}
                  email={userInfo.email}
                  telefone={userInfo.telefone}
                />
              )}

              {/* Modal */}
              {etapa === Etapas.transferido && (
                <ModalSucessoIngressoTransferido modalIsOpen={modalIsOpen} />
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-12 d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between">
          <Button
            className="bc-btn bc-btn-light mb-3 mb-sm-0"
            click={cancelar}
            text="Cancelar"
            type="button"
            width="216px"
            height="40px"
          />

          <Button
            className="bc-btn bc-btn-primary mb-3 mb-sm-0"
            click={handleSubmit(continuar)}
            text="Continuar"
            disabled={
              (!emailValido && etapa === Etapas.email) ||
              ((!watch("senha") || !!errors.senha) && etapa === Etapas.senha)
            }
            type="submit"
            width="216px"
            height="40px"
          />
        </div>
      </div>
    </Layout>
  );
}
