import axios from "axios";
import { AuthModel } from "../Interfaces/Models";
import toast from "react-hot-toast";

const AxiosClient = () => {
  const defaultOptions = {
    baseURL:
      process.env.REACT_APP_API_URL ||
      "https://function-dev-tickets-front.azurewebsites.net/api/",
    headers: {
      "Content-Type": "application/json",
      // "X-EventTicket-Tenant": process.env.REACT_APP_TENANT || "BRASILCASH",
      "X-EventTicket-Tenant": "TICKETSAPP", // TEMP 'till finish testing
      "Access-Control-Allow-Origin": "*",
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    let auth: AuthModel = {} as AuthModel;

    const authLocalStr: string | null = localStorage.getItem("auth");
    const authSessionStr: string | null = sessionStorage.getItem("auth");

    if (authLocalStr) {
      auth = JSON.parse(authLocalStr);
    } else if (authSessionStr) {
      auth = JSON.parse(authSessionStr);
    }

    if (auth.accessToken) {
      config.headers.Authorization = `Bearer ${auth.accessToken}`;

      const tokenExpirationDate = new Date(auth.expiresIn);
      const dateNow = new Date();
      const tokenExpirado: boolean = dateNow > tokenExpirationDate;

      if (tokenExpirado && !auth.refreshToken) {
        toast.error("Sua sessão expirou, faça o login novamente.");

        sessionStorage.removeItem("auth");
        localStorage.clear();

        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);

        // para o request
        return new Promise((resolve, reject) => {});
      }

      if (tokenExpirado && auth.refreshToken) {
        axios
          .create(defaultOptions)
          .postForm("/auth/token", {
            grant_type: "refresh_token",
            lembrarSenha: "true",
            refresh_token: auth.refreshToken,
          })
          .then(({ data: { data } }) => {
            localStorage.setItem("auth", JSON.stringify(data));
            config.headers.Authorization = `Bearer ${data.accessToken}`;
          })
          .catch((error) => {});
      }
    }

    return config;
  });

  return instance;
};

export default AxiosClient();
