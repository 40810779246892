import { Link } from "react-router-dom";
import { InstagramIcon } from "./Icons";
import { WhiteLabel } from "../Utils";

function Footer() {
  return (
    <footer className="bg-black">
      <div className="container-lg pt-5 pb-b text-white">
        <div className="row justify-content-between text-center text-lg-start">
          <div className="col-lg-7 mb-4 mb-lg-0">
            <p className="small">TicketsApp</p>
            <h3>
              Olá, desejaria falar
              <br />
              com a gente?
            </h3>

            {/* Mail */}
            <a className="text-break" href={`mailto:${WhiteLabel().email}`}>
              {WhiteLabel().email}
            </a>

            {/* Socials */}
            <div>
              <div className="d-inline-flex flex-wrap">
                {/*                  
                  <a href="#" className="p-1 text-white" aria-label="facebook">
                    <FacebookIcon />
                  </a>
                  <a href="#" className="p-1 text-white" aria-label="twitter">
                    <TwitterIcon />
                  </a>
                  */}
                <a
                  href="https://www.instagram.com/tickets.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-1 text-white"
                  aria-label="instagram"
                >
                  <InstagramIcon />
                </a>
                {/*
                  <a href="#" className="p-1 text-white" aria-label="linkedin">
                    <LinkedinIcon />
                  </a>
                  <a href="#" className="p-1 text-white" aria-label="youtube">
                    <YoutubeIcon />
                  </a>
                  */}
              </div>
            </div>
          </div>

          {/* Pages */}
          <div className="col-lg-3 mb-4 mb-lg-0 d-flex justify-content-center justify-content-lg-end">
            <ul className="list-unstyled" style={{ fontSize: 12 }}>
              <li className="mb-3">
                {window.location.pathname === "/" ? (
                  <span className="text-white">Início</span>
                ) : (
                  <Link to="/" className="text-white">
                    Início
                  </Link>
                )}
              </li>
              <li className="mb-3">
                {window.location.pathname === "/meu-perfil" ? (
                  <span className="text-white">Minha conta</span>
                ) : (
                  <Link to="/meu-perfil" className="text-white">
                    Minha conta
                  </Link>
                )}
              </li>
              <li className="mb-3">
                {window.location.pathname === "/meus-ingressos" ? (
                  <span className="text-white">Meus ingressos</span>
                ) : (
                  <Link to="/meus-ingressos" className="text-white">
                    Meus ingressos
                  </Link>
                )}
              </li>
              <li className="mb-3">
                <Link
                  to="http://sejaprodutor.ticketsapp.com.br"
                  className="text-white"
                >
                  Seja produtor
                </Link>
              </li>
              <li className="mb-3">
                {window.location.pathname === "/politicas-de-privacidade" ? (
                  <span className="text-white">Políticas</span>
                ) : (
                  <Link to="/politicas-de-privacidade" className="text-white">
                    Políticas
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <p
            className="mt-3 mb-5 mb-lg-4 text-center"
            style={{ fontSize: 12 }}
          ></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
