import toast from "react-hot-toast";

import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuth } from "../Hooks/useIsAuth";
import { useRouteHistoryStore } from "../Stores/route-history.store";

type PrivateRouteProps = {
  children: ReactNode;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const { redirect, setRedirect } = useRouteHistoryStore();
  const navigate = useNavigate();

  const isAuth: boolean = useIsAuth();

  useEffect(() => {
    if (!isAuth) {
      // user tries to access a private route without being authenticated
      // if we send the user to the login page, they wont be able to go back to the previous page before the private route
      // so we send the user to the previous page before the private route

      if (!redirect) {
        setRedirect(window.location.pathname + window.location.search);
      }

      toast.error("Você deve realizar o login para acessar essa página.");
      navigate("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, navigate]);

  return (
    <>
      {!isAuth && null}
      {isAuth && children}
    </>
  );
}
