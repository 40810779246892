import GrantType from "../Enums/GrantType";

export default function ToGrantType(grantType: GrantType): string {
  switch (grantType) {
    case GrantType.ClientCredentials:
      return "client_credentials";
    case GrantType.Password:
      return "password";
    case GrantType.Passwordless:
      return "passwordless";
    case GrantType.RefreshToken:
      return "refresh_token";
    case GrantType.TwoFactorAuthentication:
      return "2fa";
    case GrantType.Portaria:
      return "portaria";
    case GrantType.MagicLink:
      return "magic_link";
    default:
      throw new Error(`Invalid grant type: ${grantType}`);
  }
}
