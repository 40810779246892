import { IoTicketOutline } from "react-icons/io5";
import { RiMapPin2Fill } from "react-icons/ri";

type CardEventoProps = {
  titulo: string;
  dataInicio: string;
  imagemThumbEvento: string | null;
  concluido?: boolean;
  quantidadeIngressos?: number;
  localEvento?: string;
  enderecoPrivado?: boolean;
  possuiIngressosDisponiveisParaVenda?: boolean;
};

export default function CardEvento({
  imagemThumbEvento,
  titulo,
  dataInicio,
  concluido,
  quantidadeIngressos,
  localEvento,
  enderecoPrivado,
  possuiIngressosDisponiveisParaVenda,
}: CardEventoProps) {
  return (
    <div className="card-evento jump-on-hover">
      {possuiIngressosDisponiveisParaVenda === false && (
        <div className="esgotado-tag-container">
          <div className="esgotado-tag">Esgotado</div>
        </div>
      )}

      <img
        src={imagemThumbEvento || ""}
        alt={titulo}
        className="img-card-evento mb-2"
        style={{ filter: concluido ? "blur(1px) grayscale(1)" : "" }}
      />
      <div className="text-card-evento">
        <p className="text-500-black-16 text-break mb-1 ellipsis">{titulo}</p>
        {dataInicio ? (
          <p className="text-500-darkest-16 m-0 ">
            {new Date(dataInicio).toLocaleDateString("pt-br", {
              day: "2-digit",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
        ) : (
          <p className="text-500-darkest-16 m-0">Nenhuma sessão disponível.</p>
        )}
        {!enderecoPrivado && localEvento && (
          <small className="text-500-black-14 text-break mb-1 ellipsis">
            <RiMapPin2Fill />
            {localEvento}
          </small>
        )}
      </div>

      {quantidadeIngressos && (
        <p className="text-400-darkest-16 m-1">
          <IoTicketOutline /> ingresso(s) : {quantidadeIngressos} Disponíveis
        </p>
      )}
    </div>
  );
}
