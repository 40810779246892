import { Button, Layout } from "../../Components";
import { CheckSuccessModalIcon } from "../../Components/Icons";

import { useNavigate } from "react-router-dom";
import { useEmbeddedStore } from "../../Stores/embedded.store";
import { Link } from "react-router-dom";

export default function CheckoutSucesso() {
  const { embedded } = useEmbeddedStore();

  const navigate = useNavigate();

  return (
    <Layout background="#FFFFFF">
      <div
        className="row d-flex flex-column justify-content-center align-items-center text-center"
        style={{ minHeight: "calc(100vh - 300px)" }}
      >
        <div>
          <CheckSuccessModalIcon />
          <p className="mt-5 title-h2">
            Sua compra foi concluida, acesse seu ingresso clicando no botão
            abaixo
          </p>
          {embedded ? (
            <Link
              to={`${process.env.REACT_APP_WEB_URL || ""}/meus-ingressos`}
              target="_blank"
              className="bc-btn bc-btn-primary mt-3"
            >
              Meus ingressos
            </Link>
          ) : (
            <Button
              className="bc-btn bc-btn-primary mt-3"
              click={() => {
                navigate("/meus-ingressos");
              }}
              text="Meus ingressos"
            />
          )}
        </div>
      </div>
    </Layout>
  );
}
