interface CheckSuccessProps {
  enabled?: boolean;
}

export default function CheckSuccessIcon({
  enabled = false,
}: CheckSuccessProps) {
  return (
    <>
      {enabled ? (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1172 2.96875C6.59719 2.96875 2.11719 7.44875 2.11719 12.9688C2.11719 18.4888 6.59719 22.9688 12.1172 22.9688C17.6372 22.9688 22.1172 18.4888 22.1172 12.9688C22.1172 7.44875 17.6372 2.96875 12.1172 2.96875ZM9.40719 17.2588L5.81719 13.6688C5.56532 13.4169 5.46695 13.0498 5.55914 12.7057C5.65133 12.3616 5.92008 12.0929 6.26414 12.0007C6.6082 11.9085 6.97532 12.0069 7.22719 12.2587L10.1172 15.1388L16.9972 8.25875C17.2491 8.00688 17.6162 7.90851 17.9602 8.0007C18.3043 8.09289 18.573 8.36164 18.6652 8.7057C18.7574 9.04977 18.6591 9.41688 18.4072 9.66875L10.8172 17.2588C10.6303 17.446 10.3767 17.5512 10.1122 17.5512C9.84768 17.5512 9.59403 17.446 9.40719 17.2588Z"
            fill="#52CC6C"
          />
        </svg>
      ) : (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1172 2.96875C6.59719 2.96875 2.11719 7.44875 2.11719 12.9688C2.11719 18.4888 6.59719 22.9688 12.1172 22.9688C17.6372 22.9688 22.1172 18.4888 22.1172 12.9688C22.1172 7.44875 17.6372 2.96875 12.1172 2.96875ZM9.40719 17.2588L5.81719 13.6688C5.56532 13.4169 5.46695 13.0498 5.55914 12.7057C5.65133 12.3616 5.92008 12.0929 6.26414 12.0007C6.6082 11.9085 6.97532 12.0069 7.22719 12.2587L10.1172 15.1388L16.9972 8.25875C17.2491 8.00688 17.6162 7.90851 17.9602 8.0007C18.3043 8.09289 18.573 8.36164 18.6652 8.7057C18.7574 9.04977 18.6591 9.41688 18.4072 9.66875L10.8172 17.2588C10.6303 17.446 10.3767 17.5512 10.1122 17.5512C9.84768 17.5512 9.59403 17.446 9.40719 17.2588Z"
            fill="#9D9D9D"
          />
        </svg>
      )}
    </>
  );
}
