import { create } from "zustand";

type EmbeddedStore = {
  embedded: boolean;
  setEmbedded: (embedded: boolean) => void;
};

export const useEmbeddedStore = create<EmbeddedStore>()((set) => ({
  embedded: false,
  setEmbedded: (embedded) => set({ embedded }),
}));
