import {
  Layout,
  Button,
} from "../../Components"
import { CopyIcon } from "../../Components/Icons/";

import AxiosClient from "../../Services/AxiosClient";
import QRCode from "react-qr-code";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PedidoDetalheModel } from "../../Interfaces/Models";
import { toast } from "react-hot-toast";

function CheckoutPIX() {
  const navigate = useNavigate();
  const { pedidoId } = useParams();

  const [jaPago, setJaPago] = useState<boolean>(false);
  const [tempoValidadePix, setTempoValidadePix] = useState<number>(1800); //tempo em segundos!!
  const [chavePix, setChavePix] = useState<string>("");

  //obter a key do pix temporaria salva no localstorage!
  useEffect(() => {
    var chavePix = sessionStorage.getItem("CheckoutPixKey");

    if (!chavePix) {
      navigate("/");
      toast.error("Não foi possível obter os dados do seu pagamento!");
      return;
    }

    setChavePix(chavePix);
    sessionStorage.removeItem("CheckoutPixKey");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tempoValidadePix > 0) {
        setTempoValidadePix(tempoValidadePix - 1);
      } else {
        navigate(-1);
        clearInterval(interval);
        toast.error("Seu pagamento expirou!");
      }
    }, 1000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempoValidadePix]);

  //metodos para contador de tempo de validade do pix
  const formatarTempo = (seconds: number) => {
    const minutos = Math.floor(seconds / 60);
    const segundosRestantes = seconds % 60;
    return `${String(minutos).padStart(2, "0")}:${String(
      segundosRestantes
    ).padStart(2, "0")}`;
  };

  const copiarChavePixClipboard = () => {
    navigator.clipboard
      .writeText(chavePix)
      .then(() => toast.success("chave pix copiada!"))
      .catch(() => toast.error("erro ao copiar chave pix"));
  };

  // executa a cada 5seg para verificar o status do pagamento
  useEffect(() => {
    const intervalId = setInterval(() => {
      AxiosClient.get(`conta/pedidos/${pedidoId}`)
        .then((res) => {
          const pedido: PedidoDetalheModel = res.data.data;

          if (pedido.status === "Pago") {
            setJaPago(true);
            navigate("/");
            toast.success("seu pagamento foi realizado com sucesso");
          }
        })
        .catch(() => {
          navigate("/");
          toast.error("Não foi possível obter os dados do seu pagamento");
        });

      if (jaPago) {
        clearInterval(intervalId);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jaPago]);

  return (
    <>
      <Layout background="#FFFFFF">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "85vh" }}
        >
          <div
            className="px-3 text-center d-flex flex-column align-items-center justify-content-center custom-box-style"
            style={{
              maxWidth: "800px",
              height: "600px",
              background: "#F9F8F8",
              borderRadius: "10px",
            }}
          >
            <p className="text-500-black-14">
              QR Code gerado com sucesso! Agora é só abrir o aplicativo do seu
              banco, escolher a opção <strong>Pagar com Pix</strong> e escanear
              o QR Code ou copiar e colar o código.
            </p>

            <div className="p-2" style={{ background: "#FFF" }}>
              <QRCode value={chavePix} width={246} height={246} />
            </div>

            <p
              style={{ wordBreak: "break-all" }}
              className="px-3 text-500-black-14 mt-2"
            >
              {chavePix}
            </p>

            <Button
              click={() => copiarChavePixClipboard()}
              className="bc-btn bc-btn-primary"
              text="Copiar código pix"
              width="384px"
              iconSvg={<CopyIcon />}
            />

            <p className="mt-2 text-500-black-14">
              <strong>{formatarTempo(tempoValidadePix)}</strong> até o código
              expirar
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CheckoutPIX;
