import { lazy, Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import AppRouter from "./Routes";

const BrasilCashStyle = lazy(() => import("./Components/Styles/BrasilCashCSS"));
const TicketsStyle = lazy(() => import("./Components/Styles/TicketsCSS"));

const tenant = process.env.REACT_APP_TENANT;

export default function App() {
  useEffect(() => {
    const link = document.createElement("link");
    const oldLinks = document.querySelectorAll('link[rel="shortcut icon"]');
    oldLinks.forEach((e) => e.parentNode!.removeChild(e));
    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = process.env.REACT_APP_FAVICON || "favicon.ico";
    document.head.appendChild(link);
  }, []);

  return (
    <>
      <Suspense fallback={<></>}>
        {tenant === "BRASILCASH" && <BrasilCashStyle />}
        {tenant === "TICKETSAPP" && <TicketsStyle />}
      </Suspense>
      <Toaster position="top-center" reverseOrder={false} />
      <AppRouter />
    </>
  );
}
