import ReactSelect, { ActionMeta, SingleValue } from "react-select";

type SelectValue = {
  label: string;
  value: string;
};

interface Props {
  disabled?: boolean;
  height?: string;
  id: string;
  onChange: (newSelection: SingleValue<SelectValue>, actionMeta: ActionMeta<SelectValue>) => void;
  options: SelectValue[];
  value: SelectValue;
  variant?: "standard" | "filled" | "outlined";
}

// Exemplo de uso:
// <Select
//   id="select"
//   options={[{ value: "1", label: "1" }, { value: "2", label: "2" }]}
//   value={selectedOption}
//   onChange={setSelectedOption}
//   disabled={disabled}
// />

const Select: React.FC<Props> = ({
  onChange,
  options,
  value,
  variant,
}) => {
    return (
      <ReactSelect
        className={`form-control bc-input input-${variant}`}
        classNames={{
          control: () => "text-dark",
        }}
        menuPortalTarget={document.body}
        onChange={onChange}
        options={options}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        value={value} />
    );
  };

export default Select;
